import React, { useState, useEffect } from 'react';

/**
 * Marterial UI
 */
import { Box, IconButton, Icon, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


/**
 * Slate Editor
 */
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Node } from 'slate'
import { InsertMathExpressionButton } from '../plugins/MathExpression';
import { LinkButton } from '../plugins/Links';
import { InsertMediaLinkButton, SelectMediaButton } from '../plugins/Media';
import { InsertVideoButton } from '../plugins/Videos';

/**
 * Speech Recognition
 */
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export const StandardToolbar = (props) => {
    return (
        <React.Fragment>
            <Tooltip title="Speak to Text">
                <span><VoiceToTextButton /></span>
            </Tooltip>
            <Tooltip title="Bold">
                <span><MarkButton format="bold" icon="format_bold" /></span>
            </Tooltip>
            <Tooltip title="Italic">
                <span><MarkButton format="italic" icon="format_italic" /></span>
            </Tooltip>
            <Tooltip title="Underline">
                <span><MarkButton format="underline" icon="format_underlined" /></span>
            </Tooltip>
            <Tooltip title="Heading 1">
                <span><BlockButton format="heading-one" icon="looks_one" /></span>
            </Tooltip>
            <Tooltip title="Heading 2">
                <span><BlockButton format="heading-two" icon="looks_two" /></span>
            </Tooltip>
            <Tooltip title="Block Quote">
                <span><BlockButton format="block-quote" icon="format_quote" /></span>
            </Tooltip>
            <Tooltip title="Numbered List">
                <span><BlockButton format="numbered-list" icon="format_list_numbered" /></span>
            </Tooltip>
            <Tooltip title="Bullet Points">
                <span><BlockButton format="bulleted-list" icon="format_list_bulleted" /></span>
            </Tooltip>
            <Tooltip title="Insert Image">
                <span><InsertMediaLinkButton type="image" /></span>
            </Tooltip>
            <Tooltip title="Insert Audio URL">
                <span><InsertMediaLinkButton type="audio" /></span>
            </Tooltip>
            <Tooltip title="Record/Upload Audio">
                <span><SelectMediaButton type="audio" /> </span>
            </Tooltip>
            <Tooltip title="Insert Youtube Video">
                <span><InsertVideoButton /></span>
            </Tooltip>
            <Tooltip title="Record/Upload Video">
                <span><SelectMediaButton type="video" /> </span>
            </Tooltip>
            <Tooltip title="Insert Hyperlink">
                <span><LinkButton /></span>
            </Tooltip>
            <Tooltip title="Insert Math Expression">
                <span><InsertMathExpressionButton /></span>
            </Tooltip>
        </React.Fragment>
    );
}

const VoiceToTextButton = (props) => {
    const editor = useSlate();
    // Additional state required to avoid useEffect from being called more than once
    const [isCapturing, setCapturing] = useState(false);
    /**
     * Speech Recognition
     */
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const startListening = () => {
        setCapturing(true);
        SpeechRecognition.startListening({ language: 'zh-CN' });
    }

    const stopListening = () => {
        SpeechRecognition.stopListening();
    }

    useEffect(() => {
        if (Boolean(isCapturing) && !Boolean(listening) && Boolean(transcript)) {
            console.log(`Stop listening: ${transcript}`)
            setCapturing(false);
            Transforms.insertText(editor, transcript);
        }
    }, [listening, transcript])
    return (
        <React.Fragment>
            <IconButton
                size='small'
                onClick={Boolean(listening) ? stopListening : startListening}
                onMouseDown={e => e.preventDefault()}  >
                <Icon>{Boolean(listening) ? 'stop' : 'mic'}</Icon>
            </IconButton>
            <Typography variant='caption'>中</Typography>

        </React.Fragment>
    );
}

/**
 * Mark Operations
 */
const MarkButton = ({ format, icon }) => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const editor = useSlate();
    const isMarkActive = () => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }
    const toggleMark = () => {
        if (isMarkActive(editor, format)) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }
    return (
        <IconButton
            size="small"
            className={[isMarkActive() ? null : classes.inactive]}
            onMouseDown={event => {
                event.preventDefault();
                toggleMark();
            }}>
            <Icon>{icon}</Icon>
        </IconButton>
    )
}

/**
 * Block Operations
 */
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const BlockButton = ({ format, icon }) => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const editor = useSlate()
    const isBlockActive = () => {
        const [match] = Editor.nodes(editor, {
            match: n => n.type === format,
        })
        return !!match
    }
    const toggleBlock = () => {
        const isActive = isBlockActive()
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n => LIST_TYPES.includes(n.type),
            split: true,
        })

        Transforms.setNodes(editor, {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        })

        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }
    return (
        <IconButton
            size="small"
            className={[isBlockActive() ? null : classes.inactive]}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock();
            }}>
            <Icon>{icon}</Icon>
        </IconButton>
    )
}
