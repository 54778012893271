import React from 'react';
import { MessageDialog } from './MessageDialog';
import { Box, Typography } from '@material-ui/core';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { children: props.children, hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        this.setState({ hasError: true, error: error, errorInfo: errorInfo });
        // alert(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <React.Fragment>
                    <MessageDialog
                        open={true}
                        maxWidth="lg"
                        title="Test Paper Online - Something went Wrong"
                        message={
                            <Box overflowy="scroll">
                                <Box my={2}>
                                    <Typography variant='body1'>{this.state?.error?.message}</Typography>
                                </Box>
                                <Box my={2} style={{ whiteSpace: "pre-wrap" }}>
                                    <Typography variant='subtitle2'>Stack Trace</Typography>
                                    <Typography variant='caption'>{this.state?.error?.stack}</Typography>
                                </Box>
                                <Box my={2} style={{ whiteSpace: "pre-wrap" }}>
                                    <Typography variant='subtitle2'>Component Stack</Typography>
                                    <Typography variant='caption'>{this.state?.errorInfo?.componentStack}</Typography>
                                </Box>
                            </Box>
                        }
                        actions={[{
                            key: "Confirm",
                            label: "Confirm",
                            handler: () => alert("Okay"),
                            isDefault: true
                        }]} />
                </React.Fragment>
            );
        } else {
            return this.props.children;
        }
    }
}