import React, { useEffect, useState } from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, TextField, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionEditor } from './SectionEditor';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "fill-in-blank",
    title: null,
    instruction: null,
    // provideOptions: false,
};
export function FillInBlankSectionEditor(props) {
    const classes = makeStyles(theme => ({
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        }
    }))();
    const { sectionIndex, updateSection, languageCode } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input" }] }, props.section);
    const updateThisSection = (sectionIndex, section) => {
        var originalSection = Object.assign({}, section, { isEditing: section.questions.filter(q => q.stage !== undefined).length > 0 });
        updateSection(sectionIndex, originalSection);
    }
    const addQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex + 1, 0, Object.assign({}, { id: uuid(), stage: "input", autoFocus: true }));
        updateThisSection(sectionIndex, originalSection);
    }
    const removeQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, originalSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, originalSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var originalSection = Object.assign({}, section);
        originalSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, originalSection);
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <FillInBlankQuestionEditor
                    key={question.id}
                    className={classes.questionEditor}
                    question={question}
                    questionIndex={questionIndex}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    updateQuestion={updateQuestion}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode} />)}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
                {/* Children if any */}
                {props.children}
                {/* End: Children if any */}
            </Grid>
        </SectionEditor>
    );
}


/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Input your question, including the blank word(s).", nextStage: "select" },
    select: { hint: "Use the cursor to select the word(s) that you would like to leave blank.", previousStage: "input" }
};
export function FillInBlankQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode } = props;
    const question = Object.assign({ id: uuid() }, props.question);
    const stage = question.stage;
    const [selectionMouseDownBlockIndex, setSelectionMouseDownBlockIndex] = useState();

    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        delete originalQuestion.autoFocus;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            // setStage(stage + step);
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            // setStage(null);
            // let originalQuestion = Object.assign({}, question);
            // delete originalQuestion.stage
            // updateQuestion(questionIndex, originalQuestion);
            complete(false);
        }
    }

    const handleSelection = (blockIndex) => {

        let originatingBlockIndex = selectionMouseDownBlockIndex;

        let startBlockIndex = Math.min(...[blockIndex, originatingBlockIndex].filter(Number.isFinite));
        let endBlockIndex = Math.max(...[blockIndex, originatingBlockIndex].filter(Number.isFinite));
        let startOffset = (blockIndex === originatingBlockIndex) ?
            Math.min(document.getSelection().anchorOffset, document.getSelection().focusOffset) :
            (blockIndex > originatingBlockIndex) ? document.getSelection().anchorOffset : document.getSelection().focusOffset
        let endOffset = (blockIndex === originatingBlockIndex) ?
            Math.max(document.getSelection().anchorOffset, document.getSelection().focusOffset) :
            (blockIndex > originatingBlockIndex) ? document.getSelection().focusOffset : document.getSelection().anchorOffset
        if (!(startBlockIndex === endBlockIndex && startOffset === endOffset)) {
            // Across multiple blocks
            let blocks = question.blocks;
            let beforeText = blocks[startBlockIndex].value.substring(0, startOffset);
            let afterText = blocks[endBlockIndex].value.substring(endOffset);
            let selectedText = document.getSelection().toString();
            // Special handling on leading and trailing space to improve usability
            var numberOfLeadingSpaces = selectedText.search(/[^\s]+/);
            var numberOfTrailingSpaces = selectedText.split("").reverse().join("").search(/[^\s]+/)
            beforeText = `${beforeText}${Array(numberOfLeadingSpaces).fill(" ").join("")}`
            afterText = `${Array(numberOfTrailingSpaces).fill(" ").join("")}${afterText}`
            selectedText = selectedText.substring(numberOfLeadingSpaces, selectedText.length - numberOfTrailingSpaces);
            blocks.splice(startBlockIndex, (endBlockIndex - startBlockIndex) + 1, { type: "text", value: beforeText }, { type: "answer", value: selectedText }, { type: "text", value: afterText });
            setSelectionMouseDownBlockIndex(null);
            updateQuestion(questionIndex, Object.assign({}, question, { blocks: blocks }));
        }
    }

    const contextMenuSelect = () => {
        let remainingTextLength = document.getSelection().toString().length;
        console.log(`Original Text Length: ${remainingTextLength}`);
        question.blocks.forEach((b, bIndex) => {
            if (remainingTextLength > 0) {
                if (bIndex == selectionMouseDownBlockIndex) {
                    remainingTextLength -= (b.value.length - document.getSelection().anchorOffset);
                } else if (bIndex > selectionMouseDownBlockIndex) {
                    remainingTextLength -= (b.value.length);
                }
                console.log(`bIndex:${bIndex} / remainingTextLength:${remainingTextLength}`)
                if (remainingTextLength <= 0) {
                    handleSelection(bIndex);
                    document.getSelection().removeAllRanges();
                }
            }
        });
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <TextFieldWithSpeechRecognition fullWidth
                    languageCode={languageCode}
                    autoFocus={question.autoFocus}
                    variant="outlined" multiline={true}
                    value={(question.blocks || []).map(b => b.value).join("")}
                    onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { blocks: [{ type: "text", value: e.target.value }] }))} />
            break;
        case "select":
            editor =
                <Box borderStyle="solid" borderRadius={4} border={2} borderColor="primary.main" p={2}>
                    {(question.blocks || []).map((b, bIndex) =>
                        <SelectableTypography key={`block-${bIndex}`} className={b.type === "text" ? classes.questionText : classes.answer}
                            blockIndex={bIndex}
                            setSelectionMouseDownBlockIndex={setSelectionMouseDownBlockIndex}
                            onMouseDown={(e) => {
                                console.log("onMouseDown...");
                                console.log(document.getSelection().toString());
                                setSelectionMouseDownBlockIndex(bIndex);
                            }}
                            // onContextMenu={(e) => {
                            //     //e.preventDefault();
                            //     console.log(`onContextMenu @ block[${bIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                            //     console.log(document.getSelection().toString());
                            //     console.log(question.blocks.map(b => `[${b.value}]`).join(""));
                            //     setSelectionMouseDownBlockIndex(bIndex);
                            // }}
                            onMouseUp={(e) => {
                                console.log("onMouseUp...");
                                console.log(document.getSelection().toString());
                                handleSelection(bIndex);
                            }}
                        // onFocus={(e) => {
                        //     console.log(`onFocus @ block[${bIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                        //     console.log(document.getSelection().toString());
                        //     console.log(question.blocks.map(b => `[${b.value}]`).join(""));
                        //     setSelectionMouseDownBlockIndex(Math.min(...[bIndex, selectionMouseDownBlockIndex].filter(Number.isFinite)));
                        // }}
                        // onTouchStart={e => {
                        //     console.log("onTouchStart...");
                        //     console.log(document.getSelection().toString());
                        //     // e.preventDefault(); 
                        //     setSelectionMouseDownBlockIndex(bIndex);
                        // }}
                        // onTouchMove={e => {
                        //     console.log("onTouchEnd...");
                        //     console.log(document.getSelection().toString());
                        //     // e.preventDefault(); 
                        //     handleSelection(bIndex);
                        // }}
                        >{b.value}</SelectableTypography>
                    )}
                </Box >
            break;
        default:
            editor =
                question.blocks.map((b, bIndex) =>
                    <Typography key={`block - ${bIndex}`}
                        className={b.type === "text" ? classes.questionText : classes.answer}
                        onClick={isEditingSection ? () => { } : () => setStage("select")}>{b.value}</Typography>)
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            {(Boolean(selectionMouseDownBlockIndex) && selectionMouseDownBlockIndex >= 0) ?
                /* Select Button when Context Menu is ON (For Android) */
                <Grid item xs={12} className={classes.buttonGrid}>
                    <Button className={classes.button} color="primary" onClick={contextMenuSelect}>Select</Button>
                </Grid>
                /* End: Select Button when Context Menu is ON (For Android) */
                : <Grid item xs={12} className={classes.buttonGrid}>
                    {Boolean(STAGES[stage]) && Boolean(STAGES[stage].previousStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].previousStage)}>Back</Button> : null}
                    {Boolean(STAGES[stage]) && Boolean(STAGES[stage].nextStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].nextStage)} disabled={!Boolean(question.blocks) || question.blocks.length === 0}>Next</Button> : null}
                    {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                    {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                        <React.Fragment>
                            <Button className={classes.button} color="primary" onClick={() => complete(false)} disabled={question.blocks.filter(b => b.type === "answer").length === 0}>Complete</Button>
                            <Button className={classes.button} color="primary" onClick={() => complete(true)} disabled={question.blocks.filter(b => b.type === "answer").length === 0}>Next Question</Button>
                        </React.Fragment> :
                        null}
                </Grid>}
        </React.Fragment >
    );
}

const DEFAULT_LENGTH = 10;
const DEFAULT_LENGTH_BUFFER = 6;
export function Blank(props) {
    const { length } = props;
    const classes = makeStyles((theme) => ({
        root: {

        },
        input: {
            width: theme.spacing((length || DEFAULT_LENGTH) + DEFAULT_LENGTH_BUFFER),
            marginBottom: theme.spacing(0)
        },
        inputProps: {
            textAlign: "center",
            paddingBottom: 0,
            paddingTop: 10,
            lineHeight: "2.5em"
        }
    }))();
    return (
        <TextField
            {...props}
            className={classes.input}
            inputProps={{ className: classes.inputProps }} />
    );
}

const SelectableTypography = (props) => {
    const { className, blockIndex, setSelectionMouseDownBlockIndex, children, ...others } = props;
    const typographyRef = React.createRef();
    useEffect(() => {
        if (Boolean(typographyRef.current)) {
            if (!Boolean(typographyRef.current.onselectstart)) {
                typographyRef.current.onselectstart = (e) => {
                    console.log(`onselectstart @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
            if (!Boolean(typographyRef.current.onselectionchange)) {
                typographyRef.current.onselectionchange = (e) => {
                    console.log(`onselectionchange @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
            if (!Boolean(typographyRef.current.onselect)) {
                typographyRef.current.onselect = (e) => {
                    console.log(`onselect @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
        }

    }, [typographyRef.current])
    return (
        <Typography ref={typographyRef} blockIndex={blockIndex} className={className} {...others}>{children}</Typography>
    );
}