import React, { useState } from 'react';

/**
 * Material UI
 */
import { Box, Icon, Typography } from '@material-ui/core';

/**
 * React Sppech Kit
 */
import { useSpeechSynthesis } from 'react-speech-kit';

/**
 * Application
 */
import { TypographyWithSelectionSupport } from './TypographyWithSelectionSupport';
import { SpeakButton, StopButton } from './';

const LANGUAGES = [{
    codes: ['zh-HK', 'yue_HK_#Hant'],
    label: '粵'
}, {
    codes: ['zh-CN', 'zh_CN_#Hans', 'zh_CN_#Hans'],
    label: '普'
}, {
    codes: ['en-GB', 'en_GB'],
    label: 'Eng'
}]

export const SpeakableTypography = (props) => {
    const { children } = props;
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
    const [selectedText, setSelectedText] = useState(children);

    const speakLanguage = (text, languageCodes) => {
        let voice = voices.filter(v => languageCodes.includes(v.lang))[0];
        console.log(`Voice: ${voice?.lang}`);
        alert(voice?.lang)
        speak({ text: text, voice: voices.filter(v => languageCodes.includes(v.lang))[0] })
    }

    return (
        <React.Fragment>
            <Typography>{selectedText}</Typography>
            <TypographyWithSelectionSupport onSelect={text => setSelectedText(Boolean(text) ? text : children)}>{children}</TypographyWithSelectionSupport>
            {/* If Speaking Supported */}
            {Boolean(supported) &&
                <Box display='flex' justifyContent='right'>
                    {Boolean(speaking) ?
                        <StopButton onClick={cancel} /> :
                        LANGUAGES.map(language =>
                            <SpeakButton languageText={language.label} onClick={() => speakLanguage(selectedText, language.codes)} />)}
                </Box>}
            {/* End: If Speaking Supported */}
            {voices.map(v => <Typography>{v.name} / {v.lang} / {v.localService ? "Y" : "N"} / {v.voiceURI} / {v.default ? "Default" : "-"}</Typography>)}
        </React.Fragment >
    )
}
