import React from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionHeader } from './controls/SectionHeader';

const EMPTY_SECTION_ATTEMPT = { score: 0, answers: [] };
export function QuestionAnswerSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
            // lineHeight: "2.5em",
        },
        questionGrid: {
            display: "flex",
            textAlign: "left"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        questionBox: {
            width: "100%"
        },
        correctAnswerBox: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            borderRadius: 10,
            width: "auto"
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);

    const updateAnswer = (questionIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign([], originalSectionAttempt.answers)
        // As Radio Group doesn't support integer as value, so pass it as String and convert back
        originalAnswers[questionIndex] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = originalSectionAttempt.answers.filter((answer, aIndex) => answer === section.questions[aIndex].answer).length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    return (
        Boolean(sectionAttempt) ?
            <Grid container spacing={3} className="print-table">
                <SectionHeader section={section}
                    sectionIndex={sectionIndex}
                    sectionAttempt={sectionAttempt}
                    showScore={showScore}
                    showAnswer={showAnswer} />
                {section.questions.map((question, qIndex) =>
                    <Grid key={`question-${section.id}-${qIndex}`} item xs={12} className={classes.questionGrid}>
                        <Box display="flex" justifyContent="start">
                            <Box mr={2}>
                                <Typography className={classes.textBlock}>{qIndex + 1}. </Typography>
                            </Box>
                            <Box className={classes.questionBox} m={0} verticalAlign="middle">
                                {Boolean(section.individualQuestionText) &&
                                    <Box mb={1}>
                                        <Typography variant="body1">{question.question}</Typography>
                                    </Box>}
                                <Box my={0}>
                                    {showAnswer ?
                                        <React.Fragment>
                                            <Typography component="span" className={sectionAttempt.answers[qIndex] === section.questions[qIndex].answer ? classes.correctAnswerText : classes.incorrectAnswerText}>{sectionAttempt.answers[qIndex]}</Typography>
                                            {sectionAttempt.answers[qIndex] !== section.questions[qIndex].answer ?
                                                <Box component="span" ml={2}>
                                                    <Typography component="span" className={classes.correctAnswerBox}>{section.questions[qIndex].answer}</Typography>
                                                </Box> : null}
                                        </React.Fragment>
                                        : <TextField fullWidth onChange={(e) => updateAnswer(qIndex, e.target.value)} />}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid > : null
    )
}
