import React, { useState, useEffect } from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, IconButton, Icon, List, ListItem, ListItemIcon, Checkbox, Button, Typography, TextField, Box, Tooltip, ListSubheader } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

/**
 * Application
 */
import { firestore } from '../../common/serviecs/firebase';
import { LoadingMask } from '../../common/components/controls/LoadingMask';
import { getDate } from '../../common/functions/DateTimeUtils';

/**
 * Constant
 */
const EMPTY_ITEM = { text: null, completed: false }

export function Checklist(props) {
    const classes = makeStyles(theme => ({
        title: {
            textAlign: "center",
            position: "relative",
            paddingBottom: theme.spacing(1),
            fontWeight: 800,
            "&::after": {
                position: "absolute",
                bottom: 0,
                left: "50%",
                height: 3,
                width: 200,
                borderBottom: "rgb(204,0,0) solid 3px",
                content: `''`,
                transform: "matrix(1,0,0,1,-100,0)"
            }
        },
    }))();
    const { user } = props;
    const [selectedDate, setSelectedDate] = useState(getDate);
    // Checklist ID: yyyy-m-d
    const checklistId = `${selectedDate.getFullYear()}-${selectedDate.getUTCMonth() + 1}-${selectedDate.getUTCDate() + 1}`;
    // const [items, setItems] = useState([{ text: "Item 1", completed: false }]);
    const [checklist, setChecklist] = useState();
    const [carriedOverItems, setCarriedOverItems] = useState();
    const [suggestedItems, setSuggestedItems] = useState();
    const [isEditing, setEditing] = useState(false);
    const addItem = (itemIndex) => {
        let items = checklist.items || [];
        items.splice(itemIndex + 1, 0, { text: null, completed: false, date: selectedDate })
        setChecklist(Object.assign({}, checklist, { items: items }));
    }
    const removeItem = (itemIndex) => {
        let items = checklist.items || [];
        items.splice(itemIndex, 1)
        setChecklist(Object.assign({}, checklist, { items: items }));
    }
    const updateItem = (itemIndex, key, value) => {
        let items = checklist.items || [];
        items[itemIndex][key] = value;
        setChecklist(Object.assign({}, checklist, { items: items }));
    }
    const saveChecklist = () => {
        let sanitizedChecklist = Object.assign({}, checklist);
        sanitizedChecklist.items = sanitizedChecklist.items.filter(i => Boolean(i.text))
        firestore.collection(`/users/${user.id}/checklists/`).doc(checklistId).set(sanitizedChecklist).then(checklistRef => {
            setChecklist(sanitizedChecklist);
            setEditing(false);
            // Do nth
        });
    }
    const enableEditing = () => {
        if (!Boolean(checklist.items) || checklist.items.length === 0) {
            setChecklist(Object.assign({}, checklist, { items: [Object.assign({}, EMPTY_ITEM)] }))
        }
        setEditing(true);
    }
    const appendItem = (itemText) => {
        let items = checklist.items || [];
        items.push({ text: itemText, completed: false, date: selectedDate })
        setChecklist(Object.assign({}, checklist, { items: items }));
    }

    useEffect(() => {
        // Refresh whenever user/selectedDate change
        if (Boolean(user)) {
            firestore.collection(`/users/${user.id}/checklists`).doc(checklistId).get().then(checklistRef => {
                if (checklistRef.exists) {
                    // If checklist of the day already exists
                    let retrievedChecklist = Object.assign({ id: checklistId }, checklistRef.data());
                    setChecklist(retrievedChecklist);
                } else {
                    console.log(`Chechlist not found for ${checklistId}`)
                    // Retrieve the last 3 checklists to get all carried over items & suggested items
                    firestore.collection(`/users/${user.id}/checklists`).where("date", "<", selectedDate).orderBy("date", "desc").limit(10).get().then(
                        checklistsRef => {
                            console.log(`Number of previous ${checklistsRef.size} checklist.`)
                            console.log("Trying to find previous imcomplete items");
                            let cIndex = 0;
                            let retrievedCarriedOverItems = [];
                            let retrievedSuggestedItems = {};
                            checklistsRef.forEach(checklistRef => {
                                let items = checklistRef.data().items;
                                console.log("Previous checklist found")
                                if (cIndex < 3) {
                                    // Add all incomplete items as carried over items from the past 3 checklists
                                    retrievedCarriedOverItems = retrievedCarriedOverItems.concat(items.filter(i => !Boolean(i.completed)).map(i => i.text));
                                }
                                items.forEach(item => {
                                    if (!Boolean(retrievedSuggestedItems[item])) {
                                        retrievedSuggestedItems[item] = 0;
                                    }
                                    retrievedSuggestedItems[item] = retrievedSuggestedItems[item] + 1;
                                });
                                cIndex += 1;
                            })
                            console.log("Checklist updated")
                            setChecklist(Object.assign({ id: checklistRef.id, date: selectedDate, items: [] }));
                            setCarriedOverItems(retrievedCarriedOverItems);
                            setSuggestedItems(Object.keys(retrievedSuggestedItems).filter(itemKey => retrievedSuggestedItems[itemKey] > 3));
                        }
                    )
                }
            })

        }
    }, [checklistId, user]);

    console.log("Rendering")

    return (
        Boolean(user) && Boolean(checklist) ?
            <Container maxWidth="lg">
                <Grid Container spacing={2}>
                    {/* Date Select */}
                    <Grid item xs={12}>
                        <Box textAlign="center" m={2}>
                            <Typography variant="h5"
                                className={classes.title}>Checklist of the Day</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker style={{ width: 200 }}
                                    disableToolbar={true}
                                    autoOk={true}
                                    variant="dialog"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => setSelectedDate(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                    {/* End: Date Select */}
                    {/* Buttons */}
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        {Boolean(isEditing) ?
                            <Button color="primary" onClick={e => saveChecklist()}>Save</Button> :
                            <Button color="primary" onClick={e => enableEditing(true)}>Edit</Button>}
                    </Grid>
                    {/* End: Buttons */}
                    <Grid item xs={12}>
                        {/* Selected Date's Items */}
                        <Paper>
                            {Boolean(checklist.items.length > 0) ?
                                <List>
                                    {(checklist.items || []).map((item, iIndex) =>
                                        <ListItem key={iIndex} dense button>
                                            {!Boolean(isEditing) &&
                                                <ListItemIcon>
                                                    <Checkbox edge="start" color="primary"
                                                        checked={Boolean(item.completed)}
                                                        onClick={e => updateItem(iIndex, "completed", e.target.checked)} />
                                                </ListItemIcon>}
                                            {Boolean(isEditing) ?
                                                <TextField color="primary"
                                                    placeholder="Input your action item here"
                                                    style={{ width: "100%" }}
                                                    value={item.text}
                                                    onChange={e => updateItem(iIndex, "text", e.target.value)} /> :
                                                <Typography variant="subtitle1">{item.text}</Typography>}
                                            {/* Action Buttons  */}
                                            {Boolean(isEditing) &&
                                                <Box display="flex">
                                                    <Tooltip title="Add the next item">
                                                        <IconButton edge="end" onClick={() => addItem(iIndex)}>
                                                            <Icon>keyboard_return</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Remove this item">
                                                        <IconButton edge="end" onClick={() => removeItem(iIndex)}>
                                                            <Icon>clear</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>}
                                            {/* End: Action Buttons  */}
                                        </ListItem>)}
                                </List> :
                                <Box p={2} textAlign="center">
                                    <Typography variant="body1">You don't have any checklist item. You can add items by clicking the Edit button, or add items carried over or from suggestions.</Typography>
                                </Box>}
                        </Paper>
                        {/* End: Selected Date's Items */}
                        {/* Carried Over Items */}
                        {Boolean(carriedOverItems) && carriedOverItems.length > 0 &&
                            <React.Fragment>
                                <List subheader={<ListSubheader color="primary">Carried Over</ListSubheader>}>
                                    {carriedOverItems.map((carriedOverItem, cIndex) =>
                                        <ListItem key={cIndex}>
                                            <ListItemIcon>
                                                <IconButton size="small" edge="start">
                                                    <Icon onClick={() => appendItem(carriedOverItem)}>add</Icon>
                                                </IconButton>
                                            </ListItemIcon>
                                            <Typography variant="subtitle2" color="textSecondary">{carriedOverItem}</Typography>
                                        </ListItem>
                                    )}
                                </List>
                            </React.Fragment>}
                        {/* End: Carried Over Items */}
                        {/* Suggested Items */}
                        {Boolean(suggestedItems) && suggestedItems.length > 0 &&
                            <React.Fragment>
                                <List subheader={<ListSubheader color="primary">Suggestions</ListSubheader>}>
                                    {suggestedItems.map((suggestedItem, sIndex) =>
                                        <ListItem key={sIndex}>
                                            <ListItemIcon>
                                                <IconButton size="small" edge="start">
                                                    <Icon onClick={() => appendItem(suggestedItem)}>add</Icon>
                                                </IconButton>
                                            </ListItemIcon>
                                            <Typography variant="subtitle2" color="textSecondary">{suggestedItem}</Typography>
                                        </ListItem>
                                    )}
                                </List>
                            </React.Fragment>}
                        {/* End: Suggested Items */}
                    </Grid>
                </Grid>
            </Container > : <LoadingMask message="Loading Checklist" />
    )
}