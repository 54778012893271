import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';

/**
 * Language Menu
 */
export function LanguageMenu(props) {
    const { t, i18n, supportedLanguages } = props;
    const classes = makeStyles(theme => ({
        button: {
            color: "white"
        },
        menuIcon: {
            marginRight: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },
        expandIcon: {
            marginLeft: theme.spacing(0),
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        }
    }))();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (languageCode) => {
        localStorage.setItem("languageCode", languageCode);
        i18n.changeLanguage(languageCode);
        handleClose();
    }

    return (
        <div>
            <Button className={classes.button} onClick={handleClick}
                classes={{ startIcon: classes.menuIcon, endIcon: classes.expandIcon }}
                startIcon={<Icon style={{ marginRight: "0" }}>translate</Icon>}
                endIcon={<Icon>expand_more</Icon>}>
                <Box display={{ xs: "none", md: "block" }}>{t(supportedLanguages[i18n.language])}</Box>
                <Box display={{ xs: "block", md: "none" }}>{t(i18n.language)}</Box>
            </Button>
            <Menu id="language-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {Object.keys(supportedLanguages).map(languageCode =>
                    <MenuItem key={`menu-item-language=${languageCode}`} onClick={() => changeLanguage(languageCode)}>
                        <Typography variant="subtitle2">{t(supportedLanguages[languageCode])}</Typography>
                    </MenuItem>)}
            </Menu>
        </div>
    );
}
