import React, { useState, useRef, useEffect } from 'react';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';

/**
 * Slate Editor
 */
import { Editor, Transforms } from 'slate';
import { ReactEditor, useEditor, useSelected, useFocused, useReadOnly } from 'slate-react';

import { css } from 'emotion';

// import { Button, Icon } from '../controls';

/**
 * Material UI - For InsertAndEditImageButton
 */
import { grey } from '@material-ui/core/colors';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useMediaQuery, Button, IconButton, Icon, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Switch, FormControlLabel, Typography, Box, Chip } from '@material-ui/core';

/**
 * QR Code
 */
import QRCode from 'qrcode.react';

/**
 * CropperJS
 */
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

/**
 * Firebase
 */
import { storage } from '../../../common/serviecs/firebase';

/**
 * Application
 */
import { UploadMediaToFirebaseStorageButton } from '../../../common/components/controls/Media';
import { AudioPlayer } from '../../../common/components/controls/Media';
//import { usePrintLayout } from '../hooks';

/**
 * Constants
 */
export const SUPPORTED_MEDIA = {
    image: { key: "image", icon: "image" },
    audio: { key: "audio", icon: "audiotrack" }
};


/**
 * Slate Control Box
 */
const SlateControlEditingBox = withStyles(theme => ({
    root: {
        backgroundColor: grey[100],
        borderRadius: theme.spacing(3),
        display: "inline-block"
    }
}))(Box);

/**
 * Suggested view element for this plugin
 */
export const MediaElement = ({ attributes, children, element, printLayoutVariant }) => {
    const selected = useSelected()
    const focused = useFocused()
    const editor = useEditor();
    const { mediaUrls } = editor;
    const theme = useTheme();
    // Show expand/collapse button in edit mode only
    const readOnly = useReadOnly();
    // const printLayout = usePrintLayout();
    const [isPlaying, setPlaying] = useState(false);
    const audioElementReference = useRef();
    let isInline = Boolean(element.isInline);
    let showControls = Boolean(element.showControls);
    let mediaElement = null;
    const toggleAudio = () => {
        if (Boolean(isPlaying)) {
            audioElementReference.current.pause();
        } else {
            audioElementReference.current.play();
        }
        setPlaying(!isPlaying);
    }
    const toggleControls = () => {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { showControls: !showControls }, { at: path });

    }
    switch (element.type) {
        case "image":
            // Image
            mediaElement =
                <img
                    src={element.url}
                    className={css`
                        display: block;
                        max-width: 100%;
                        max-height: 20em;
                        box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
                    `} />
            break;
        case "audio":
            const extension = element.extension;
            const mediaIndex = (mediaUrls || []).indexOf(element.url) || 0;
            // Audio
            mediaElement =
                <React.Fragment>
                    <Box component="span" displayPrint="none">
                        <SlateControlEditingBox>
                            <AudioPlayer
                                src={element.url}
                                extension={extension}
                                showControls={showControls}
                                printLayout={printLayoutVariant === "index" ? "default" : "qr-code"} />
                            {!Boolean(readOnly) &&
                                <IconButton size="small" style={{ verticalAlign: "top", padding: 0, marginTop: theme.spacing(0.5), marginRight: theme.spacing(Boolean(showControls) ? 1.5 : 0.5) }} onClick={toggleControls}>
                                    <Icon style={{ fontSize: "0.8rem" }}>{Boolean(showControls) ? "fullscreen_exit" : "fullscreen"}</Icon>
                                </IconButton>}
                        </SlateControlEditingBox>
                    </Box>
                    {/* Display Index */}
                    <Box component="span" mx={1} display="none" displayPrint="inline-block">
                        <a href={element.url} style={{ textDecoration: "none" }}>
                            <Chip
                                style={{ cursor: "pointer" }}
                                icon={<Icon>audiotrack</Icon>}
                                label={<Typography variant="subtitle2">{mediaIndex + 1}</Typography>}
                                color="primary" size="small" />
                        </a>
                    </Box>
                </React.Fragment>
            break;
        default:
            break;
    }
    //<div {...attributes} contentEditable={false}>
    return (
        <div {...attributes} contentEditable={false} style={{ display: isInline ? "inline-block" : "block", verticalAlign: "middle" }}>
            {mediaElement}
            {children}
        </div>
    )
}

export const InsertMediaLinkButton = (props) => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const { type } = props;
    const editor = useEditor()
    const isMediaActive = () => {
        const [link] = Editor.nodes(editor, { match: n => n.type === type })
        return !!link
    }
    return (
        <IconButton
            size='small'
            className={isMediaActive() ? null : classes.inactive}
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt(`Enter the URL of the ${type}:`)
                if (!url) return
                const extension = url.split('.').pop().toLowerCase();
                insertMedia(editor, type, extension, url)
            }} >
            <Icon>{SUPPORTED_MEDIA[type].icon}</Icon>
        </IconButton>
    )
}

export const SelectMediaButton = (props) => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const { type } = props;
    const editor = useEditor();
    const isMediaActive = () => {
        const [link] = Editor.nodes(editor, { match: n => n.type === type })
        return !!link
    }
    return (
        <UploadMediaToFirebaseStorageButton
            className={isMediaActive() ? null : classes.inactive}
            type={type}
            callback={(extension, url) => insertMedia(editor, type, extension, url)} />
    );
}

export const InsertAndEditImageButton = (props) => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const { type } = props;
    const editor = useEditor();
    const isMediaActive = () => {
        const [link] = Editor.nodes(editor, { match: n => n.type === type })
        return !!link
    }
    return (
        <IconButton
            size='small'
            className={isMediaActive() ? null : classes.inactive}
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt(`Enter the URL of the ${type}:`)
                if (!url) return
                insertMedia(editor, type, null, url)
            }} >
            <Icon>{SUPPORTED_MEDIA[type].icon}</Icon>
        </IconButton>
    )
}

/**
 * Wrapper to override Editor from 'slate'
 */
export const withMedia = (editor, options) => {
    const { insertData, isVoid, account } = editor
    const { asDataUrl } = options || {};

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')
        const { files } = data

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader();
                const [mime, extension] = file.type.split('/')
                if (Object.keys(SUPPORTED_MEDIA).includes(mime)) {
                    if (Boolean(asDataUrl)) {
                        // As DataUrl
                        reader.addEventListener('load', () => {
                            const url = reader.result
                            insertMedia(editor, mime, extension, url)
                        })
                        reader.readAsDataURL(file)
                    } else {
                        // Upload to Firebase Cloud Storage
                        console.log(`Filename: ${file.name}`);
                        storage.ref().child(`${account.email}/${mime}/${new Date().getTime()}-${file.name}`).put(file).then(snapshot => {
                            snapshot.ref.getDownloadURL().then(url => {
                                insertMedia(editor, mime, extension, url)
                            });
                        });
                    }
                }

                console.log(`MIME: ${mime}`)
            }
        } else if (isImageUrl(text)) {
            // insertImage(editor, text)
            insertMedia(editor, "image", null, text);
        } else {
            insertData(data)
        }
    }

    return editor
}

/**
 * Private methods
 */
const insertMedia = (editor, type, extension, url) => {
    const text = { text: '' }
    const { selection } = editor;
    const media = { type, extension, url, isInline: true, children: [text] }
    Transforms.insertNodes(editor, media)
    // Insert an empty text child so the cursor can be located properly
    Transforms.insertText(editor, "");
}

const isImageUrl = url => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext)
}


/**
 * Media Editing Dialog
 */
const CANVAS_MARKERS = [
    { type: "stroke", border: { width: 1, style: "dotted", color: "#0000FF" }, coordinates: [0, 0, 30, 100] },
    { type: "fillRect", color: "#00FF00", coordinates: [80, 180, 30, 100] },
    { type: "listMarker", coordinates: [50, 20], text: "This is a text marker" },
]
export default function ImageEditingDialog(props) {
    const [open, setOpen] = useState(props.open !== undefined ? props.open : true);
    const [cropper, setCropper] = useState();
    const [croppedImageDataUrl, setCroppedImageDataUrl] = useState();
    const [canvasMarkers, setCanvasMarkers] = useState(CANVAS_MARKERS);
    const [currentMarker, setCurrentMarker] = useState();
    const imageRef = useRef();
    const canvasRef = useRef();
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fullScreen = true;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadImage = (fileBlob) => {
        let fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            let dataUrl = fileReader.result;
            imageRef.current.src = dataUrl;
        });
        fileReader.readAsDataURL(fileBlob);
    }

    const initializeCropper = () => {
        let cropper = new Cropper(
            imageRef.current, {
            viewMode: 2,
            // dragMode: 'none',
            // scalable: true,
            // aspectRatio: 1,
        });
        setCropper(cropper);
    }

    const confirmImage = () => {
        let dataUrl = cropper.getCroppedCanvas({
            // width: 1000,
            // height: 1000,
            // maxWidth: 1000,
            // maxHeight: 1000
        }).toDataURL();
        // setCroppedImageDataUrl(dataUrl);
        let image = new Image();
        image.src = dataUrl;
        image.addEventListener("load", () => {
            let canvasContext = canvasRef.current.getContext("2d");
            canvasContext.drawImage(image, 0, 0);
            // canvasRef.current.addEventListener("mousedown", (e) => {
            //     setCurrentMarker({
            //         type: "rect",
            //         clientLeft: imageRef.current.clientLeft,
            //         clientTop: imageRef.current.clientTop,
            //         clientWidth: imageRef.current.clientWidth,
            //         clientHeight: imageRef.current.clientHeight,
            //         offsetLeft: imageRef.current.offsetLeft,
            //         offsetTop: imageRef.current.offsetTop,
            //         offsetWidth: imageRef.current.offsetWidth,
            //         offsetHeight: imageRef.current.offsetHeight,
            //     });
            // });
        });
    }

    const rotate = (degree) => {
        cropper.rotate(degree);
    }

    const loadImageOriginal = (fileBlob) => {
        let fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
            let dataUrl = fileReader.result;
            let image = new Image;
            // Get the canvas DOM element
            let canvasElement = document.getElementById("image-to-edit");
            let context = canvasElement.getContext("2d");
            image.onload = function () {
                context.drawImage(image, 0, 0, 110, 330, 10, 10, 110, 330);
                imageRef.current.addEventListener("mousedown", (e) => {
                    console.log({
                        clientLeft: imageRef.current.clientLeft,
                        clientTop: imageRef.current.clientTop,
                        clientWidth: imageRef.current.clientWidth,
                        clientHeight: imageRef.current.clientHeight,
                        offsetLeft: imageRef.current.offsetLeft,
                        offsetTop: imageRef.current.offsetTop,
                        offsetWidth: imageRef.current.offsetWidth,
                        offsetHeight: imageRef.current.offsetHeight,
                    });
                    console.log(e);
                });
            };
            image.src = dataUrl;
        })
        fileReader.readAsDataURL(fileBlob);
    }

    const handleMouseDown = (event) => {
        let containerTop = event.currentTarget.offsetTop;
        let containerLeft = event.currentTarget.offsetLeft;
        let cursorY = event.nativeEvent.y;  // event.clientY
        let cursorX = event.nativeEvent.x;  // event.clientX
        if (Boolean(currentMarker) && Boolean(currentMarker.type)) {
            setCurrentMarker(Object.assign({}, currentMarker, {
                coordinates: [cursorY - containerTop, cursorX - containerLeft, 0, 0]
            }));
        }
    }
    const handleMouseMove = (event) => {
        if (Boolean(currentMarker) && Boolean(currentMarker.coordinates)) {
            // let height = event.nativeEvent.offsetY - currentMarker.coordinates[0];
            // let width = event.nativeEvent.offsetX - currentMarker.coordinates[1];
            let height = currentMarker.coordinates[2] + event.nativeEvent.movementY;
            let width = currentMarker.coordinates[3] + event.nativeEvent.movementX;
            setCurrentMarker(Object.assign({}, currentMarker, {
                coordinates: [currentMarker.coordinates[0], currentMarker.coordinates[1], height, width],
            }));
        }
    }
    const handleMouseUp = (event) => {
        if (currentMarker) {
            setCanvasMarkers([...canvasMarkers, ...[currentMarker]]);
            setCurrentMarker(null);
        }
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}>
                <DialogTitle>Insert and Edit Image</DialogTitle>
                <DialogContent>
                    {/* Image Cropper */}
                    <div style={{ border: "1px dotted #0000FF", maxWidthssss: "100%" }}>
                        <img ref={imageRef} onLoad={initializeCropper} stylessss={{ maxWidth: "100%" }} />
                    </div>
                    {/* End: Image Cropper */}
                    {/* Preview */}
                    <div style={{ border: "1px dotted #0000FF" }}>
                        <img src={croppedImageDataUrl} />
                    </div>
                    {/* End: Preview */}
                    {/* Canvas */}
                    <div style={{ position: "relative", border: "1px dotted #0000FF", cursor: "crosshair", wdiths: "100%" }}
                        onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                        <canvas ref={canvasRef} style={{ cursor: "crosshair", background: "url('/logo512.png')", widths: "100%" }} />
                        {/* Markers for Preview */}
                        {[...canvasMarkers, ...[currentMarker]].filter(m => Boolean(m) && Boolean(m.coordinates)).map((marker, mIndex) => {
                            let typeSpecificStyles = {};
                            if (marker.type === "stroke") {
                                typeSpecificStyles.border = `${marker.border.width}px ${marker.border.style} ${marker.border.color}`;
                            }
                            if (marker.type === "fillRect") {
                                typeSpecificStyles.backgroundColor = marker.color;
                            }
                            return <div kay={mIndex}
                                style={
                                    Object.assign({},
                                        typeSpecificStyles, {
                                        display: "block",
                                        position: "absolute",
                                        top: marker.coordinates[0],
                                        left: marker.coordinates[1],
                                        height: marker.coordinates[2],
                                        width: marker.coordinates[3],
                                    })}>{marker.text}</div>
                        })}
                        {/* End: Markers for Preview */}
                    </div>
                    {/* End: Canvas */}
                    {/* Debug Messages */}
                    <div style={{ height: 30, width: 1000, whiteSpace: "pre", overflowWrap: "break-word" }}>{JSON.stringify(currentMarker, null, 3)}</div>
                    {/* Debug Messages */}
                    {/* Buttons */}
                    <input accept="image/*"
                        style={{ display: "none" }}
                        id="icon-button-file" type="file"
                        onChange={e => loadImage(e.target.files[0])} />
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" component="span">
                            <Icon>photo_camera</Icon>
                        </IconButton>
                    </label>
                    <Tooltip title="Rotate 90 degree clockwise">
                        <IconButton color="primary" onClick={() => rotate(90)}>
                            <Icon>rotate_right</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate 90 degree anit-clockwise">
                        <IconButton color="primary" onClick={() => rotate(-90)}>
                            <Icon>rotate_left</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add rectangle">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "stroke", border: { width: 1, style: "solid", color: "#FF0000" } })}>
                            <Icon>crop_square</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add filled reatangle">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "fillRect", color: "#00FF00" })}>
                            <Icon>stop</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add text marker">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "textMarker" })}>
                            <Icon>text_fields</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Complete your edit">
                        <IconButton color="primary" onClick={confirmImage}>
                            <Icon>check_circle</Icon>
                        </IconButton>
                    </Tooltip>
                    {/* End: Buttons */}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">Disagree</Button>
                    <Button onClick={handleClose} color="primary" autoFocus>Agree</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}