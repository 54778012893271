import React, { useEffect, useState } from 'react';

/**
 * Material UI
 */
import { Typography, TextField, Icon, IconButton, Button, ButtonGroup, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Speech Recognition
 */
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { useSpeechRecognition } from 'react-speech-kit';

/**
 * Application
 */
import { LANGUAGE_CODE_CHINESE } from '../../../app/Constants';

export const TextFieldWithSpeechRecognition = (props) => {
    const classes = makeStyles(theme => ({
        button: {
            cursor: "pointer",
        }
    }))();
    const { value, onChange, InputProps, languageCode, additionalActions, ...others } = props;
    // const [selectionOffset, setSelectionOffset] = useState();
    const inputTextRef = React.createRef();
    const [isEditing, setEditing] = useState(false);
    const [offsetToAdjust, setOffsetToAdjust] = useState();
    const enableChineseSupport = Boolean(LANGUAGE_CODE_CHINESE === languageCode);

    /**
     * Speech Recognition
     */
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const startListening = () => {
        let offset = inputTextRef.current.selectionStart;
        // setSelectionOffset(offset);
        SpeechRecognition.startListening({ language: 'zh-CN' });
        // listen({ interimResults: false, language: 'yue-Hant-HK' });
    }

    const stopListening = () => {
        SpeechRecognition.stopListening();
    }

    const insertTextToCursor = (text) => {
        let _selectionOffset = inputTextRef.current.selectionStart;
        let leadingText = (value || '').substring(0, _selectionOffset || 0);
        let trailingText = (value || '').substring(_selectionOffset || 0);
        // let newOffet = _selectionOffset + text.length;
        // setOffsetToAdjust(newOffet);
        onChange({ target: { value: `${leadingText}${text}${trailingText}` } });
    }

    useEffect(() => {
        if (Boolean(isEditing) && !listening && Boolean(transcript)) {
            console.log(`Stop listening: ${transcript}`)
            // let leadingText = (value || '').substring(0, selectionOffset || 0);
            // let trailingText = (value || '').substring(selectionOffset || 0);
            // let newOffet = selectionOffset + transcript.length;
            // setOffsetToAdjust(newOffet);
            // onChange({ target: { value: `${leadingText}${transcript}${trailingText}` } });
            insertTextToCursor(transcript);
        }
    }, [listening])

    useEffect(() => {
        if (Boolean(offsetToAdjust)) {
            inputTextRef.current.setSelectionRange(offsetToAdjust, offsetToAdjust);
            setOffsetToAdjust(null);
        }
    }, [value]);

    return (
        <Box display="flex" flexDirection="column" width={Boolean(props.fullWidth) ? "100%" : "auto"}>
            {Boolean(!browserSupportsSpeechRecognition) && <Typography>Speech Recognition not Supported</Typography>}
            <TextField
                {...others}
                inputRef={inputTextRef}
                onChange={onChange}
                onFocus={e => setEditing(true)}
                onBlur={e => setEditing(false)}
                value={value}
                InputProps={{
                    startAdornment: InputProps?.startAdornment,
                    endAdornment:
                        <React.Fragment>
                            {/* Enable Chinese Speech Recognition Only When Language is Chinese */}
                            {Boolean(isEditing) &&
                                <React.Fragment>
                                    {Boolean(enableChineseSupport) && Boolean(browserSupportsSpeechRecognition) &&
                                        <IconButton size="small">
                                            <Icon fontSize="small" style={{ alignItems: "flex-start" }}
                                                onClick={Boolean(listening) ? stopListening : startListening}
                                                onMouseDown={e => e.preventDefault()}   // Suppress the onBlur event
                                                className={classes.button}>{Boolean(listening) ? 'stop' : 'mic'}</Icon>
                                            <Typography variant='caption'>中</Typography>
                                        </IconButton>}
                                    {/* Additional Actions */}
                                    {Boolean(additionalActions) && additionalActions}
                                    {/* End: Additional Actions */}
                                </React.Fragment>}
                            {InputProps?.endAdornment}
                        </React.Fragment>
                }}
            />
            {/* Chinese Symbols Toolbar */}
            {Boolean(isEditing) &&
                <Box py={1} display="flex" justifyContent="space-between">
                    {/* Chinese Symbols Toolbar */}
                    <Box>
                        {enableChineseSupport && <ChineseSymbolToolbar handleClick={insertTextToCursor} />}
                    </Box>
                    {/* End: Chinese Symbols Toolbar */}
                </Box>}
        </Box>
    );

}

const CHINESE_SYMBOLS = ["，", "。", "：", "；", "「", "」", "《", "》"];
export const ChineseSymbolToolbar = (props) => {
    const { handleClick } = props;
    return (
        <ButtonGroup size="small" style={{ backgroundColor: "#DDDDDD33" }}>
            {CHINESE_SYMBOLS.map(s =>
                <Button size="small"
                    onMouseDown={e => e.preventDefault()}
                    onClick={e => {
                        e.preventDefault();
                        handleClick(s);
                    }}>{s}</Button>)}
        </ButtonGroup>
    )
}