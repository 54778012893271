import React, { useState } from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';

/**
 * Application
 */
import {
    PaperAttemptTable,
    PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_OUTBOX,
    PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX,
    PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX,
    PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX,
} from '../test-paper/papers/PaperAttemptTable';
import { USER_ROLE_CHILD } from '../Constants';

/**
 * Constants
 */
const LOCAL_STORAGE_KEY_DASHBOARD_ACTIVE_SELECTION = "dashboard.activeSelection";

export function Dashboard(props) {
    const classes = makeStyles(theme => ({
        sectionsAppBar: {
            position: "fixed",
            top: "auto",
            bottom: 0
        },
        sectionToolbar: {
            minHeight: 0
        },
    }))();
    const { user } = props;
    const defaultSelection = localStorage.getItem(LOCAL_STORAGE_KEY_DASHBOARD_ACTIVE_SELECTION) || (user.role === USER_ROLE_CHILD) ? PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX : PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX;
    const [activeSection, setActiveSection] = useState(defaultSelection);
    const selectTab = (newValue) => {
        localStorage.setItem(LOCAL_STORAGE_KEY_DASHBOARD_ACTIVE_SELECTION, newValue);
        setActiveSection(newValue);
    }
    return (
        <React.Fragment>
            <PaperAttemptTable {...props} view={activeSection} />
            <AppBar className={classes.sectionsAppBar}>
                <Toolbar className={classes.sectionToolbar}>
                    <Tabs value={activeSection} onChange={(e, newValue) => selectTab(newValue)} variant="scrollable" scrollButtons="auto" style={{ flexGrow: 1 }}>
                        {Boolean(user) && (user.role !== USER_ROLE_CHILD) &&
                            <Tab value={PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX} label="Assignments - Returned" wrapped />}
                        {Boolean(user) && (user.role !== USER_ROLE_CHILD) &&
                            <Tab value={PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_OUTBOX} label="Assignments - Sent" wrapped />}
                        <Tab value={PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX} label="Assignments - Received" wrapped />
                        <Tab value={PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX} label="Your Results" wrapped />
                    </Tabs>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}


