import React, { useState } from 'react';

/**
 * Material UI
 */
import { Box, Button, Container, Grid } from '@material-ui/core';
import { fillInBlankSectionConfigurationsDataPatch, multipleChoiceSectionRichTextTypeDataPatch } from '../../job/adhoc/DataPatching';


export const DataPatchingPanel = (props) => {
    const { account } = props;
    const [results, setResults] = useState();

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box whiteSpace="preserve">
                        Result: {JSON.stringify(results, null, 3)}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary"
                        onClick={() =>
                            multipleChoiceSectionRichTextTypeDataPatch(account).then(ids => setResults(ids))}>Multiple Choice Section Richtext Type Data Patch</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary"
                        onClick={() =>
                            fillInBlankSectionConfigurationsDataPatch(account).then(ids => setResults(ids))}>Fill in Blank Section configurations Data Patch</Button>
                </Grid>

            </Grid>
        </Container>
    )
}