const CHINESE_REGEX = /[\u3400-\u9FBF]/;

export const VOICE_LANGUAGES = {
    cantonese: {
        order: 0,
        codes: ['zh-HK', 'yue_HK_#Hant'],
        label: '粵',
        name: 'Cantonese'
    }, mandarin: {
        order: 1,
        codes: ['zh-CN', 'zh_CN_#Hans', 'zh_CN_#Hans'],
        label: '普',
        name: 'Mandarin'
    }, english: {
        order: 2,
        codes: ['en-GB', 'en_GB'],
        label: 'Eng',
        name: 'English'
    }
};

export const isChinese = (text) => {
    if (Boolean(text) && text.match(CHINESE_REGEX)) {
        return true;
    }
    return false;
}

export const getVoiceLanguageCodeByKey = (voiceKey) => {
    return VOICE_LANGUAGES[voiceKey]?.codes;
}

export const getVoiceLanguageCodeByText = (text) => {
    let key = Boolean(isChinese(text)) ? "cantonese" : "english";
    return VOICE_LANGUAGES[key]?.codes || [];
}

export const getChineseExplanation = async (text) => {
    let firstCharacter = text.substring(0, 1);
    let response = await fetch(`/json/chinese-dictionary/${firstCharacter}.json`);
    let json = await response.json();
    return json[text];
}
