import React, { } from 'react';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const GenericSectionConfigurationEditor = (props) => {
    const classes = makeStyles(theme => ({
        checkboxLabel: {
            fontWeight: 800,
            fontSize: '0.8rem'
        }
    }))();
    const { section, updateSectionConfiguration } = props;
    const { configurations = {} } = section;
    return (
        <React.Fragment>
            <FormControlLabel
                className={classes.switchLabel}
                control={
                    <Checkbox
                        checked={Boolean(configurations?.provideOptions)}
                        onChange={() => updateSectionConfiguration("provideOptions", !Boolean(configurations?.provideOptions))}
                        color="primary" />}
                label={<Typography className={classes.checkboxLabel}>With Options?</Typography>} />
            <FormControlLabel
                className={classes.switchLabel}
                control={
                    <Checkbox
                        checked={Boolean(configurations?.shuffleQuestions)}
                        onChange={() => updateSectionConfiguration("shuffleQuestions", !Boolean(configurations?.shuffleQuestions))}
                        color="primary" />}
                label={<Typography className={classes.checkboxLabel}>Shuffle?</Typography>} />
        </React.Fragment>
    );
}
