import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Typography, Box } from '@material-ui/core';

export function LoadingMask(props) {

    const classes = makeStyles(theme => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }))();

    const { message, open } = props;

    return (
        <Backdrop className={classes.backdrop} open={open || true} >
            <CircularProgress color="inherit" />
            <Box mx={2}>
                <Typography variant="h6">{message}...</Typography>
            </Box>
        </Backdrop>);
}