import React from 'react';

/**
 * Material UI
 */
import { FormGroup, FormControlLabel, FormLabel, Switch, Box } from '@material-ui/core';

export function DualLabelSwitch(props) {
    const { onLabel, offLabel, onChange, value, color, labelClass } = props;
    return (
        <FormGroup row className={labelClass}>
            <FormControlLabel label={onLabel}
                className={labelClass}
                control={
                    <React.Fragment>
                        <Box pl={1.5}>
                            <FormLabel size="small" color="primary.main" className={labelClass}>{offLabel}</FormLabel>
                        </Box>
                        <Switch size="normal" onChange={onChange} checked={value} color={color} />
                    </React.Fragment>
                } />
        </FormGroup>
    );
}