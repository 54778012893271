import React, { useState } from 'react';

/**
 * Material UI
 */
import { IconButton, Icon, Menu, MenuItem, Typography } from '@material-ui/core';

/**
 * 
 * @param {*} props 
 * actions: [{
 *      label: The label of the menu item
 *      handler: The no argument handler when the menu item is clicked
 * }]
 */
export function ActionMenu(props) {
    const { actions } = props;
    const [anchorEl, setAnchorEl] = useState();
    return (
        <React.Fragment>
            <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
                <Icon size="small">more_vert</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={e => setAnchorEl(null)} style={{ textAlign: "center" }}>
                {actions.map((action, aIndex) =>
                    <MenuItem key={aIndex} onClick={() => {
                        action.handler();
                        setAnchorEl(null);
                    }}>
                        <Typography variant="body2">{action.label}</Typography>
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    )
}