import React, { useState, useEffect, useContext } from 'react';

/**
 * Material UI
 */
import { Container, Grid, Button, useMediaQuery, Menu, IconButton, MenuItem, Typography, Icon, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";

/**
 * Firebase
 */
import { firestore, getFirestoreTimestamp } from '../../../common/serviecs/firebase';
import { LoadingMask } from '../../../common/components/controls/LoadingMask';

/**
 * Application
 */
import { NavigationContext, UserContext } from '../../../App';
import { formatTimestamp } from '../../../common/functions/DateTimeUtils';
import { AddToCollectionDialog } from '../collections/Collection';
import { AssignmentDialog } from '../paper-editors/AssignmentDialog';

const COLUMNS_LARGE_SCREEN = [
    { name: "id", label: "ID", options: { filter: false, sort: false, display: "excluded" } },
    { name: "grade", label: "Grade", options: { setCellHeaderProps: () => ({ style: { width: "100px" } }) } },
    { name: "subject", label: "Subject" },
    { name: "combinedTitle", label: "Title", options: { filter: false, setCellHeaderProps: () => ({ style: { width: "38%" } }) } },
    { name: "title", label: "Title", options: { display: "excluded" } },
    { name: "subtitle", label: "Sub Title", options: { display: "excluded" } },
    { name: "school", label: "School" },
    { name: "createdAt", label: "Created" },
    { name: "action", label: " ", options: { filter: false, sort: false, setCellProps: (v) => ({ style: { textAlign: "right" } }) } },
    { name: "taggings", label: "Taggings", options: { filter: true, filterType: "multiselect", sort: false, display: "excluded" } },
];
const COLUMNS_SMALL_SCREEN = [
    { name: "id", label: "ID", options: { filter: false, sort: false, display: "excluded" } },
    { name: "headline", label: "Headline", options: { filter: false, sort: false, setCellProps: () => ({ style: { fontSize: "1rem", fontWeight: 800 } }) } },
    { name: "title", label: "Title", options: { display: "excluded", sort: false } },
    { name: "subtitle", label: "Subtitle", options: { display: "excluded", sort: false } },
    { name: "sectionInfo", label: "Section Details", options: { filter: false, sort: false } },
    { name: "creationStatus", label: "Creation Status", options: { filter: false, sort: false } },
    { name: "action", label: " ", options: { filter: false, sort: false, setCellProps: (v) => ({ style: { textAlign: "right" } }) } },
    { name: "grade", label: "Grade", options: { display: "excluded" } },
    { name: "subject", label: "Subject", options: { display: "excluded" } },
    { name: "title", label: "Title", options: { display: "excluded" } },
    { name: "school", label: "School", options: { display: "excluded" } },
    { name: "taggings", label: "Taggings", options: { filter: true, sort: false, display: "excluded" } },
];

export function PaperTable(props) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { account, user } = useContext(UserContext);
    const { goto } = useContext(NavigationContext);
    const title = props.title || "Search for Test Papers";
    const [papers, setPapers] = useState(null);
    const retrievePapers = () => {
        var retrievedPapers = [];
        firestore.collection("test-papers").where("ownerUserId", "==", account.ownerUserId).orderBy("updatedAt", "desc").get().then(query => {
            query.forEach(doc => {
                retrievedPapers.push(
                    transformPaper(
                        Object.assign({ id: doc.id }, doc.data()),
                        account, user, goto, deletePaper, duplicatePaper));
            })
            setPapers(retrievedPapers);
        });
    }
    /**
     * TODO: Not being used for the moment
     */
    const duplicatePaper = async (paperId) => {
        let currentTimestamp = getFirestoreTimestamp();
        let documentRef = await firestore.collection("test-papers").doc(paperId).get();
        let documentToDuplicate = Object.assign({}, documentRef.data(), { createdAt: currentTimestamp, updatedAt: currentTimestamp });
        let documentRefDuplicated = firestore.collection("test-papers").add(documentToDuplicate);
        //goto(`/papers/${docRef.id}/edit`)
    }
    const deletePaper = (paperId) => {
        // TODO: Add dialog confirmation
        var docRef = firestore.collection("test-papers").doc(paperId);
        docRef.collection("paper-attempts").get().then(attemptsRef => {
            // Delete all the sub-collections
            attemptsRef.forEach(attemptRef => {
                attemptRef.ref.delete();
            });
            // Delete the paper
            docRef.delete().then(() => {
                retrievePapers();
            });
        })
    }
    useEffect(() => {
        if (Boolean(props.papers)) {
            setPapers(props.papers.map(paper => transformPaper(paper, account, user, goto, deletePaper, duplicatePaper)));
        } else {
            retrievePapers();
        }
    }, [account]);
    return (
        Boolean(papers) ?
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={title}
                            data={papers}
                            columns={isSmallScreen ? COLUMNS_SMALL_SCREEN : COLUMNS_LARGE_SCREEN}
                            options={{
                                fixedHeaderOptions: { yAxis: true },
                                responsive: "stacked",
                                print: false,
                                download: false,
                                viewColumns: false,
                                rowHover: true,
                                selectableRows: "none"
                            }}
                        />
                    </Grid>
                </Grid>
            </Container > : <LoadingMask message="Loading your test papers" />
    );
}

function transformPaper(paper, account, user, goto, deletePaper, duplicatePaper) {
    let createdAt = Boolean(paper.createdAt) ? formatTimestamp(paper.createdAt.toDate()) : "-";
    let updatedAt = Boolean(paper.createdAt) ? formatTimestamp(paper.createdAt.toDate()) : "-";
    let actions = [
        { label: "Take", handler: () => goto(`/papers/${paper.id}`) },
        { label: "Print", handler: () => goto(`/papers/${paper.id}/print`) },
        { label: "Edit", handler: () => goto(`/papers/${paper.id}/edit`) },
        { label: "Delete", handler: () => deletePaper(paper.id) },
        // { label: "Duplicate", handler: () => duplicatePaper(paper.id) }
        { label: "Duplicate...", handler: () => goto(`/papers/${paper.id}/duplicate`) }
    ]
    return Object.assign({}, paper,
        {
            headline: [[paper.grade, paper.subject].join(" "), paper.title, paper.subtitle].join(" - "),
            combinedTitle: [paper.title, paper.subtitle].filter(t => Boolean(t)).join(" - "),
            sectionInfo: `${(paper.sections || []).length} section(s) - Full Score: ${paper.fullScore}`,
            timeLimit: `${paper.timeLimitInMinutes} mins`,
            createdAt: createdAt,
            updatedAt: updatedAt,
            creationStatus: `Created by ${paper.author.fullName} at ${createdAt}`,
            action: <React.Fragment>
                <Box display={{ xs: "block", md: "none" }}>
                    <PaperActionButtons user={user} account={account} paper={paper} actions={actions} />
                </Box>
                <Box display={{ xs: "none", md: "block" }}>
                    <PaperActionMenu user={user} account={account} paper={paper} actions={actions} />
                </Box>
            </React.Fragment>,
        });
}

const paperActionButtonsStyles = makeStyles(theme => ({
    narrowButton: {
        minWidth: theme.spacing(4)
    }
}));
function PaperActionButtons(props) {
    const classes = paperActionButtonsStyles();
    const { account, user, paper, actions } = props;
    const [showAssignToDialog, setShowAssignToDialog] = useState(false);
    const [showAddToCollectionDialog, setShowAddToCollectionDialog] = useState(false);
    return (
        <React.Fragment>
            {/* Assign To Dialog */}
            {Boolean(showAssignToDialog) &&
                <AssignmentDialog
                    account={account}
                    user={user}
                    paper={paper}
                    handleClose={() => setShowAssignToDialog(false)} />}
            {/* End: Assign To Dialog */}

            {/* Add to Collection Dialog */}
            {Boolean(showAddToCollectionDialog) &&
                <AddToCollectionDialog user={user}
                    paper={paper}
                    open={showAddToCollectionDialog}
                    handleClose={() => setShowAddToCollectionDialog(false)} />}
            {/* End: Add to Collection Dialog */}
            {actions.map((action, aIndex) =>
                <Button className={classes.narrowButton} key={aIndex} color="primary" size="small" style={{ marginLeft: 0, marginRight: 0 }} onClick={action.handler}>{action.label}</Button>)}
            <Button className={classes.narrowButton} key={actions.length} color="primary" size="small" style={{ marginLeft: 0, marginRight: 0 }} onClick={() => setShowAssignToDialog(true)}>Assign</Button>
            <Button className={classes.narrowButton} key={actions.length + 1} color="primary" size="small" style={{ marginLeft: 0, marginRight: 0 }} onClick={() => setShowAddToCollectionDialog(true)}>Add to Collection</Button>
        </React.Fragment >
    )
}

function PaperActionMenu(props) {
    const { account, user, paper, actions } = props;
    const [anchorEl, setAnchorEl] = useState();
    const [showAssignToDialog, setShowAssignToDialog] = useState(false);
    const [showAddToCollectionDialog, setShowAddToCollectionDialog] = useState(false);
    return (
        <React.Fragment>
            {/* Assign To Dialog */}
            {Boolean(showAssignToDialog) &&
                <AssignmentDialog
                    account={account}
                    user={user}
                    paper={paper}
                    handleClose={() => setShowAssignToDialog(false)} />}
            {/* End: Assign To Dialog */}

            {/* Add to Collection Dialog */}
            {Boolean(showAddToCollectionDialog) &&
                <AddToCollectionDialog user={user}
                    paper={paper}
                    open={showAddToCollectionDialog}
                    handleClose={() => setShowAddToCollectionDialog(false)} />}
            {/* End: Add to Collection Dialog */}

            <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
                <Icon size="small">more_vert</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={e => setAnchorEl(null)} style={{ textAlign: "center" }}>
                {actions.map((action, aIndex) =>
                    <MenuItem key={aIndex} onClick={() => {
                        action.handler();
                        setAnchorEl(null);
                    }}>
                        <Typography variant="body2">{action.label}</Typography>
                    </MenuItem>
                )}
                <MenuItem key={-1} onClick={() => { setAnchorEl(false); setShowAssignToDialog(true); }}>
                    <Typography variant="body2">Assign ...</Typography>
                </MenuItem>
                <MenuItem key={-1} onClick={() => { setAnchorEl(false); setShowAddToCollectionDialog(true); }}>
                    <Typography variant="body2">Add to Collection</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}