import React, { useState, useEffect } from 'react';

/**
 * Firebase
 */
import { firestore } from '../../serviecs/firebase';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Icon, Menu, Typography, Badge, Divider, ListItem, ListItemIcon, ListItemText, Avatar, Box } from '@material-ui/core';

export const notifyUser = async (user, notification) => {
    firestore.collection(`/users/${user.id}/notifications`)
        .add(Object.assign({ read: false }, notification)).then(docRef => {
            //Do nth
        })
}

/**
 * Login Menu
 */
export function NotificationMenu(props) {
    const { user, goto } = props;
    const userId = user.id;
    const classes = makeStyles(theme => ({
        button: {
            color: "white"
        },
        menu: {
            minWidth: 350
        },
        listItem: {
            paddingLeft: theme.spacing(2)
        },
        listItemIcon: {
            // minWidth: theme.spacing(5)
        },
        unreadText: {
            whiteSpace: "pre-line",
            fontWeight: 800,
            color: theme.palette.primary.main
        },
        unreadIcon: {
            whiteSpace: "pre-line",
            color: theme.palette.primary.main
        }
    }))();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notifications, setNotifications] = useState([]);
    const [unsubscribe, setUnsubscribe] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (Boolean(unsubscribe)) {
            // Unsubscript the previous subscription
            unsubscribe();
        }
        var unsubscribeFunction = firestore.collection(`/users/${userId}/notifications`).orderBy("createdAt", "desc").onSnapshot(notificationsRef => {
            let retrievedNotifications = []
            notificationsRef.forEach(notificationRef => {
                var retrievedNotification = Object.assign({ id: notificationRef.id }, notificationRef.data());
                retrievedNotifications.push(retrievedNotification);
                // Send Browser Notification
                // if (Notification.permission === "granted" && !Boolean(retrievedNotification.read) && !Boolean(retrievedNotification.notified)) {
                //     try {
                //         let notification = new Notification(
                //             process.env.REACT_APP_APPLICATION_NAME,
                //             {
                //                 body: retrievedNotification.message,
                //                 icon: "/logo192.png"
                //             });
                //         notification.onclick = (event) => {
                //             event.preventDefault();
                //             goto(retrievedNotification.path);
                //             updateNotification(retrievedNotification.id, { read: true });
                //         }
                //         notification.onclose = (event) => {
                //             event.preventDefault();
                //             updateNotification(retrievedNotification.id, { notified: true });
                //         }
                //         // setTimeout(() => { updateNotification(retrievedNotification.id, "notified", true); }, 3000);
                //     } catch (error) {
                //         // Do nth
                //     }
                // }
            });
            setNotifications(retrievedNotifications);
        });
        setUnsubscribe(() => unsubscribeFunction);
    }, [userId]);

    const updateNotification = (id, updatedAttributes) => {
        firestore.collection(`/users/${user.id}/notifications`).doc(id).set(updatedAttributes, { merge: true });
    }

    return (
        <div>
            <IconButton id={`notification-button-${user.id}`} className={classes.button} onClick={handleClick} disabled={notifications.length === 0}>
                <Badge badgeContent={notifications.filter(n => n.read === false).length} color="error" showZero={false}>
                    <Icon>notifications</Icon>
                </Badge>
            </IconButton>
            <Menu id={`notification-menu-${user.id}`} className={classes.menu} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {notifications.map((notification, nIndex) => {
                    let readHandler = notification.read ? () => { } : () => updateNotification(notification.id, { read: true });
                    let handler = Boolean(notification.path) ? () => goto(notification.path) : () => { }
                    return (
                        <Box key={`notification-${nIndex}`}>
                            <ListItem className={classes.listItem} button
                                onClick={() => { readHandler(); handler(); handleClose(); }}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    {notification.avatarUrl ? <Avatar src={null} /> :
                                        <Icon className={notification.read ? null : classes.unreadIcon}>{notification.type}</Icon>}
                                </ListItemIcon>
                                <ListItemText>
                                    <Box mr={2}>
                                        <Typography className={notification.read ? null : classes.unreadText} variant="body2">{notification.message}</Typography>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            {nIndex < (notifications.length - 1) ? <Divider /> : null}
                        </Box>
                    )
                })}
            </Menu >
        </div >
    );
}