import React from 'react';

import { Backdrop, Typography, Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const PilotExpiryBackdrop = (props) => {
    const classes = makeStyles(theme => ({
        expiryBackdrop: {
            zIndex: theme.zIndex.drawer + 100,
            color: '#fff',
        }
    }))();
    return (
        <Backdrop open={true} className={classes.expiryBackdrop}>
            <Container maxWidth="lg">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mb={6}>
                        <Typography variant='h3'>Your Test Paper Online Trial Has Expired</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h6'>
                            It seems your free trial of Test Paper Online has expired. We're confident that Test Paper Online can help you achieve your educational goals. To continue using all the amazing features of our platform, please contact the Test Paper Online team.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Backdrop>
    )
}