import { Icon, IconButton, TextField, createGenerateClassName, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

export const NumericControl = (props) => {
    const { value, min, max, step = 1, onChange, ...others } = props;
    const classes = makeStyles(theme => ({
        textField: {
            padding: theme.spacing(0)
        },
        inputField: {
            textAlign: "center",
            width: "2rem",
            padding: theme.spacing(0),
        },
        inputFieldContainer: {
            padding: theme.spacing(0),
        }
    }))();

    const changeValue = (step) => {
        let newValue = (value + step);
        if ((newValue >= min) && (newValue <= max)) {
            onChange(newValue)
        }
    }

    return (
        <TextField
            disabled={true}
            className={classes.textField}
            value={value}
            inputProps={{ className: classes.inputField }}
            InputProps={{
                className: classes.inputFieldContainer,
                disableUnderline: true,
                startAdornment:
                    <IconButton
                        disabled={Boolean(value === max)}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => changeValue(+1 * step)}>
                        <Icon>add_circle</Icon>
                    </IconButton>,
                endAdornment:
                    <IconButton
                        disabled={Boolean(value === min)}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => changeValue(-1 * step)}>
                        <Icon>remove_circle</Icon>
                    </IconButton>
            }}
            {...others}
        />
    );
}