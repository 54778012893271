import React, { useEffect, useState, useContext } from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@material-ui/core';

/**
 * Application
 */
import { UserContext, NavigationContext } from '../../../App';
import { firestore, getFirestoreTimestamp } from '../../../common/serviecs/firebase';
import { ActionMenu, DeleteDocumentFromFirebaseDialog } from '../../../common/components/controls';
import { LoadingMask } from '../../../common/components/controls/LoadingMask';

const useStyles = makeStyles((theme) => ({
    cardImage: {
        margin: theme.spacing(1),
        height: 120
    },
    collectionDescription: {
        whiteSpace: "pre-wrap"
    }
}))
export function CollectionCards(props) {
    const { user } = useContext(UserContext);
    const { goto } = useContext(NavigationContext);
    const classes = useStyles();
    const [collections, setCollections] = useState();
    const [collectionToDelete, setCollectionToDelete] = useState();

    const retrieveCollections = (user) => {
        setCollections(null);
        firestore.collection(`/users/${user.id}/collections`).orderBy("title", "asc").get().then(collectionsRef => {
            let retrievedCollections = [];
            collectionsRef.forEach(doc => {
                retrievedCollections.push(
                    Object.assign(
                        { id: doc.id },
                        doc.data()));
            });
            setCollections(retrievedCollections);
        });
    }

    useEffect(() => {
        if (!Boolean(collections)) {
            retrieveCollections(user);
        }
    }, [user]);

    return (
        <Container maxWidth="lg">
            {/* Confirm Deletion Dialog */}
            <DeleteDocumentFromFirebaseDialog
                open={Boolean(collectionToDelete)}
                confirmCallback={() => retrieveCollections(user)}
                handleClose={() => setCollectionToDelete(null)}
                collectionPath={`/users/${user.id}/collections`}
                documentId={Boolean(collectionToDelete) ? collectionToDelete.id : null}
                title={"Confirm Deletion"}
                contentText={`Are you sure to delete the collection '${Boolean(collectionToDelete) ? collectionToDelete.title : null}'?`} />
            {/* End: Confirm Deletion Dialog */}
            <Grid container spacing={2}>
                {Boolean(collections) ?
                    collections.length === 0 ?
                        <Box textAlign="center" mt={8} width="100%">
                            <Typography variant="body1">You don't have any collection yet</Typography>
                        </Box>
                        :
                        collections.map((collection, cIndex) => {
                            let collectionActions = [{
                                label: "Delete Collection",
                                handler: () => setCollectionToDelete(collection)
                            }];
                            return (
                                <React.Fragment>
                                    <Grid key={cIndex} item xs={12} sm={6} md={3}>
                                        <Card>
                                            <CardActionArea>
                                                <CardMedia className={classes.cardImage}
                                                    image="/images/beautiful-scenery-colour.jpg"
                                                    title={collection.title}
                                                    onClick={() => goto(`collections/${collection.id}`)} />
                                                <CardContent>
                                                    <Box display="flex" justifyContent="space-between" width="100%">
                                                        <Typography flexGrow={10} variant="h6" component="h2"
                                                            onClick={() => goto(`collections/${collection.id}`)}>{collection.title}</Typography>
                                                        <ActionMenu actions={collectionActions} />
                                                    </Box>
                                                    <Typography className={classes.collectionDescription}
                                                        variant="body2" component="p" color="textSecondary"
                                                        onClick={() => goto(`collections/${collection.id}`)}>{collection.description || "This is a collection for my daughter. This is a collection for my daughter. This is a collection for my daughter."} </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </React.Fragment>
                            );
                        }) :
                    <LoadingMask message="Loading your collections" />
                }
            </Grid>
        </Container >
    );
}