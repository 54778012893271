import React from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, TextField, Typography, Button, FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionEditor } from './SectionEditor';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "true-or-false",
    title: null,
    instruction: null
};
export function TrueOrFalseSectionEditor(props) {
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input" }] }, props.section);
    const { languageCode } = props;
    {/* https://stackoverflow.com/questions/48355322/re-render-array-of-child-components-in-react-after-editing-the-array */ }
    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <TrueOrFalseQuestionEditor
                    key={question.id}
                    question={question}
                    questionIndex={questionIndex}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode} />)}>
        </SectionEditor>
    );
}

/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Type in your question and specify either 'True' or 'False' as the answer." },
};
export function TrueOrFalseQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode } = props;
    const question = Object.assign(props.question);
    const stage = question.stage;
    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            complete(false);
        }
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <React.Fragment>
                    <TextFieldWithSpeechRecognition
                        fullWidth
                        languageCode={languageCode}
                        variant="outlined"
                        multiline={true}
                        value={question.text}
                        placeholder="Input your question here"
                        onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { text: e.target.value }))} />
                    <FormControl component="fieldset">
                        <RadioGroup value={question.answer !== undefined ? (Boolean(question.answer) ? "T" : "F") : question.answer} onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { answer: (e.target.value === "T") }))}>
                            <FormControlLabel value={"T"} control={<Radio color="primary" />} label="True" />
                            <FormControlLabel value={"F"} control={<Radio color="primary" />} label="False" />
                        </RadioGroup>
                    </FormControl>
                </React.Fragment >
            break;
        default:
            editor =
                <React.Fragment>
                    <Box mb={1}>
                        <Typography onClick={isEditingSection ? () => { } : () => setStage("input")}>{question.text}</Typography>
                    </Box>
                    <Typography onClick={isEditingSection ? () => { } : () => setStage("input")}>{Boolean(question.answer) ? "True" : "False"}</Typography>
                </React.Fragment>
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {Boolean(STAGES[stage]) && Boolean(STAGES[stage].previousStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].previousStage)}>Back</Button> : null}
                {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)}
                            disabled={!(Boolean(question.text) && question.answer !== undefined)}> Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)}
                            disabled={!(Boolean(question.text) && question.answer !== undefined)}>Next Question</Button>
                    </React.Fragment> :
                    null}
            </Grid>
        </React.Fragment >
    );
}