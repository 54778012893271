import React from 'react';
import { Switch, Route } from "react-router-dom";

/**
 * Application
 */
import { UserTable } from './UserTable';

export function UserPanel(props) {
    return (
        <Switch>
            <Route exact path="/users">
                <UserTable {...props} />
            </Route>
            <Route exact path={"/users/new"}>
                <UserTable {...props} />
            </Route>
            <Route path={"/users/:id/edit"}>
                <UserTable {...props} user={props.user} />
            </Route>
            <Route path={"/users/:id"} >
                <UserTable {...props} />
            </Route>
        </Switch >
    )
}