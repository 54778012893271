import React from 'react';

/**
 * Material UI
 */
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
export function SectionTitle({ title }) {
    const classes = makeStyles(theme => ({
        sectionTitle: {
            fontWeight: 800,
            marginBottom: theme.spacing(2),
            position: "relative",
            "&::after": {
                position: "absolute",
                top: -5,
                left: -15,
                height: 30,
                width: 30,
                // borderRadius: 3,
                borderBottom: `30px solid ${theme.palette.primary.main}CC`,
                content: `''`,
                //borderColor: theme.palette.primary.main,
                zIndex: -1,
            }
        },
    }))();
    return (
        <Box fontWeight={800}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>{title}</Typography>
        </Box>
    );
}