import React, { useState } from 'react';

/**
 * Slate
 */
import { Editor, Transforms } from 'slate';
import { ReactEditor, useEditor, useReadOnly } from 'slate-react';
// import { Button } from '../controls';

/**
 * Equation Editor
 */
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import EquationEditor from "equation-editor-react";

/**
 * Material UI
 */
import { Button, Icon, Box, makeStyles, Typography, IconButton } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

/**
 * Application
 */
import { cx, css } from 'emotion';

/**
 * Math Expression Slate Element
 */
export const MathExpressionElement = (props) => {
    const { attributes, children, element } = props;
    const [open, setOpen] = useState();
    const editor = useEditor();
    const readOnly = useReadOnly();
    const classes = makeStyles(theme => ({
        editing: {
            display: 'inline-block',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: theme.spacing(0.5),
            borderColor: theme.palette.primary.main,
            padding: theme.spacing(0.5)
        }
    }))();
    return (
        <React.Fragment>
            {/* Editor to update Expression */}
            {!Boolean(readOnly) && Boolean(open) &&
                <MathExpressionEditorDialog
                    value={element.expression}
                    open={true}
                    title="Input your Math Expression"
                    handleConfirm={(expression) => updateExpression(editor, element, expression)}
                    handleClose={() => setOpen(false)} />}
            {/* End: Editor to update Expression */}
            {/* Void Element for Displaying Expression */}
            <span {...attributes} style={{ display: 'inline-block' }}>
                <Box component='span'
                    className={Boolean(open) ? classes.editing : null}
                    onClick={Boolean(readOnly) ? null : () => setOpen(true)}>
                    <InlineMath math={element?.expression} />
                </Box>
                <span {...attributes}>{children}</span>
            </span>
            {/* End: Void Element for Displaying Expression */}
        </React.Fragment>
    );
}


/**
 * Button to Insert Math Expression
 */
export const InsertMathExpressionButton = () => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const [open, setOpen] = useState();
    const editor = useEditor();
    return (
        <React.Fragment>
            <MathExpressionEditorDialog
                open={open}
                title="Input your Math Expression"
                handleConfirm={(expression) => insertMathExpression(editor, expression)}
                handleClose={() => setOpen(false)} />
            <IconButton
                size='small'
                className={classes.inactive}
                onMouseDown={event => {
                    event.preventDefault();
                    setOpen(true)
                }}>
                <Icon>calculate</Icon>
            </IconButton>
        </React.Fragment>
    )
}

/**
 * Wrapper to override Editor from 'slate'
 */
export const withMathExpressions = editor => {
    /**
     * Treat this element as void
     */
    const { isVoid, isInline } = editor
    editor.isVoid = element => {
        return (element.type === 'math-expression' ? true : isVoid(element));
    }
    editor.isInline = element => {
        return (element.type === 'math-expression' ? true : isInline(element));
    }
    return editor;
}

/**
 * Inserting new Math Expression into the Slate Editor
 */
const insertMathExpression = (editor, expression) => {
    const expressionNodes = [{
        type: 'math-expression',
        expression: expression,
        children: [{ text: '' }]
        // }, {
        //     type: 'paragraph',
        //     children: [{ text: '' }]
    }];
    Transforms.insertNodes(editor, expressionNodes)
}

/**
 * Update an existing Expression from Slate Editor
 */
export const updateExpression = (editor, element, expression) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
        editor, {
        expression: expression
    }, {
        at: path
    });
}

/**
 * Dialog Popup for Inputing Math Expression
 */
export const MathExpressionEditorDialog = (props) => {
    const { value, open, handleConfirm, handleClose } = props;
    const [expression, setExpression] = useState(value);
    return (
        <div>
            <Dialog open={open}
                fullWidth
                minWidth="lg">
                <DialogTitle id="alert-dialog-title">Input your Math Expression</DialogTitle>
                <DialogContent>
                    {/* Equation Editor */}
                    <Box border={1} padding={1} borderRadius={5} borderColor='primary'>
                        <EquationEditor
                            value={expression}
                            onChange={setExpression}
                            autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
                            autoOperatorNames="sin cos tan" />
                    </Box>
                    {/* End: Equation Editor */}
                </DialogContent>
                {/* Action Buttons */}
                <DialogActions>
                    {/* Confirm Button */}
                    <Button
                        color="primary"
                        onClick={() => {
                            handleConfirm(expression);
                            handleClose();
                        }}>Confirm</Button>
                    {/* End: Confirm Button */}
                    {/* Cancel Button */}
                    <Button
                        color="grey"
                        onClick={handleClose}>Cancel</Button>
                    {/* End: Cancel Button */}
                </DialogActions>
                {/* End: Action Buttons */}
            </Dialog>
        </div >
    );
}