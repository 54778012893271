import React, { useState, useEffect } from 'react';

/**
 * Firebase
 */
import firebase, { firestore, getFirestoreTimestamp } from '../../common/serviecs/firebase';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Container, Chip, Icon, Avatar, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton, Autocomplete } from '@material-ui/lab';

/**
 * Application
 */
import { USER_ROLES, USER_ROLE_OWNER, USER_ROLE_PARENT } from '../Constants';
import { hashCode } from '../../common/functions/StringUtils';
import { PasswordDialog } from '../../common/components/controls/UserSelection';
import { ImageEditorDialog, resizeImage } from '../../common/components/controls/ImageEditorDialog';

export function UserTable(props) {
    const classes = makeStyles(theme => ({
        root: {
            width: "100%",
            color: "rgb(44,62,80)",
            fontFamily: "Calibri",
            textAlign: "center",
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 100,
                height: 4,
                content: `''`,
                transform: "matrix(1,0,0,1,-50,0)"
            }
        },
        sectionImage: {
            height: theme.spacing(10),
            width: theme.spacing(10),
            borderRadius: "50%",
            border: "3px solid #ebebeb",
            boxShadow: "0 3px 2px rgba(0, 0, 0, 0.1)",
        },
        addUserIcon: {
            fontSize: 80
        }
    }))();
    const { t, account, user } = props;
    const [users, setUsers] = useState();
    // A boolean flag to control whether refresh is required
    const [requireUserRefresh, setRequireUserRefresh] = useState(true);
    const [selectedUser, setSelectedUser] = useState();
    const isParent = [USER_ROLE_OWNER, USER_ROLE_PARENT].includes(user.role);
    useEffect(() => {
        if (Boolean(firebase.auth().currentUser) && requireUserRefresh) {
            var users = [];
            firestore.collection("users").where("ownerUserId", "==", account.ownerUserId).get().then(usersRef => {
                usersRef.forEach(userRef => {
                    users.push(Object.assign({ id: userRef.id }, userRef.data()));
                });
                setUsers(users);
                // Set refresh flag false to indicate refresh has already been completed
                setRequireUserRefresh(false);
            })
        }
    }, [requireUserRefresh]);
    const updateUser = (user) => {
        var updatedUser = Object.assign({
            ownerUserId: account.ownerUserId,
            fullName: `${user.firstName} ${user.lastName}`,
            createdAt: getFirestoreTimestamp(),
            updatedAt: getFirestoreTimestamp(),
        }, user);
        if (Boolean(updatedUser.id || updatedUser.email)) {
            // Existing user or linked account
            firestore.collection("users").doc(updatedUser.id || updatedUser.email).set(updatedUser).then(usersRef => { });
        } else {
            // New user without email
            firestore.collection("users").add(updatedUser).then(usersRef => { });
        }
        setSelectedUser(null);
        // Reload all account users
        setRequireUserRefresh(true);
    }

    const deleteUser = (user) => {
        firestore.collection("users").doc(user.id).delete().then(userRef => {
            setSelectedUser(null);
            // Reload all account users
            setRequireUserRefresh(true);
        });
    }
    return (
        Boolean(users) &&
        <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={2}>
                {!Boolean(selectedUser) &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Box mb={3}>
                                <Typography variant="h6" className={classes.titleLine}>{t("Users")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                                {/* Avatar for each User */}
                                {users.filter(u => isParent || user.id === u.id).map((user, uIndex) =>
                                    <Box key={`section-${uIndex}`}
                                        display="flex" alignItems="center" flexDirection="column"
                                        mx={2} onClick={() => setSelectedUser(user)}>
                                        <Box>
                                            <Avatar className={classes.sectionImage} src={user.imageUrl} />
                                        </Box>
                                        <Box>
                                            <Typography variant="h6">{user.fullName}</Typography>
                                        </Box>
                                        <Box m={1}>
                                            <Chip label={USER_ROLES[user.role].label}
                                                style={{ fontWeight: 800 }}
                                                variant="outlined"
                                                color="primary" size="small"
                                                iconas={<Icon fontSize="small">{USER_ROLES[user.role].iconKey}</Icon>} />
                                        </Box>
                                    </Box>)}
                                {/* End: Avatar for each User */}
                                {/* New User Avatar */}
                                {isParent &&
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="start" mx={2}>
                                        <Box>
                                            <Avatar className={classes.sectionImage} onClick={() => setSelectedUser({})}>
                                                <Icon className={classes.addUserIcon}>add</Icon>
                                            </Avatar>
                                        </Box>
                                    </Box>}
                                {/* End: New User Avatar */}
                            </Box>
                        </Grid>
                    </React.Fragment>}
                {/* User Detail Form */}
                <Grid item xs={12}>
                    {Boolean(selectedUser) &&
                        <UserForm key={Boolean(selectedUser) ? selectedUser.id : "new-user"}
                            t={t} account={account} currentUser={user} user={selectedUser}
                            updateUser={updateUser} deleteUser={deleteUser} cancelUpdate={() => setSelectedUser(null)} />}
                </Grid>
                {/* End: User Detail Form */}
            </Grid >
            <Box mb={10} />
        </Container >
    );
}

function UserForm(props) {
    const classes = makeStyles(theme => ({
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 100,
                height: 4,
                content: `''`,
                transform: "matrix(1,0,0,1,-50,0)"
            }
        },
        avatarImageContainer: {
            position: "relative",
        },
        avatarImage: {
            position: "relative",
            height: theme.spacing(10),
            width: theme.spacing(10),
            borderRadius: "50%",
            border: "3px solid #ebebeb",
            boxShadow: "0 3px 2px rgba(0, 0, 0, 0.1)",
        },
        avatarUploadButton: {
            position: "absolute",
            bottom: theme.spacing(-1),
            right: theme.spacing(-1),
        },
    }))();
    const { t, currentUser, updateUser, deleteUser, cancelUpdate } = props;
    const [user, setUser] = useState(Object.assign({}, props.user));
    const [editMode, setEditMode] = useState(!Boolean(user.id) ? "new-user" : null);
    const [userType, setUserType] = useState("dependent-user");
    const [imageDataUrl, setImageDataUrl] = useState();
    const updateUserAttribute = (key, value) => {
        var updatedNewUser = Object.assign({}, user);
        updatedNewUser[key] = value;
        setUser(updatedNewUser);
    }

    const loadImage = (fileBlob) => {
        // let fileReader = new FileReader();
        // fileReader.addEventListener("load", () => {
        //     setImageDataUrl(fileReader.result);
        // });
        // fileReader.readAsDataURL(fileBlob);
        resizeImage(user, fileBlob, 256, 256, 0.8, "image/jpg", setImageDataUrl);
    }

    return (
        <Grid container spacing={3}>
            {/* Password Dialog */}
            {(editMode === "validate-password") &&
                <PasswordDialog open={true}
                    successCallback={() => setEditMode("change-password")}
                    closeCallback={() => { }}
                    user={user} />}
            {/* End: Password Dialog */}
            {/* Image Editor Dialog */}
            {Boolean(imageDataUrl) &&
                <React.Fragment>
                    Image editor on
                    <ImageEditorDialog
                        user={user}
                        imageDataUrl={imageDataUrl}
                        cropperOptions={{ aspectRatio: 1 }}
                        handleClose={() => setImageDataUrl(null)}
                        handleConfirm={croppedImageDataUrl => { setUser(Object.assign({}, user, { imageUrl: croppedImageDataUrl })); setImageDataUrl(null); }} />
                </React.Fragment>}
            {/* End: Image Editor Dialog */}
            {/* Title */}
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.titleLine}>{t(Boolean(user.id) ? "Update User Details" : "New Users")}</Typography>
            </Grid>
            {/* End: Title */}
            {/* Image */}
            <Grid itme xs={12}>
                <Box display="flex" justifyContent="center">
                    <div className={classes.avatarImageContainer}>
                        <Avatar className={classes.avatarImage} alt={user.fullName} src={user.imageUrl} />
                        {/* Select Image Button */}
                        {["new-user", "edit"].includes(editMode) &&
                            <React.Fragment>
                                <input id="upload-image-button" type="file" accept="image/*" style={{ display: "none" }} onChange={e => { loadImage(e.target.files[0]); e.target.value = null; }} />
                                <label htmlFor="upload-image-button">
                                    <IconButton className={classes.avatarUploadButton} size="small" component="span">
                                        <Icon>camera_alt</Icon>
                                    </IconButton>
                                </label>
                            </React.Fragment>}
                        {/* End: Select Image Button */}
                    </div>
                </Box>
            </Grid>
            {/* End: Image */}
            <Grid item xs={12}>
                <Box textAlign="left" fontWeight={800} mt={2}>
                    <Typography variant="subtitle2">ID: {user.id}</Typography>
                </Box>
            </Grid>
            {/* Buttons */}
            <Grid xs={12} style={{ textAlign: "right" }}>
                {Boolean(editMode) ?
                    <React.Fragment>
                        <Button color="primary" onClick={() => updateUser(user)}>{t(Boolean(user.id) ? "Update" : "Create")}</Button>
                        {Boolean(user.id) &&
                            <Button color="primary" onClick={() => deleteUser(user)}>{t("Delete")}</Button>}
                    </React.Fragment> :
                    <React.Fragment>
                        <Button color="primary" onClick={() => setEditMode("edit")}>{t("Edit")}</Button>
                        <Button color="primary" onClick={() => (currentUser.role === USER_ROLE_OWNER || !Boolean(user.passwordHashCode)) ? setEditMode("change-password") : setEditMode("validate-password")}>{t("Change Password")}</Button>
                    </React.Fragment>}
                <Button onClick={cancelUpdate}>Cancel</Button>
            </Grid>
            {/* End: Button */}
            {editMode === "new-user" &&
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <ToggleButtonGroup size="small" exclusive value={userType} onChange={(e, newValue) => setUserType(newValue)}>
                            <ToggleButton value="linked-google-user">
                                <Typography variant="subtitle2">Linked Google User</Typography>
                            </ToggleButton>
                            <ToggleButton value="dependent-user">
                                <Typography variant="subtitle2">Dependent User</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>}
            {["new-user", "edit"].includes(editMode) && user.role !== USER_ROLE_OWNER &&
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <ToggleButtonGroup size="small" exclusive
                            value={user["role"]}
                            onChange={(e, newValue) => updateUserAttribute("role", newValue)}>
                            {Object.keys(USER_ROLES).map(urKey =>
                                <ToggleButton value={urKey} disabled={Boolean(urKey === USER_ROLE_OWNER)}>
                                    <Typography variant="subtitle2">{USER_ROLES[urKey].label}</Typography>
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </Grid>}
            <Grid item xs={12} sm={6}>
                <TextField label="First Name" variant="standard"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.firstName}
                    onChange={e => updateUserAttribute("firstName", e.target.value)}
                    InputLabelProps={{ shrink: true }} fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Last Name" variant="standard"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.lastName}
                    onChange={e => updateUserAttribute("lastName", e.target.value)}
                    InputLabelProps={{ shrink: true }} fullWidth size="small" />
            </Grid>
            {(userType === "linked-google-user" || Boolean(user.email)) &&
                <Grid item xs={12}>
                    <TextField label="Email" variant="standard"
                        disabled={userType !== "linked-google-user"}
                        helperText={Boolean(props.user.email) ? "You cannot change your login email address" : "Input a valid gmail address if you want to link up"}
                        value={user.email}
                        onChange={e => updateUserAttribute("email", e.target.value)}
                        InputLabelProps={{ shrink: true }} fullWidth size="small" />
                </Grid>}
            {/* Password */}
            {["new-user", "change-password"].includes(editMode) &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField label="Password" variant="standard"
                            type="password"
                            onChange={e => updateUserAttribute("passwordHashCode", hashCode(e.target.value))}
                            InputLabelProps={{ shrink: true }} fullWidth size="small" />
                    </Grid>
                </React.Fragment>}
            {/* End: Password */}
            <Grid item xs={12} sm={12} md={6}>
                <TextField label="School" variant="standard"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.school}
                    onChange={e => updateUserAttribute("school", e.target.value)}
                    InputLabelProps={{ shrink: true }} fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label="Grade" variant="standard"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.grade}
                    onChange={e => updateUserAttribute("grade", e.target.value)}
                    InputLabelProps={{ shrink: true }} fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
                <TextField
                    label="Class" variant="standard"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.class}
                    onChange={e => updateUserAttribute("class", e.target.value)}
                    InputLabelProps={{ shrink: true }} fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={2} md={1}>
                <TextField
                    label="No." type="number"
                    disabled={!["new-user", "edit"].includes(editMode)}
                    value={user.classNumber}
                    inputProps={{ style: { textAlign: "center" } }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" > (</InputAdornment>,
                        endAdornment: <InputAdornment position="end">)</InputAdornment>
                    }}
                    InputLabelProps={{ shrink: true }} fullWidth size="small"
                    onChange={e => updateUserAttribute("classNumber", e.target.value)} />
            </Grid>
            {/* Taggings */}
            <Grid item xs={12}>
                <Autocomplete
                    disabled={!["new-user", "edit"].includes(editMode)}
                    options={[]}
                    value={user.taggings}
                    getOptionLabel={(option) => option}
                    fullWidth
                    multiple={true}
                    freeSolo={true}
                    onChange={(e, value) => updateUserAttribute("taggings", value)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Taggings"
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            {/* End: Taggings */}
        </Grid >
    );
}