import React, { useState } from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Icon, Link, Box } from '@material-ui/core';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Typography } from '@material-ui/core';

/**
 * Firebase
 */
import firebase, { logout } from '../../serviecs/firebase';

/**
 * Application
 */
import { LanguageMenu } from './LanguageMenu';
import { LoginMenu } from './LoginMenu';
import { UserSelectionMenu } from './UserSelection';
import { NotificationMenu } from './NotificationMenu';
import { MENU_ITEMS, SUPPORTED_LANGUAGES, USER_ROLE_PILOT } from '../../../app/Constants';
import { formatDate } from '../../functions/DateTimeUtils';

/**
 * Main App Bar
 */
export function MainAppBar(props) {

    const { t, i18n, account, user, goto, selectUser } = props;
    const classes = makeStyles(theme => ({
        root: {
            backgroundColor: `${theme.palette.primary.main}88`,
            // Background color of your app bar
            //backgroundColor: "rgba(252,106,3,1)",
            //color: "white"
            // Make it above <LoadingMask />
            zIndex: theme.zIndex.drawer + 2
        },
        toolbarLogo: {
            // flexGrow: 1,
            height: 50,
            cursor: "pointer",
            [theme.breakpoints.down('sm')]: {
                height: 38
            }
        },
        toolbarMenuIcon: {
            fontWeight: 800,
            color: theme.palette.primary.contrastText
        },
        toolbarItem: {
            fontWeight: 800
        },
        toolbarTitle: {
            flexGrow: 1,
        },
        toolbarBackground: {
            height: 64,
            backgroundColor: theme.palette.primary.main,
            marginBottom: theme.spacing(1)
        },
        drawerHeader: {
            backgroundColor: "rgba(252,106,3,1)",
            color: "white",
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        drawerHeaderUserName: {
            marginTop: 12
        },
        drawerLoginButton: {
            color: "white",
            width: "100%"
        },
        drawerList: {
            minWidth: 200
        },
        menuItem: {
            paddingLeft: theme.spacing(1)
        },
        menuItemIcon: {
            minWidth: theme.spacing(5)
        },
        expiryDate: {
            fontWeight: 800
        }
    }))();

    const [isDisplayDrawer, setDisplayDrawer] = useState(false);
    const requestNotificationPermission = (e) => {
        Notification.requestPermission();
    }

    return (
        <React.Fragment>
            <AppBar className={classes.root}>
                <Toolbar className={classes.toolbarItem}>
                    {/* Logo of your application - height = 50px */}
                    <Box className={classes.toolbarLogo} m={1}>
                        <img className={classes.toolbarLogo} alt="Logo" src="/images/logo.png" onClick={() => goto("/")} />
                    </Box>
                    {/* End: Logo of your application - height = 50px */}
                    <Box align="center" className={classes.toolbarTitle}>
                        <Box display={{ xs: "none", sm: "block" }}>
                            <Typography variant="h6">{t(process.env.REACT_APP_APPLICATION_NAME)}</Typography>
                        </Box>
                    </Box>
                    <Box mr={1}>
                        <LanguageMenu t={t} i18n={i18n} supportedLanguages={SUPPORTED_LANGUAGES} />
                    </Box>
                    {/* Notifications */}
                    {Boolean(user) ?
                        <Box>
                            <NotificationMenu t={t} user={user} goto={goto} />
                        </Box> : null}
                    {/* End: Notifications */}
                    {/* User Selection / Login Menu */}
                    <Box>
                        {Boolean(user) ?
                            <UserSelectionMenu account={account} user={user} onSelectUser={selectUser} showDependentUsersOnly={true} /> :
                            (!Boolean(account) && <LoginMenu t={t} />)}
                    </Box>
                    {/* End: User Selection / Login Menu */}
                    <IconButton variant="contained" className={classes.toolbarMenuIcon} onClick={() => setDisplayDrawer(true)}>
                        <Icon>menu</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* App Bar underlying Image */}
            <div className={classes.toolbarBackground} />
            {/* End: App Bar underlying Image */}
            {/* Drawer */}
            <Drawer variant="temporary" anchor="right" open={isDisplayDrawer} onClose={() => setDisplayDrawer(false)}>
                {/* Header - Avatar & Name */}
                <Box my={0}
                    onClick={() => setDisplayDrawer(false)}
                    onKeyDown={() => setDisplayDrawer(false)}>
                    <Box className={classes.drawerHeader} px={1}>
                        {Boolean(user) ?
                            <React.Fragment>
                                <Avatar alt={user.firstName} src={user.imageUrl} />
                                <Typography className={classes.drawerHeaderUserName} variant="h6">{user.fullName}</Typography>
                            </React.Fragment> : <Typography variant="h6">{t(process.env.REACT_APP_APPLICATION_NAME)}</Typography>}
                    </Box>
                </Box>
                {/* End: Header - Avatar & Name */}
                <Box mx={2} onClick={() => setDisplayDrawer(false)}>
                    {/* Display Pilot Expiry Date if Applicable */}
                    {Boolean(user) && Boolean(user?.role === USER_ROLE_PILOT) &&
                        <Box textAlign="center" my={1}>
                            <Typography variant='caption' color='error'>
                                You trial will expire on&nbsp;
                            </Typography>
                            <Typography variant='caption' color='error' className={classes.expiryDate}>
                                {formatDate(user?.pilotExpiresAt?.toDate())}
                            </Typography>
                        </Box>}
                    {/* End: Display Pilot Expiry Date if Applicable */}
                    <List className={classes.drawerList}>
                        {MENU_ITEMS.filter(mi => (!Boolean(mi.requireAuthentication) || (Boolean(user) && (!Boolean(mi.requiredRoles) || mi.requiredRoles.includes(user.role)))) &&
                            (!Boolean(mi.requireOwner) || user.role === "owner") &&
                            (!Boolean(mi.developmentOnly) || process.env.NODE_ENV === "development")).map(mi =>
                                mi.label ?
                                    <Box key={mi.key} ml={(mi.level - 1) * 2}>
                                        <ListItem className={classes.menuItem} button={Boolean(mi.path)} onClick={() => goto(mi.path)}>
                                            <ListItemIcon className={classes.menuItemIcon}><Icon>{mi.iconKey}</Icon></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="subtitle2">{t(mi.label)}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Box>
                                    : <Divider key={mi.key} />
                            )}
                        {/* Logout */}
                        {Boolean(firebase.auth().currentUser) ?
                            <React.Fragment>
                                {/* Disable Notificaion as it is not supported in mobile iOS */}
                                {false && Notification.permission !== "granted" &&
                                    <React.Fragment>
                                        <Divider key="divider-notification" />
                                        <ListItem className={classes.menuItem} button key="subscrible-for-notification" onClick={requestNotificationPermission}>
                                            <ListItemIcon className={classes.menuItemIcon}><Icon>add_alert</Icon></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="subtitle2">{t("Subscribe for Notification")}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </React.Fragment>}
                                {/* End: Disable Notificaion as it is not supported in mobile iOS */}
                                <Divider key="divider-logout" />
                                <ListItem className={classes.menuItem} button key="logout" onClick={() => { logout(); goto("/"); }}>
                                    <ListItemIcon className={classes.menuItemIcon}><Icon>logout</Icon></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle2">{t("Logout")}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </React.Fragment> :
                            null}
                        {/* End: Logout */}
                        {/* Build Timestamp */}
                        <Divider key="divider-build-timestamp" />
                        <ListItem>
                            <ListItemText style={{ textAlign: "right" }}>
                                <Typography variant="caption" component="div">Build: {process.env.REACT_APP_BUILD_TIMESTAMP}</Typography>
                                <Link component="button" variant="overlined" color="textPrimary" style={{ fontSize: "0.6rem" }} onClick={() => window.location.reload(true)}>Check new version</Link>
                            </ListItemText>
                        </ListItem>
                        {/* End: Build Timestamp */}
                    </List>
                </Box>
            </Drawer>
            {/* End: Drawer */}
        </React.Fragment >
    );
}