import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box, Typography, IconButton, Icon } from '@material-ui/core';

export const TermsAndConditions = React.memo(function TermsAndConditions(props) {
    const { t } = props;
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "justify"
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(1),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 100,
                height: 4,
                content: `''`,
                transform: `matrix(1,0,0,1,-50,0)`
            }
        },
    }))(); return (
        <Container maxWidth="lg">
            <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12}>
                    <Box textAlign="right" displayPrint="none">
                        <IconButton onClick={() => { window.print(); return false; }}>
                            <Icon>print</Icon>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <Typography variant="h4" className={classes.titleLine}>{t("Terms and Conditions")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box my={2}>
                        <Typography variant="body1">
                            Please read these terms and conditions ("terms", "terms and conditions") carefully before using the "{t(process.env.REACT_APP_APPLICATION_NAME)}" website (the "service") operated by "{t(process.env.REACT_APP_COMPANY_NAME)}" ("us", 'we", "our").
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Conditions of Use
                        </Typography>
                        <Typography variant="body1">
                            We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Privacy Policy
                        </Typography>
                        <Typography variant="body1">
                            Before you continue using our website we advise you to read our <a href="/privacy-policies">privacy policy</a> regarding our user data collection. It will help you better understand our practices.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Copyright
                        </Typography>
                        <Typography variant="body1">
                            Content published on this website (digital downloads, images, texts, graphics, logos) is the property of "{t(process.env.REACT_APP_APPLICATION_NAME)}" and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of "{t(process.env.REACT_APP_COMPANY_NAME)}", with copyright authorship for this compilation by "{t(process.env.REACT_APP_COMPANY_NAME)}".
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Communications
                        </Typography>
                        <Typography variant="body1">
                            The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Applicable Law
                        </Typography>
                        <Typography variant="body1">
                            By visiting this website, you agree that the laws of the Hong Kong jurisdiction, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between "{t(process.env.REACT_APP_COMPANY_NAME)}" and you, or its business partners and associates.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Disputes
                        </Typography>
                        <Typography variant="body1">
                            Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by the court of Hong Kong and you consent to exclusive jurisdiction and venue of such courts.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            Comments, Reviews, and Emails
                        </Typography>
                        <Typography variant="body1">
                            Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content has to be free of software viruses, political campaigns, and commercial solicitation.
                        </Typography>
                        <Typography variant="body1">
                            We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant "{t(process.env.REACT_APP_APPLICATION_NAME)}" non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            License and Site Access
                        </Typography>
                        <Typography variant="body1">
                            We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.
                        </Typography>
                    </Box>

                    <Box my={2}>
                        <Typography variant="h6">
                            User Account
                        </Typography>
                        <Typography variant="body1">
                            If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.
                        </Typography>
                        <Typography variant="body1">
                            We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </Container >
    )
});