import React, { useState } from 'react';

/**
 * Firebase
 */
import { firestore, getFirestoreTimestamp } from '../../../common/serviecs/firebase';

/**
 * Material UI
 */
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box, TextField, InputAdornment, Button, Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

/**
 * Application
 */
import { notifyUser } from '../../../common/components/controls/NotificationMenu';
import { UserSelectionMenu } from '../../../common/components/controls/UserSelection';
import { getDate } from '../../../common/functions/DateTimeUtils';
import { SECTION_EDITORS } from './PaperEditor';
import { PaperTitleAttributes } from './PaperAttributes';

export function AssignmentDialog(props) {
    const classes = makeStyles(theme => ({
        dialogTitle: {
            backgroundColor: `${theme.palette.primary.main}`,
            color: theme.palette.primary.contrastText
        },
        sectionTitle: {
            fontWeight: 800,
            fontSize: "1rem"
        }
    }))();
    const { account, user, handleClose } = props;
    const [dateDue, setDateDue] = useState(getDate());
    const [assignedUser, setAssignedUser] = useState(null);
    const [paper, setPaper] = useState(props?.paper);
    const [sections, setSections] = useState(paper?.sections?.map(s => ({})));

    const assignUser = () => {
        firestore.collection(`/test-papers/${paper.id}/paper-attempts`).add({
            paperId: paper.id,
            subject: paper.subject || null,
            title: paper.title || null,
            subtitle: paper.subtitle || null,
            school: paper.school || null,
            grade: paper.grade || null,
            fullScore: paper.fullScore,
            timeLimitInMinutes: paper.timeLimitInMinutes || null,
            completedAt: null,
            assignedAt: getFirestoreTimestamp(),
            sections: sections,
            assignedBy: {
                id: user.id,
                fullName: user.fullName,
            },
            attemptedBy: {
                ownerUserId: assignedUser.ownerUserId || assignedUser.id,
                id: assignedUser.id,
                fullName: assignedUser.fullName,
                dateDue: dateDue,
            }
        }).then(async (docRef) => {
            await notifyUser(assignedUser, {
                type: "assignment",
                message: `${user.fullName} has assigned you a test: ${paper.grade} ${paper.subject} - ${[paper.title, paper.subtitle].filter(t => Boolean(t)).join(" - ")}`,
                path: `/papers/${paper.id}/paper-attempts/${docRef.id}`,
                createdAt: getFirestoreTimestamp()
            });
            handleClose();
        });
    }

    const updateSectionConfiguration = (sectionIndex, configKey, configValue) => {
        let _sections = [...sections];
        _sections[sectionIndex].configurations = Object.assign({}, _sections[sectionIndex]?.configurations, { [configKey]: configValue });
        setSections(() => _sections);
    }

    return (
        <Dialog open={true}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="h5">
                    Assignment: {paper.grade} {paper.subject}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                label="Assign To"
                                placeholder="Select assignee..."
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={Boolean(assignedUser) ? assignedUser.fullName : null}
                                InputProps={{
                                    styles: {
                                        paddingRight: 4
                                    },
                                    endAdornment:
                                        <InputAdornment>
                                            <UserSelectionMenu
                                                account={account}
                                                user={user}
                                                onSelectUser={setAssignedUser}
                                                buttonIcon="more_vert"
                                                requirePassword={false}
                                                showDependentUsersOnly={false} />
                                        </InputAdornment>
                                }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                label="Date Due"
                                disableToolbar={true}
                                autoOk={true}
                                variant="dialog"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                value={dateDue}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setDateDue(date)} />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {/* Input for Overriding Title and Subtitle */}
                    <PaperTitleAttributes
                        paper={paper}
                        updatePaperAttributes={(key, value) => setPaper(() => Object.assign({}, paper, { [key]: value }))} />
                    {/* End: Input for Overriding Title and Subtitle */}

                    {paper.sections.map((section, sIndex) => {
                        let configuredSection = Object.assign({}, section);
                        configuredSection.configurations = Object.assign({}, section?.configurations, sections[sIndex]?.configurations);
                        let sectionSelectionElement =
                            <FormControlLabel
                                className={classes.switchLabel}
                                control={
                                    <Checkbox
                                        checked={!Boolean(configuredSection.configurations?.excluded)}
                                        onChange={() => updateSectionConfiguration(sIndex, "excluded", !configuredSection.configurations?.excluded)}
                                        color="primary" />}
                                label={<Typography className={classes.sectionTitle}>{sIndex + 1}. {section.title}</Typography>} />;
                        let optionsSelectionElement = Boolean(SECTION_EDITORS?.[section.type]?.optionsSelection) ?
                            React.createElement(SECTION_EDITORS?.[section.type]?.optionsSelection, {
                                section: configuredSection,
                                updateSectionConfiguration: (key, value) => updateSectionConfiguration(sIndex, key, value)
                            }) : null
                        return (
                            <Grid item xs={12}>
                                {sectionSelectionElement}
                                <Box ml={3}>
                                    {optionsSelectionElement}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                    disabled={!Boolean(assignedUser)}
                    onClick={assignUser}>Assign</Button>
                <Button color="primary" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}