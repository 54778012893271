import React from 'react';

/**
 * Material UI
 */
import { Box, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

export function AdminPanel(props) {
    return (
        <Box mt={10}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Variable</TableCell>
                        <TableCell align="center">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(process.env).map(k =>
                        <TableRow>
                            <TableCell align="center">{k}</TableCell>
                            <TableCell align="center">{process.env[k]}</TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </Box>
    );
}

