import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Material UI
 */
import { Box, Typography } from '@material-ui/core';

/**
 * Important props
 * 1. timeRemainingInSeconds
 * 2. className
 * 3. textClassName
 */
export const Timer = (props) => {
    const { textClassName, timeLimitInSeconds } = props;
    // Time Remaining in Seconds
    const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState(timeLimitInSeconds);

    useEffect(() => {
        setTimeout(() => setTimeRemainingInSeconds(timeRemainingInSeconds - 1), 1000);
    }, [timeRemainingInSeconds])

    return (
        <Box {...props}>
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Typography variant="h6" className={textClassName}>
                    <TimeDisplay timeInSeconds={timeRemainingInSeconds} />
                </Typography>
            </Box>
        </Box>
    );
}

Timer.propTypes = {
    timeLimit: PropTypes.number.isRequired,
};

export const TimeDisplay = ({ timeInSeconds }) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return (
        <span>
            {hours > 0 ? `${hours}:` : null}
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
        </span>
    );
}
