/**
 * Material UI
 */
import { orange, green, red } from '@material-ui/core/colors';

/**
 * Application
 */
import { AdminPanel } from '../common/components/AdminPanel';
import { UserDetails } from '../common/components/UserDetails';
import { PrintableSection } from '../common/components/Sections';
import { PaperPanel } from './test-paper/papers/PaperPanel';
import { UserPanel } from './users/UserPanel';
import { TermsAndConditions } from './static/TermsAndConditions';
import { Dashboard } from './dashboard/Dashboard';
import { Checklist } from './checklist/Checklist';
import { CollectionPanel } from './test-paper/collections/CollectionPanel';
import { SpeakTestPanel } from '../common/SpeakTestPanel';
import { DataPatchingPanel } from '../common/components/DataPatchingPanel';

/**
 * Supported Language
 */
export const LANGUAGE_CODE_CHINESE = "zh";
export const LANGUAGE_CODE_ENGLISH = "en";
export const SUPPORTED_LANGUAGES = {
    [LANGUAGE_CODE_ENGLISH]: "English",
    [LANGUAGE_CODE_CHINESE]: "Chinese"
}
export const LANGUAGE_CODE_CHINESE_FONT_STEP = 3;
export const LANGUAGES = Object.keys(SUPPORTED_LANGUAGES).map(k => ({ label: SUPPORTED_LANGUAGES[k], code: k }));

/**
 * User Roles
 */
export const USER_ROLE_ADMIN = "admin";
export const USER_ROLE_OWNER = "owner";
export const USER_ROLE_PARENT = "parent";
export const USER_ROLE_CHILD = "child";
export const USER_ROLE_PILOT = "pilot";
export const USER_ROLE_PILOT_EXPIRY_THRESHOLD_IN_DAYS = 30;

export const USER_ROLES = {
    [USER_ROLE_ADMIN]: { label: "Administrator", iconKey: "supervisor_account", entitledRoles: [USER_ROLE_CHILD] },
    [USER_ROLE_PILOT]: { label: "Pilot User", iconKey: "person_outline", entitledRoles: [USER_ROLE_CHILD] },
    [USER_ROLE_OWNER]: { label: "Owner", iconKey: "account_circle", entitledRoles: [USER_ROLE_CHILD] },
    [USER_ROLE_PARENT]: { label: "Parent", iconKey: "emoji_people", entitledRoles: [USER_ROLE_CHILD] },
    [USER_ROLE_CHILD]: { label: "Child", iconKey: "face", entitledRoles: [] }
}

/**
 * Menu Items
 */
export const MENU_ITEMS = [
    { key: "dashboard", label: "Your Dashboard", iconKey: "dashboard", level: 1, path: "/dashboard", component: Dashboard, requireAuthentication: true },
    { key: "assignments", label: "Your Assignments", iconKey: "assignment", level: 1, path: "/assignments", component: PaperPanel, props: {}, requireAuthentication: true },
    { key: "paper-attempts", label: "Your Results", iconKey: "scoreboard", level: 1, path: "/paper-attempts", component: PaperPanel, props: {}, requireAuthentication: true },
    { key: "checklists", label: "Checklist of the day", iconKey: "playlist_add_check", level: 1, path: "/checklists", component: Checklist, props: {}, requireAuthentication: true },
    { key: "divider-papers", label: null, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER, USER_ROLE_PARENT] },
    { key: "papers", label: "Search For Tests", iconKey: "search", level: 1, path: "/papers", component: PaperPanel, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER, USER_ROLE_PARENT, USER_ROLE_PILOT] },
    { key: "paper-new", label: "Create Your Test", iconKey: "create", level: 1, path: "/papers/new", component: PaperPanel, props: {}, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER, USER_ROLE_PARENT, USER_ROLE_PILOT] },
    { key: "collections", label: "Your Collections", iconKey: "apps", level: 1, path: "/collections", component: CollectionPanel, props: {}, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER, USER_ROLE_PARENT, USER_ROLE_PILOT] },
    { key: "divider-item-2", label: null, developmentOnly: true },
    { key: "flash-cards", label: "Flash Card", iconKey: "flash_on", level: 1, path: "/flash-cards", component: PrintableSection, props: { title: "Flash Card", numberOfColumns: 1, sections: [{ title: "Flash Card", text: "Coming soon..." }] }, developmentOnly: true },
    { key: "divider-account", label: null, requireAuthentication: true, },
    { key: "account", label: "Manage Users", iconKey: "manage_accounts", level: 1, path: "/users", component: UserPanel, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER, USER_ROLE_PARENT] },
    { key: "user-details", label: "Account Details", iconKey: "account_box", level: 1, path: "/user-details", component: UserDetails, requireAuthentication: true, developmentOnly: true },
    { key: "divider-admin", label: null },
    { key: "speak-test", label: "Speak Test", iconKey: "mic", level: 1, path: "/speak-test", component: SpeakTestPanel, requireAuthentication: true, developmentOnly: true },
    { key: "admin", label: "Admin", iconKey: "supervisor_account", level: 1, path: "/admin", component: AdminPanel, requireAuthentication: true, developmentOnly: true },
    { key: "data-patching", label: "Data Patching", iconKey: "healing", level: 1, path: "/data-patching", component: DataPatchingPanel, requireAuthentication: true, requiredRoles: [USER_ROLE_OWNER] },
    { key: "divider-terms-and-conditions", label: null, requireAuthentication: true, },
    { key: "terms-and-conditions", label: "Terms and Conditions", iconKey: "policy", level: 1, path: "/terms-and-conditions", component: TermsAndConditions },
];

/**
 * Theme
 */
export const CUSTOM_THEME = {
    palette: {
        primary: {
            light: orange.A500,
            main: orange.A700,
            dark: orange.A900,
            contrastText: "rgb(255, 255, 255)"
        },
        success: {
            light: green.A500,
            main: green.A700,
            dark: green.A900,
            contrastText: "rgb(255, 255, 255)"
        },
        error: {
            light: red.A500,
            main: red.A700,
            dark: red.A900,
            contrastText: "rgb(255, 255, 255)"
        },
        text: {
            primary: "rgba(0,0,0,0.87)"
        },
        status: {
            danger: orange[500],
        },
    },
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif", "Wang-Kai",].join(",")
    }
}

export function overrdieMuiDataTableTheme(theme) {
    return {
        overrides: {
            MuiTable: {
                root: {
                    tableLayout: "fixed"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // backgroundColor: "#FF0000",
                    textAlign: "center"
                },
                stackedCommon: {
                    [theme.breakpoints.down("sm")]: {
                        height: "auto",
                        whiteSpace: "pre-line"
                    }
                },
                cellStackedSmall: {
                    [theme.breakpoints.down("sm")]: {
                        display: "none",
                        width: 0,
                        backgroundColor: "#FF0000"
                    }
                },
                responsiveStackedSmall: {
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "0.75rem",
                        width: "100% !important",
                        paddingTop: theme.spacing(1),
                        paddingBottom: 0,
                        border: 0,
                    }
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: "center"
                }
            },
            MUIDataTableHeadCell: {
                toolButton: {
                    justifyContent: "center"
                },
                sortAction: {
                    justifyItems: "flex-end"
                }
            },
            MUIDataTableToolbar: {
                left: {
                    flex: "100 1 auto"
                },
                titleText: {
                    [theme.breakpoints.down("xl")]: {
                        textAlign: "center",
                        fontSize: "1.25rem !important",
                        fontWeight: 800,
                        display: "block",
                        position: "relative",
                        paddingBottom: theme.spacing(0.5),
                        '&::after': {
                            position: "absolute",
                            bottom: 0,
                            left: "50%",
                            borderBottom: "rgb(204,0,0) solid 4px",
                            width: 120,
                            height: 4,
                            content: `''`,
                            transform: "matrix(1,0,0,1,-60,0)"
                        }
                    }
                },
                actions: {
                    [theme.breakpoints.down("sm")]: {
                        textAlign: "right !important"
                    },
                    // [theme.breakpoints.down("xs")]: {
                    //     textAlign: "right !important"
                    // }
                }
            },
            MuiButton: {
                root: {
                    textTransform: "none"
                }
            },
            MuiCheckbox: {
                root: {
                    paddingRight: theme.spacing(0.25)
                }
            }
        }
    }
};

export const INTRO_BLOCKS = [{
    title: "Boost Productivity",
    text: "With Test Paper Online, you can be well prepared before you get back home from work and your kids get back home from school. Utilise your lunch break or even the gap time during your daily commute to create the test or exercise of the day.",
    imageUrl: "/images/intro/productivity-transparent.png"
}, {
    title: "Instantly Posted / Stay Connected",
    text: "When your kids get back home after school while you are still at work, you can still get instant updates on what your kids have achieved",
    imageUrl: "/images/intro/connected-transparent.png"
}, {
    title: "Track Progress",
    text: "With all your kids assignments and results stored on the cloud, you can track their progress and improvement hassle free",
    imageUrl: "/images/intro/tracking-transparent.png"
}, {
    title: "Share and Access",
    text: "Share your tests and exercises to help the other parents while at the same time getting access to tests and exercises shared by the others.",
    imageUrl: "/images/intro/share-transparent.png"
}];

export const GRADES = [
    { group: "Kindergarten", label: "K1" },
    { group: "Kindergarten", label: "K2" },
    { group: "Kindergarten", label: "K3" },
    { group: "Primary", label: "P1" },
    { group: "Primary", label: "P2" },
    { group: "Primary", label: "P3" },
    { group: "Primary", label: "P4" },
    { group: "Primary", label: "P5" },
    { group: "Primary", label: "P6" },
    { group: "Secondary", label: "S1" },
    { group: "Secondary", label: "S2" },
    { group: "Secondary", label: "S3" },
    { group: "Secondary", label: "S4" },
    { group: "Secondary", label: "S5" },
    { group: "Secondary", label: "S6" },
    { group: "International", label: "Grade 1" },
    { group: "International", label: "Grade 2" },
    { group: "International", label: "Grade 3" },
    { group: "International", label: "Grade 4" },
    { group: "International", label: "Grade 5" },
    { group: "International", label: "Grade 6" },
    { group: "International", label: "Grade 7" },
    { group: "International", label: "Grade 8" },
    { group: "International", label: "Grade 9" },
    { group: "International", label: "Grade 10" },
    { group: "International", label: "Grade 11" },
    { group: "International", label: "Grade 12" },
    { group: "International", label: "Grade 13" },
];