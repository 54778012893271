import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const SelectTextBox = (props) => {
    const { variant, size = "small", className, value = " ", selected, ...otherProps } = props;

    // inputProps={{ className: [questionClasses.expressionTextInput, classes.sentenceAnswerBox] }} 
    const classes = makeStyles(theme => ({
        root: {
            cursor: "pointer",
            borderRadius: Boolean(variant === "outlined") ? theme.spacing(0.5) : 0,
            borderTopWidth: Boolean(variant === "outlined") ? (Boolean(selected) ? 2 : 1) : 0,
            borderRightWidth: Boolean(variant === "outlined") ? (Boolean(selected) ? 2 : 1) : 0,
            borderLeftWidth: Boolean(variant === "outlined") ? (Boolean(selected) ? 2 : 1) : 0,
            borderBottomWidth: (Boolean(selected) ? 2 : 1),
            borderColor: Boolean(selected) ? theme.palette.primary.main : `${theme.palette.grey[400]}`,
            borderStyle: "solid",
            height: '3em'
        },
        selectedOption: {
            background: `${theme.palette.primary.main}99`,
            borderRadius: theme.spacing(1)
        },
        selectedExpression: {
            background: `${theme.palette.grey[300]}99`
        }
    }))();

    return (
        <Box display="inline-block">
            <Box display="flex" alignItems="center" justifyContent="center"
                className={[".MuiTextField-root", classes.root, Boolean(selected) ? classes.selectedExpression : null]}
                {...otherProps}>
                <Typography>{value}</Typography>
            </Box>
        </Box >
    );
}