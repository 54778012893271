import React from 'react';
import { Button, Icon, makeStyles } from "@material-ui/core"

export const ExpanableCheckbox = (props) => {
    const classes = makeStyles(theme => ({
        label: {
            fontWeight: 500,
            fontSize: '0.85rem',
            textTransform: "none"
        }
    }))();
    const { label, onChange, checked, color } = props;
    const iconKey = Boolean(checked) ? "expand_less" : "expand_more";
    return (
        <Button
            color={color}
            variant="text"
            className={classes.label}
            startIcon={<Icon>{iconKey}</Icon>}
            onClick={onChange}>{label}</Button>
    )
}