import React, { useState } from 'react';
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { TextFieldWithSpeechRecognition } from '.';
import { Icon, IconButton } from '@material-ui/core';

//                 setValue={value => updateQuestion(questionIndex, Object.assign({}, question, { richText: value }))} /> :
//                 onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { text: e.target.value }))} />
// autoFocus
export const TextFieldRichtextEditorSwitchable = (props) => {
    const { useRichText, value, languageCode, handleTextChange, handleRichTextChange, ...others } = props;
    return (
        Boolean(useRichText) ?
            <RichTextEditor variant="outlined" mode='compact'
                value={value}
                setValue={handleRichTextChange}
                {...others}
                additionalActions={
                    <React.Fragment>
                        <IconButton size="small"
                            onClick={() => {
                                // TODO: Pass plain text current value into it
                                handleTextChange(null);
                            }}>
                            <Icon fontSize="small">raw_on</Icon>
                        </IconButton>
                    </React.Fragment>
                } /> :
            <TextFieldWithSpeechRecognition
                fullWidth
                languageCode={languageCode}
                variant="outlined" multiline={true}
                value={value}
                onChange={e => handleTextChange(e.target.value)}
                additionalActions={
                    <IconButton size="small"
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => {
                            handleRichTextChange(value);
                        }}>
                        <Icon fontSizes="small">html</Icon>
                    </IconButton>}
                {...others} />
    );
}