/**
 * Regex for Email
 */
export const REGEX_EMAIL = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";

/**
 * Character Code for A
 */
export const CHAR_CODE_A = 65;

export const DUMMY_SECTIONS = [
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
    {
        title: "Click the print button to print this page",
        text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."
    },
]