import React from 'react';
import { Switch, Route } from "react-router-dom";

/**
 * Material UI
 */

/**
 * Application
 */
import { Collection } from './Collection';
import { CollectionCards } from './CollectionCards';

export function CollectionPanel(props) {
    return (
        <Switch>
            <Route exact path="/collections">
                {/* TODO: To be implemented */}
                <CollectionCards {...props} />
            </Route>
            <Route path={"/collections/:id"} >
                <Collection {...props} />
            </Route>
        </Switch >
    )
}