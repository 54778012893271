import React, { useEffect } from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography } from '@material-ui/core';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { shuffleArray } from '../../../common/functions/ArrayUtils';
import { SectionHeader } from './controls/SectionHeader';
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';

const EMPTY_SECTION_ATTEMPT = { score: 0, answers: [] };
export function MultipleChoiceSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
            // lineHeight: "2.5em",
        },
        questionGrid: {
            display: "flex",
            textAlign: "left",
            pageBreakInside: "avoid"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        correctChoiceText: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            borderRadius: 10,
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);

    const updateAnswer = (questionIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign([], originalSectionAttempt.answers)
        // As Radio Group doesn't support integer as value, so pass it as String and convert back
        originalAnswers[questionIndex] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = originalSectionAttempt.answers.filter(a => a === 0).length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    useEffect(() => {
        // Shuffle choices for the first time
        if (!Boolean(sectionAttempt.choiceOrders)) {
            let originalSectionAttempt = Object.assign({}, sectionAttempt);
            let choiceOrders = {};
            section.questions.forEach((q, qIndex) => choiceOrders[qIndex] = shuffleArray([...Array(q.choices.length).keys()]));
            originalSectionAttempt.choiceOrders = choiceOrders;
            updateSectionAttempt(sectionIndex, originalSectionAttempt);
        }
    });

    return (
        Boolean(sectionAttempt.choiceOrders) ?
            <Grid container spacing={3} className="print-table">
                <SectionHeader section={section}
                    sectionIndex={sectionIndex}
                    sectionAttempt={sectionAttempt}
                    showScore={showScore} showAnswer={showAnswer} />
                {section.questions.map((question, qIndex) =>
                    <Grid key={`question-${section.id}-${qIndex}`} item xs={12} className={[classes.questionGrid, "avoid-page-break"]}>
                        <Typography className={[classes.textBlock, classes.questionNumberMarker]}>{qIndex + 1}. </Typography>
                        <FormControl component="fieldset">
                            {Boolean(question.type === "richText") ?
                                <RichTextEditor readOnly={true}
                                    value={question.text} /> :
                                <Typography variant="body1">{question.text}</Typography>}

                            {/* RadioGroup doesn't support integer as value, use String and parseInt as a workaround */}
                            <RadioGroup value={`${sectionAttempt.answers[qIndex]}`}
                                onChange={(e) => updateAnswer(qIndex, parseInt(e.target.value))} >
                                {sectionAttempt.choiceOrders[qIndex].map((choice, cIndex) => {
                                    let className = null;
                                    if (choice === 0) {
                                        if (choice === sectionAttempt.answers[qIndex]) {
                                            // Correct answer
                                            className = classes.correctAnswerText;
                                        } else {
                                            // Correct choice but not selected answer
                                            className = classes.correctChoiceText;
                                        }
                                    } else if (choice === sectionAttempt.answers[qIndex]) {
                                        // Incorrect answer
                                        className = classes.incorrectAnswerText;
                                    }
                                    return <FormControlLabel key={`option-${choice}`} value={`${choice}`}
                                        disabled={showAnswer}
                                        control={<Radio color="primary" />}
                                        label={
                                            <Box display="flex" alignItems="flex-start" className={showAnswer ? className : null}>
                                                <Box pr={0.5}>
                                                    <Typography>{String.fromCharCode(cIndex + 65)}. </Typography>
                                                </Box>
                                                {question.choices[choice]?.type === "richText" ?
                                                    <RichTextEditor readOnly={true}
                                                        value={question.choices[choice]?.value} /> :
                                                    <Typography>{question.choices[choice]?.value}</Typography>}
                                            </Box>}
                                    />
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                )}
            </Grid > : null
    )
}
