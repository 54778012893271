import React from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionEditor } from './SectionEditor';
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { TextFieldRichtextEditorSwitchable } from '../../../common/components/controls';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "mulitple-choice",
    title: null,
    instruction: null
};
export function MultipleChoiceSectionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
        },
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        }
    }))();
    const { sectionIndex, updateSection, languageCode } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input", choices: [] }] }, props.section);
    const updateThisSection = (sectionIndex, section) => {
        var originalSection = Object.assign({}, section, { isEditing: section.questions.filter(q => q.stage !== undefined).length > 0 });
        updateSection(sectionIndex, originalSection);
    }
    const addQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex + 1, 0, Object.assign({}, { id: uuid(), stage: "input", autoFocus: true, choices: [] }));
        updateThisSection(sectionIndex, originalSection);
    }
    const removeQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, originalSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, originalSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var originalSection = Object.assign({}, section);
        originalSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, originalSection);
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <MultipleChoiceQuestionEditor
                    className={classes.questionEditor}
                    question={question}
                    questionIndex={questionIndex}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    updateQuestion={updateQuestion}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode} />)} />
    );
}

/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Type in your question and the choices, the first choice would be used as the correct answer. Choices would get shuffled during test." },
};
export function MultipleChoiceQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode } = props;
    const question = Object.assign(props.question);
    const stage = question.stage;
    const updateChoice = (cIndex, value) => {
        let originalQuestion = Object.assign({}, question);
        let choices = [...[], ...question.choices];
        choices[cIndex] = value;
        originalQuestion.choices = choices;
        updateQuestion(questionIndex, originalQuestion);
    }
    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        delete originalQuestion.autoFocus;
        originalQuestion.choices = originalQuestion.choices.filter(c => Boolean(c));
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            complete(false);
        }
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <React.Fragment>
                    <TextFieldRichtextEditorSwitchable
                        value={question.text}
                        languageCode={languageCode}
                        useRichText={Boolean(question.type === "richText")}
                        handleTextChange={value => updateQuestion(questionIndex, Object.assign({}, question, { type: "text", text: value }))}
                        handleRichTextChange={value => updateQuestion(questionIndex, Object.assign({}, question, { type: "richText", text: value }))}
                        placeholder="Input your question here"
                        autoFocus={question.autoFocus}
                    />
                    {[...Array(5).keys()].map(cIndex => {
                        let choice = (question.choices || [])[cIndex];
                        return (
                            <Grid key={`choice-${cIndex}`} item xs={12} className={classes.editorGrid}>
                                <Typography className={classes.questionNumber} style={{ paddingTop: 8 }}>{String.fromCharCode(cIndex + 65)}.</Typography>
                                <Box className={classes.editorBox}>
                                    <TextFieldRichtextEditorSwitchable
                                        fullWidth
                                        languageCode={languageCode}
                                        multiline={true}
                                        useRichText={choice?.type === "richText"}
                                        value={choice?.value}
                                        placeholder={cIndex === 0 ? "Input the correct choice here" : "Input an incorrect choice here. Leave it blank if there is already enough incorrect choices"}
                                        handleTextChange={value => updateChoice(cIndex, { type: "text", value: value })}
                                        handleRichTextChange={value => updateChoice(cIndex, { type: "richText", value: value })} />
                                </Box>
                            </Grid>);
                    })}
                </React.Fragment>
            break;
        default:
            editor =
                <React.Fragment>
                    <Box onClick={isEditingSection ? () => { } : () => setStage("input")}>
                        {Boolean(question.type === "richText") ?
                            <RichTextEditor readOnly={true} value={question.text} /> :
                            <Typography>{question.text}</Typography>}
                    </Box>
                    {question.choices.map((choice, cIndex) =>
                        <Grid key={`choice-${cIndex}`} item xs={12} className={classes.editorGrid} onClick={isEditingSection ? () => { } : () => setStage("input")}>
                            <Typography className={classes.questionNumber}>{String.fromCharCode(cIndex + 65)}.</Typography>
                            <Box className={classes.editorBox}>
                                {Boolean(choice.type === "richText") ?
                                    <RichTextEditor readOnly={true} value={choice.value} /> :
                                    <Typography>{choice.value || choice}</Typography>}
                            </Box>
                        </Grid>
                    )}
                </React.Fragment>
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {Boolean(STAGES[stage]) && Boolean(STAGES[stage].previousStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].previousStage)}>Back</Button> : null}
                {Boolean(STAGES[stage]) && Boolean(STAGES[stage].nextStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].nextStage)} disabled={false}>Next</Button> : null}
                {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)}
                            disabled={!(Boolean(question.richText || question.text) && Boolean(question.choices[0]) && question.choices.filter(c => Boolean(c)).length >= 2)}>Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)}
                            disabled={!(Boolean(question.richText || question.text) && Boolean(question.choices[0]) && question.choices.filter(c => Boolean(c)).length >= 2)}>Next Question</Button>
                    </React.Fragment> :
                    null}
            </Grid>
        </React.Fragment >
    );
}