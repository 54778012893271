import React from 'react';
import { Switch, Route } from "react-router-dom";

/**
 * Material UI
 */
import { PaperEditor } from '../paper-editors/PaperEditor';
import { PaperTable } from '../papers/PaperTable';
import { PaperAttemptTable, PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX, PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX } from '../papers/PaperAttemptTable';
import { Paper } from '../papers/Paper';

export function PaperPanel(props) {
    return (
        <Switch>
            <Route exact path="/paper-attempts">
                <PaperAttemptTable {...props} view={PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX} />
            </Route>
            <Route exact path="/assignments">
                <PaperAttemptTable {...props} view={PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX} />
            </Route>
            {/* Review result of an assignment */}
            <Route exact path="/papers/:id/paper-attempts/:paperAttemptId/review/:historyIndex">
                <Paper {...props} isReview={true} />
            </Route>
            <Route exact path="/papers/:id/paper-attempts/:paperAttemptId/review">
                <Paper {...props} isReview={true} />
            </Route>
            {/* End: Review result of an assignment */}
            {/* Take/retake an assignment */}
            <Route exact path="/papers/:id/paper-attempts/:paperAttemptId">
                <Paper {...props} />
            </Route>
            {/* End: Take/retake an assignment */}
            {/* List out all test papers */}
            <Route exact path="/papers">
                <PaperTable {...props} />
            </Route>
            {/* End: List out all test papers */}
            {/* Create a new test paper */}
            <Route exact path={"/papers/new"}>
                <PaperEditor {...props} />
            </Route>
            {/* End: Create a new test paper */}
            {/* Duplicate a new test paper */}
            <Route path={"/papers/:id/duplicate"}>
                <PaperEditor {...props} isDuplicateMode={true} />
            </Route>
            {/* End: Duplicate a new test paper */}
            {/* Edit an existing test paper */}
            <Route path={"/papers/:id/edit"}>
                <PaperEditor {...props} />
            </Route>
            {/* End: Edit an existing test paper */}
            {/* Take a test paper at a specific stage */}
            <Route path={"/papers/:id/:currentStage"}>
                <Paper {...{ ...props }} />
            </Route>
            {/* End: Take a test paper at a specific stage */}
            {/* Take a test paper without an assignment */}
            <Route path={"/papers/:id"}>
                <Paper {...props} />
            </Route>
            {/* End: Take a test paper without an assignment */}
        </Switch >
    )
}