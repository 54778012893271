import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';

/**
 * Firebase
 */
import { loginWithGoogle, loginWithFacebook } from '../../serviecs/firebase';

/**
 * Login Menu
 */
export function LoginMenu(props) {
    const { t } = props;
    const classes = makeStyles(theme => ({
        button: {
            color: "white"
        },
        expandIcon: {
            marginLeft: theme.spacing(0),
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        }
    }))();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button className={classes.button} onClick={handleClick}
                classes={{ endIcon: classes.expandIcon }}
                endIcon={<Icon>expand_more</Icon>}>{t("Login")}</Button>
            <Menu id="login-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    key="menu-item-login-google"
                    onClick={loginWithGoogle}>
                    <Typography variant="subtitle2">{t("Google")}</Typography>
                </MenuItem>
                <MenuItem
                    key="menu-item-login-facebook"
                    onClick={loginWithFacebook}>
                    <Typography variant="subtitle2">{t("Facebook")}</Typography>
                </MenuItem >
            </Menu >
        </div >
    );
}