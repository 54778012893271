import React from 'react';

/**
 * Material UI
 */
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, makeStyles } from '@material-ui/core';

export function MessageDialog(props) {
    const classes = makeStyles(theme => ({
        dialogTitle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    }))();
    const { title, message, actions, closeCallback, maxWidth = "md", fullScreen = false } = props;
    const [open, setOpen] = React.useState(props.open || false);

    const handleClose = () => {
        setOpen(false);
        if (Boolean(closeCallback)) {
            closeCallback();
        }
    };

    return (
        <div>
            <Dialog open={open}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(actions || []).map(a => <Button key={a.key} onClick={() => { a.handler(); handleClose(); }} color="primary" autoFocus={a.isDefault || false}>{a.label}</Button>)}
                    {closeCallback && <Button key="cancel" onClick={handleClose} color="primary">{Boolean(actions) ? "Cancel" : "Got it"}</Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}