import React from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, Typography, Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionEditor } from './SectionEditor';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "dictation",
    title: null,
    instruction: null
};

const transformSectionAsAiFillInBlankSection = (section) => {
    let transformedQuestions = section?.questions.map(question => ({ word: question?.answer }));
    return Object.assign({}, section, { questions: transformedQuestions });
}

export function DictationSectionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
        },
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        },
        boldTextField: {
            fontWeight: 800
        }
    }))();
    const { sectionIndex, updateSection, languageCode, copyAsNewSection } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input" }] }, props.section);
    const updateThisSection = (sectionIndex, section) => {
        var originalSection = Object.assign({}, section, { isEditing: section.questions.filter(q => q.stage !== undefined).length > 0 });
        updateSection(sectionIndex, originalSection);
    }
    const addQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex + 1, 0, Object.assign({}, { id: uuid(), stage: "input", autoFocus: true }));
        updateThisSection(sectionIndex, originalSection);
    }
    const removeQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, originalSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, originalSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var originalSection = Object.assign({}, section);
        originalSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, originalSection);
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <DictationQuestionEditor
                    key={question.id}
                    className={classes.questionEditor}
                    question={question}
                    questionIndex={questionIndex}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    updateQuestion={updateQuestion}
                    isEditingSection={section.isEditing}
                    individualQuestionText={section.individualQuestionText}
                    languageCode={languageCode} />)}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
                {/* Children if any */}
                {props.children}
                {/* End: Children if any */}
                {/* Custom Buttons for this Section */}
                <Box mt={3}>
                    <DictationSectionEditorButtons
                        section={section}
                        copyAsNewSection={copyAsNewSection} />
                </Box>
                {/* End: Custom Buttons for this Section */}
            </Grid>
        </SectionEditor>
    );
}

const STAGES = {
    input: { hint: "Type in your question and the answer." },
};
export function DictationQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, individualQuestionText, languageCode } = props;
    const question = Object.assign(props.question);
    const stage = question.stage;
    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        delete originalQuestion.autoFocus;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
            // } else {
            //     complete(false);
        }
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <React.Fragment>
                    <TextFieldWithSpeechRecognition fullWidth
                        languageCode={languageCode}
                        variant="outlined"
                        multiline={true}
                        value={question.answer}
                        autoFocus={Boolean(question.autoFocus)}
                        //label="Answer"
                        placeholder="Input the answer here"
                        onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { answer: e.target.value }))} />
                </React.Fragment>
            break;
        default:
            editor =
                <React.Fragment>
                    <Typography onClick={isEditingSection ? () => { } : () => setStage("input")}>A: {question.answer}</Typography>
                </React.Fragment>
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {stage === "input" ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)}
                            disabled={!Boolean(question.answer)}>Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)}
                            disabled={!Boolean(question.answer)}>Next Question</Button>
                    </React.Fragment> :
                    null}
            </Grid>
        </React.Fragment >
    );
}

const DictationSectionEditorButtons = (props) => {
    const { section, copyAsNewSection } = props;
    const classes = makeStyles(theme => ({
        button: {
            fontWeight: 800
        }
    }))();
    return (
        <Box>
            <Button className={classes.button}
                color="primary"
                startIcon={<Icon>auto_awesome</Icon>}
                onClick={() => copyAsNewSection("ai-generated-fill-in-blank", transformSectionAsAiFillInBlankSection(section))}
                disabled={section?.questions?.filter(q => Boolean(q?.answer)).length === 0 || section?.questions?.some(q => Boolean(q?.stage))}>Copy as AI Fill in Blank section</Button>
        </Box>
    );
}