/**
 * Material UI
 */
import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

export * from './ActionMenu';
export * from './ExpandableCheckbox';
export * from './Dictionary';
export * from './DeleteDocumentFromFirebaseDialog';
export * from './GoogleLoginButton';
export * from './NumericControl';
export * from './TextFieldRichtextEditorSwitchable';
export * from './TextFieldWithSpeechRecognition';
export * from './Timer';
export * from './TypographyWithSelectionSupport';
export * from './SectionTitle';
export * from './SelectTextBox';
export * from './SpeakableTypography';
export * from './VoiceButtons';

export const CustomSlider = withStyles({
    valueLabel: {
        left: 'calc(-50% - 4px)',
        top: 15,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
})(Slider);