import React from 'react';

/**
 * Slate
 */
import { Editor, Transforms, Range } from 'slate';
import { useSlate } from 'slate-react';

/**
 * Material UI
 */
import { IconButton, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Miscellaneous
 */
import isUrl from 'is-url'

/**
 * Suggested view element for this plugin
 */
export const LinkElement = ({ attributes, element, children }) => {
    return (
        <a {...attributes} href={element.url}>
            {children}
        </a>);
}

/**
 * Commom Methods
 */
const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
    return !!link
}

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }
    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

/**
 * Button to Insert Link
 */
export const LinkButton = () => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const editor = useSlate()
    const insertLink = (url) => {
        if (editor.selection) {
            wrapLink(editor, url)
        }
    }
    return (
        <IconButton
            size='small'
            className={isLinkActive(editor) ? null : classes.inactive}
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the link:')
                if (!url) {
                    return;
                } else {
                    insertLink(url);
                }
            }} >
            <Icon>link</Icon>
        </IconButton>
    )
}

/**
 * Wrapper to override Editor from 'slate'
 */
export const withLinks = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    /**
     * Support Pastinng from Clipboard
     */
    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertText(text)
        }
    }

    /**
     * Support Pastinng from Clipboard
     */
    editor.insertData = data => {
        const text = data.getData('text/plain')
        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor;
}