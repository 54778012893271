import React from 'react';

/**
 * Material UI
 */
import { Grid, Typography } from '@material-ui/core';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionHeader } from './controls/SectionHeader';

const EMPTY_SECTION_ATTEMPT = { score: 0, answers: [] };
export function TrueOrFalseSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
        },
        questionGrid: {
            display: "flex",
            textAlign: "left"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        correctChoiceText: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            borderRadius: 10,
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, EMPTY_SECTION_ATTEMPT, {
        numberOfAnswers: section.questions.length,
        answers: [...Array(section.questions.length)].map(a => null)
    }, props.sectionAttempt);

    const updateAnswer = (questionIndex, answer) => {
        let answers = [...sectionAttempt.answers];
        answers[questionIndex] = answer;
        // Calculate score on the spot
        let score = answers.filter((a, aIndex) => a === section.questions[aIndex]?.answer).length;
        updateSectionAttempt(sectionIndex, { ...{}, ...sectionAttempt, ...{ answers: answers, score: score } });
    }

    return (
        <Grid container spacing={3} className="print-table">
            <SectionHeader section={section}
                sectionIndex={sectionIndex}
                sectionAttempt={sectionAttempt}
                showScore={showScore}
                showAnswer={showAnswer} />
            {section.questions.map((question, qIndex) =>
                <Grid key={`question-${section.id}-${qIndex}`} item xs={12} className={classes.questionGrid}>
                    <Typography className={[classes.textBlock, classes.questionNumberMarker]}>{qIndex + 1}. </Typography>
                    <FormControl component="fieldset">
                        <Typography variant="body1">{question.text}</Typography>
                        {/* RadioGroup doesn't support integer as value, use "T" or "F" as a workaround */}
                        <RadioGroup value={sectionAttempt.answers[qIndex] !== null ? (Boolean(sectionAttempt.answers[qIndex]) ? "T" : "F") : null}
                            onChange={(e) => updateAnswer(qIndex, (e.target.value === "T"))}>
                            {[{ label: "True", value: "T" }, { label: "False", value: "F" }].map((option, oIndex) => {
                                let className = null;
                                if ((section.questions[qIndex].answer ? "T" : "F") === option.value) {
                                    if (sectionAttempt.answers[qIndex] !== undefined &&
                                        sectionAttempt.answers[qIndex] !== null &&
                                        (sectionAttempt.answers[qIndex] ? "T" : "F") === option.value) {
                                        // Correct answer
                                        className = classes.correctAnswerText;
                                    } else {
                                        // Correct choice but not selected answer
                                        className = classes.correctChoiceText;
                                    }
                                } else if (sectionAttempt.answers[qIndex] !== undefined &&
                                    sectionAttempt.answers[qIndex] !== null &&
                                    (sectionAttempt.answers[qIndex] ? "T" : "F") === option.value) {
                                    // Incorrect selected answer
                                    className = classes.incorrectAnswerText;
                                }
                                return <FormControlLabel value={option.value} key={oIndex}
                                    disabled={showAnswer}
                                    control={<Radio color="primary" />}
                                    label={<Typography className={showAnswer ? className : null}>{option.label}</Typography>} />
                            })}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )}
        </Grid >
    )
}
