import React, { useCallback, useMemo, useContext, useEffect, useLayoutEffect, useState } from 'react'

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Box, IconButton, Tooltip, Typography, Icon } from '@material-ui/core';

/**
 * Slate
 */
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Node } from 'slate'
import { withHistory } from 'slate-history'
import { CompactToolbar, StandardToolbar } from './controls';
import { withMedia, MediaElement, InsertMediaLinkButton, SelectMediaButton } from './plugins/Media';
import { withLinks, LinkElement, LinkButton } from './plugins/Links';
import { withVideos, VideoElement, InsertVideoButton } from './plugins/Videos';
import { withMathExpressions, MathExpressionElement, InsertMathExpressionButton, MathExpressionEditorDialog } from './plugins/MathExpression';

/**
 * QR Code
 */
import QRCode from 'qrcode.react';

/**
 * Miscellaneous
 */
import isHotkey from 'is-hotkey';

/**
 * Application
 */
import { UserContext } from '../../App';
import { PrintLayoutContext } from './hooks';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const prepopulatedRichTextValue = (text) => {
    return (
        [{
            type: "paragraph",
            children: [{
                text: (text || "")
            }]
        }]
    );
}

/**
 * Extract a list of URLs for all media items in sequence
 */
export const extractMediaUrls = (value) => {
    let urls = [];
    value.forEach(item => {
        if (item.type === "audio") {
            urls.push(item.url);
        }
        if (Boolean(item.children)) {
            urls = urls.concat(extractMediaUrls(item.children));
        }
    });
    return urls;
}

/**
 * TODO: Include a Table plugin
 */
export const RichTextEditor = (props) => {
    const { account } = useContext(UserContext);
    const [showMenu, setShowMenu] = useState(true);
    const classes = makeStyles(theme => ({
        editorBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2),
        }
    }))();
    const { variant, mode = 'standard', readOnly, printLayout, setValue, additionalActions, className, ...others } = props;
    const value = (typeof props.value === "string")
        ? prepopulatedRichTextValue(props.value) :
        props.value || prepopulatedRichTextValue("");

    /**
     * Override Slate Editor Config
     */
    const renderElement = useCallback(props => {
        return <Element {...props} />
    }, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    // Embed loggedInUser into the editor object
    var editor = createEditor();
    editor.account = account;
    // Wrap the editor with all the various plugins
    editor = useMemo(() => withMathExpressions(
        withVideos(
            withLinks(
                withMedia(
                    withHistory(
                        withReact(editor)))))),
        []);

    /**
     * Insert empty paragraphy after non-paragraph node when hitting enter
     * TODO: Externalize
     */
    const { insertBreak, deleteBackward, isVoid, isInline } = editor;
    // editor.insertBreak = () => {
    //     const { selection } = editor;
    //     let parentNode = Node.parent(editor, selection.focus.path);
    //     if (Boolean(parentNode) && parentNode.type !== "paragraph") {
    //         Transforms.insertNodes(editor, [{
    //             type: "paragraph",
    //             children: [{
    //                 text: ""
    //             }]
    //         }])
    //     } else {
    //         insertBreak();
    //     }
    // }
    // editor.isVoid = (element) => {
    //     return (element.isVoid !== undefined) ? Boolean(element.isVoid) : isVoid(element);
    // }
    editor.isInline = (element) => {
        return (element.isInline !== undefined) ? Boolean(element.isInline) : isInline(element);
    }

    editor.isVoid = (element) => {
        return (element.type === 'break') ? true : isVoid(element);
    }
    editor.insertBreak = () => {
        let { path } = editor?.selection?.focus;
        let elementIndex = path[0] || 0;
        let targetCursor = {
            path: [elementIndex + 1, 0],
            offset: 0
        }
        // let nextNode = Editor.next(editor);
        // let currentNode = Editor.node(editor, path);
        // Transforms.setNodes(editor, { break: true }, { at: path });
        Transforms.insertNodes(editor, [
            // { type: 'break', children: [{ text: '' }] },
            { type: 'paragraph', children: [{ text: '', breakBefore: true }] }
        ]);
        Transforms.select(editor, {
            anchor: targetCursor,
            focus: targetCursor
        })
    }

    // TODO: How to move it into the plugin?
    const mediaUrls = extractMediaUrls(value);
    editor.mediaUrls = mediaUrls;

    return (
        <PrintLayoutContext.Provider value={printLayout}>
            {/* TODO: To Remove - Debug Message */}
            {false && Boolean(process.env.NODE_ENV === 'development') &&
                <React.Fragment>
                    <Box style={{ whiteSpace: 'pre-wrap' }}>
                        Value: {JSON.stringify(value, null, '\t')}
                    </Box>
                    <Box>
                        At: {JSON.stringify(editor?.selection, null, '\t')}
                    </Box>
                </React.Fragment>}
            {/* End: TODO: To Remove - Debug Message */}
            <Box className={className || (variant === "outlined" ? classes.editorBox : null)} style={{ position: 'relative' }} {...others}>
                <Slate editor={editor} value={value} onChange={v => setValue(v)}>
                    {/* Standard Toolbar - Top */}
                    {!Boolean(readOnly) && Boolean(mode === 'standard') &&
                        <Box display="flex" justifyContent='flex-start' flexWrap='wrap' style={{ borderBottom: '2px solid #EEEEEE' }} pb={0.5} mb={1}>
                            <StandardToolbar />
                        </Box>}
                    {/* End: Standard Toolbar - Top */}
                    {/* Custom Toolbar */}
                    {!Boolean(readOnly) && Boolean(mode === 'compact') &&
                        <Box position='absolute' right={10} display='flex' justifyContent='flex-end'>
                            <IconButton size="small" onClick={() => setShowMenu(!showMenu)}>
                                <Icon fontSize="small">{Boolean(showMenu) ? 'expand_less' : 'expand_more'}</Icon>
                            </IconButton>
                            {/* Custom Mode Additional Actions */}
                            <Box display="inline-flex">
                                {Boolean(additionalActions) && additionalActions}
                            </Box>
                            {/* End: Custom Mode Additional Actions */}
                        </Box>}
                    {/* End: Custom Toolbar */}
                    <Typography variant="body1">
                        <Editable
                            readOnly={Boolean(readOnly)}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            placeholder="You can input rich text / image / audio / video / Math expression here..."
                            spellCheck
                            autoFocus
                            onKeyDown={event => {
                                for (const hotkey in HOTKEYS) {
                                    if (isHotkey(hotkey, event)) {
                                        event.preventDefault()
                                        const mark = HOTKEYS[hotkey]
                                        toggleMark(editor, mark)
                                    }
                                }
                            }}
                        />
                    </Typography>
                    {/* Toolbar - Bottom */}
                    {!Boolean(readOnly) && (Boolean(mode === 'standard') || Boolean(showMenu)) &&
                        <Box display="flex" justifyContent='flex-start' flexWrap='wrap' style={{ borderTop: '2px solid #EEEEEE' }} pt={0.5} mt={1}>
                            <StandardToolbar />
                        </Box>}
                    {/* End: Toolbar - Bottom */}
                </Slate>
                {/* QR Codes for Media - Print Only */}
                <Box mt={3} display="none" displayPrint="inline-block">
                    {mediaUrls.map((url, uIndex) =>
                        <Box m={1} style={{ display: "inline-block" }}>
                            <Badge badgeContent={uIndex + 1} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                                <QRCode style={{ verticalAlign: "middle" }} value={url} size={80} />
                            </Badge>
                        </Box>
                    )}
                </Box>
                {/* End: QR Codes for Media - Print Only */}
            </Box>
        </PrintLayoutContext.Provider>
    )
}

const Element = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'image':
        case 'audio':
            return <MediaElement {...props} />
        case 'link':
            return <LinkElement {...props} />
        case 'video':
            return <VideoElement {...props} />
        case 'math-expression':
            return <MathExpressionElement {...props} />
        default:
            return <div {...attributes}>{children}</div>
    }
}

const Leaf = (props) => {
    const { attributes, children, leaf } = props
    let childrenWithMarks = children;
    if (leaf.bold) {
        childrenWithMarks = <strong>{childrenWithMarks}</strong>
    }
    if (leaf.code) {
        childrenWithMarks = <code>{childrenWithMarks}</code>
    }
    if (leaf.italic) {
        childrenWithMarks = <em>{childrenWithMarks}</em>
    }
    if (leaf.underline) {
        childrenWithMarks = <u>{childrenWithMarks}</u>
    }
    return (
        <React.Fragment>
            {Boolean(false && leaf.breakBefore) && <br />}
            <span {...attributes}>{childrenWithMarks}</span>
        </React.Fragment>
    );
}

/**
 * Mark Operations
 * TODO: Externalize and centralize
 */
const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}


