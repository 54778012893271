import React, { useState, useEffect } from 'react';

/**
 * Firebase
 */
import { firestore } from '../../serviecs/firebase';

/**
 * Material UI
 */
import { Box, Typography, MenuItem, Menu, Avatar, IconButton, Icon, Button, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { LoadingMask } from './LoadingMask';
import { hashCode } from '../../functions/StringUtils';
import { USER_ROLES } from '../../../app/Constants';

export function UserSelectionPanel(props) {
    const classes = makeStyles(theme => ({
        root: {
            width: "100%",
            color: "rgb(44,62,80)",
            fontFamily: "Calibri",
            textAlign: "center",
        },
        sectionImage: {
            height: theme.spacing(10),
            width: theme.spacing(10),
            borderRadius: "50%",
            border: "3px solid #ebebeb",
            boxShadow: "0 3px 2px rgba(0, 0, 0, 0.1)",
            cursor: "pointer"
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(1),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 100,
                height: 4,
                content: `''`,
                transform: "matrix(1,0,0,1,-50,0)"
            }
        },
    }))();

    /**
     * TODO: Move to the main app and put into context
     */
    const { account, onSelectUser } = props;
    const [accountUsers, setAccountUsers] = useState();
    const [validatingUser, setValidatingUser] = useState();
    // TODO: Consider using onSnapshot to subscribe for changes
    useEffect(() => {
        if (!Boolean(accountUsers)) {
            firestore.collection("users").where("ownerUserId", "==", account.ownerUserId).get().then(usersRef => {
                var users = [];
                usersRef.forEach(userRef => {
                    users.push(Object.assign({ id: userRef.id }, userRef.data()));
                });
                setAccountUsers(users);
            });
        }
    }, [])
    return (
        Boolean(accountUsers) ?
            <React.Fragment>
                {Boolean(validatingUser) &&
                    <PasswordDialog open={true}
                        successCallback={() => onSelectUser(validatingUser)}
                        closeCallback={() => setValidatingUser(null)}
                        user={validatingUser} />}
                <Box m={3}>
                    <Typography className={classes.titleLine} variant="h6">Select User</Typography>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {accountUsers.filter(u => (account.email === u.email) || (USER_ROLES[account.role].entitledRoles.includes(u.role) && !Boolean(u.email))).map((user, uIndex) =>
                        <Box m={3} key={uIndex} display="flex" flexDirection="column" alignItems="center" onClick={() => { Boolean(user.passwordHashCode) ? setValidatingUser(user) : onSelectUser(user); }}>
                            <Box>
                                <Avatar className={classes.sectionImage} src={user.imageUrl} />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="textPrimary">{user.fullName}</Typography>
                            </Box>
                        </Box>)}
                </Box>
            </React.Fragment > :
            <LoadingMask message="Loading user profiles" />);
}

export function UserSelectionMenu(props) {
    const classes = makeStyles(theme => ({
        root: {
            width: "100%",
            color: "rgb(44,62,80)",
            fontFamily: "Calibri",
            textAlign: "center",
        },
        textButton: {
            fontWeight: 800
        }
    }))();
    const { account, user, onSelectUser, buttonLabel, buttonIcon } = props;
    const showDependentUsersOnly = Boolean(props.showDependentUsersOnly);
    const requirePassword = (props.requirePassword !== undefined) ? props.requirePassword : true;
    const [anchorEl, setAnchorEl] = useState();
    const [accountUsers, setAccountUsers] = useState();
    const [validatingUser, setValidatingUser] = useState();
    const hasOtherUsers = Boolean(accountUsers) && accountUsers.filter(u => u.id !== user.id).length > 0;
    const handleClose = () => {
        setAnchorEl(null);
    }
    // TODO: Consider using onSnapshot to subscribe for changes
    useEffect(() => {
        if (Boolean(account) && !Boolean(accountUsers)) {
            firestore.collection("users").where("ownerUserId", "==", account.ownerUserId).get().then(usersRef => {
                var users = [];
                usersRef.forEach(userRef => {
                    users.push(Object.assign({ id: userRef.id }, userRef.data()));
                });
                setAccountUsers(users);
            });
        }
    }, [account]);
    return (
        <React.Fragment>
            {Boolean(buttonLabel) ?
                <Button color="primary"
                    className={classes.textButton}
                    onClick={e => setAnchorEl(e.target)}>{buttonLabel}</Button> :
                <IconButton disabled={!hasOtherUsers} onClick={e => setAnchorEl(e.target)} size={Boolean(buttonIcon) ? "small" : "normal"}>
                    {Boolean(buttonIcon) ?
                        <Icon>{buttonIcon}</Icon> :
                        <Avatar src={user.imageUrl}>
                            {user.firstName ? user.firstName.substring(0, 1) : null}
                            {user.lastName ? user.lastName.substring(0, 1) : null}
                        </Avatar>}
                </IconButton>}
            {/* Menu only visible if there is other users */}
            {Boolean(account) && Boolean(user) && hasOtherUsers &&
                <React.Fragment>
                    {Boolean(validatingUser) &&
                        <PasswordDialog open={true}
                            successCallback={() => onSelectUser(validatingUser)}
                            closeCallback={() => setValidatingUser(null)}
                            user={validatingUser} />}
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClose()}>
                        {accountUsers.filter(u => (true || u.id !== user.id) && (u.id === account.id || !showDependentUsersOnly || !Boolean(u.email))).map(user =>
                            <MenuItem key={user.id}
                                onClick={() => {
                                    (Boolean(requirePassword) && Boolean(user.passwordHashCode)) ? setValidatingUser(user) : onSelectUser(user);
                                    handleClose();
                                }} >
                                <ListItemIcon>
                                    <Avatar size="small" src={user.imageUrl}>
                                        {user.firstName ? user.firstName.substring(0, 1) : null}
                                        {user.lastName ? user.lastName.substring(0, 1) : null}
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="subtitle2">{user.fullName}</Typography>
                                </ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </React.Fragment>}
        </React.Fragment >);
}

export function PasswordDialog(props) {
    const classes = makeStyles(theme => ({
        dialogTitle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    }))();
    const { user, closeCallback, successCallback } = props;
    const [open, setOpen] = React.useState(props.open || false);

    const handleClose = () => {
        setOpen(false);
        if (Boolean(closeCallback)) {
            closeCallback();
        }
    };
    const validatePassword = (inputPassword) => {
        if (hashCode(inputPassword) === user.passwordHashCode) {
            handleClose();
            successCallback();
        } else {
            // Do nth
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className={classes.dialogTitle}>
                    <Box fontWeight={800} textAlign="center">
                        <Typography variant="h6" color="primary.contrastText">Login as {user.fullName}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box m={2}>
                        <TextField type="password" fullWidth label="Password"
                            InputProps={{ autoFocus: true }}
                            InputLabelProps={{ shrink: true, textAlign: "center" }}
                            onChange={e => validatePassword(e.target.value)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button key="cancel" onClick={handleClose} color="primary" size="small">Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}