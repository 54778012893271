import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Box, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

export function UserDetails(props) {
    const classes = makeStyles(theme => ({
        root: {
        },
        valueCell: {
            maxWidth: 300,
            overflowWrap: "break-word"
        }
    }))();

    const { user } = props;
    const USER_ATTRIBUTES = [
        { key: "id", label: "ID" },
        { key: "fullName", label: "Full Name" },
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "imageUrl", label: "Image URL" },
        { key: "email", label: "Email" },
        { key: "phoneNumber", label: "Phone Number" },
        { key: "idToken", label: "ID Token" },
        { key: "refreshToken", label: "Refresh Token" },
    ];

    return (
        <Box mt={10}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Attributes</TableCell>
                        <TableCell align="center">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Boolean(user) ?
                        USER_ATTRIBUTES.map(a =>
                            <TableRow style={{ width: "100%" }}>
                                <TableCell align="center">{a.label}</TableCell>
                                <TableCell className={classes.valueCell} align="center">{eval(`user.${a.key}`)}</TableCell>
                            </TableRow>) : null}
                </TableBody>
            </Table>
        </Box>
    );
}