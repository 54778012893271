import React from 'react';

/**
 * Material UI
 */
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { RichTextEditor } from '../../../../lib/slate/RichTextEditor';

export function SectionHeader({ section, sectionIndex, sectionAttempt, showScore, showAnswer, sectionControl }) {
    const classes = makeStyles(theme => ({
        sectionGrid: {
            textAlign: "left"
        },
        sectionTitle: {
            fontWeight: 800,
            marginBottom: theme.spacing(2),
            position: "relative",
            "&::after": {
                position: "absolute",
                top: -5,
                left: -15,
                height: 30,
                width: 30,
                // borderRadius: 3,
                borderBottom: `30px solid ${theme.palette.primary.main}CC`,
                content: `''`,
                //borderColor: theme.palette.primary.main,
                zIndex: -1,
            }
        },
    }))();
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.sectionGrid}>
                <Box fontWeight={800}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="subtitle1" className={classes.sectionTitle}>Part {sectionIndex + 1}. {section.title}</Typography>
                        {Boolean(sectionControl) && sectionControl}
                    </Box>
                    <Typography variant="body1">{section.instruction}</Typography>
                </Box>
                {showScore &&
                    <Box>
                        <Typography variant="subtitle2">Score: {sectionAttempt.score} / {sectionAttempt.numberOfAnswers} </Typography>
                    </Box>}
            </Grid>
            {Boolean(section.richText) &&
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Box p={2} border={1} borderRadius={4} borderColor="textPrimary">
                            <RichTextEditor readOnly={true} value={section.richText} />
                        </Box>
                    </Typography>
                </Grid>}
        </React.Fragment>
    );
}