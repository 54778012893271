import React from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { NumberTextField } from '../../../common/components/controls/NumberTextField';
import { SectionHeader } from './controls/SectionHeader';

const EMPTY_SECTION_ATTEMPT = { score: 0, answers: [] };
export function ArithmeticOperationSection(props) {
    const classes = makeStyles(theme => ({
        answerBox: {
            display: "flex",
            justifyContent: "space-around",
            border: "1px solid",
            borderColor: theme.palette.text.primary,
            borderRadius: 4,
        },
        numberBox: {
            letterSpacing: theme.spacing(2),
            width: theme.spacing(2),
            textAlign: "center",
        },
        answerTextField: {
            letterSpacing: theme.spacing(1),
            textAlign: "right"
        },
        correctAnswerBox: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            borderRadius: 10,
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.map(q => q.expressionBlocks.filter(eb => typeof eb !== "number")).flat().length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);

    /**
     * sectionAttempt.answers would be an Object with
     * key: [questionIndex, expresionBlockIndex]
     * value: a number which refers to the user's answer input
     */
    const updateAnswer = (questionIndex, expressionBlockIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign({}, originalSectionAttempt.answers)
        originalAnswers[[questionIndex, expressionBlockIndex]] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = section.questions.map((q, qIndex) => q.expressionBlocks.filter((eb, ebIndex) => {
            return q.expressionBlocks[ebIndex - 1] && (typeof q.expressionBlocks[ebIndex - 1] !== "number") &&
                originalSectionAttempt.answers[[qIndex, ebIndex]] === eval(q.expressionBlocks.slice(0, ebIndex + 1).join(""));
        })).flat().length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    return (
        <Grid container spacing={3} className="print-table">
            <SectionHeader section={section} sectionIndex={sectionIndex} sectionAttempt={sectionAttempt} showAnswer={showAnswer} />
            {section.questions.map((question, qIndex) => {
                // Calculate the maximum number of digits along the calculation path,
                // and add one more digit as reserve
                var numberOfDigits = Math.max(...question.expressionBlocks.map(e => (typeof e === "number") ? e.toString().length : null).filter(e => e !== null));
                numberOfDigits = Math.max(numberOfDigits, eval(question.expressionBlocks.join("")).toString().length);
                numberOfDigits += 1;
                return <Grid key={question.id} item xs={12} sm={4} md={3}>
                    <Box display="flex" flexDirection="row" justifyItems="flex-start">
                        <Typography>{qIndex + 1}. </Typography>
                        <Box flexGrow={1} mr={3}>
                            {(question.expressionBlocks).map((expressionBlock, ebIndex) => {
                                if (typeof expressionBlock === "number") {
                                    var withAnswer = Boolean(question.expressionBlocks[ebIndex - 1]);
                                    var answer = showAnswer ? eval(question.expressionBlocks.slice(0, ebIndex + 1).join("")) : null;
                                    var attemptedAnswer = sectionAttempt.answers[[qIndex, ebIndex]];
                                    return (
                                        <Box key={ebIndex} display="flex" flexDirection="column" alignItems="flex-end">
                                            <Box display="flex" justifyContent="flex-end" style={{ paddingRight: 1 }}>
                                                {/* Put the opearator (which is the previous expressionBlock) onto the same line */}
                                                {withAnswer &&
                                                    <Typography className={classes.numberBox}>{question.expressionBlocks[ebIndex - 1]}</Typography>}
                                                {/* Add filler space to add up to the same number of digits for each line */}
                                                {[...Array(numberOfDigits - expressionBlock.toString().length).keys()].map((fIndex) => <Typography key={fIndex} className={classes.numberBox}>&nbsp;</Typography>)}
                                                {expressionBlock.toString().match(/[\d]/gi).map((e, eIndex) => <Typography key={eIndex} className={classes.numberBox}>{e}</Typography>)}
                                            </Box>
                                            {/* Answer TextField */}
                                            {withAnswer ?
                                                showAnswer ?
                                                    <React.Fragment>
                                                        <Box display="flex" justifyContent="flex-end"
                                                            borderTop={1}
                                                            className={answer === attemptedAnswer ? classes.correctAnswerText : classes.incorrectAnswerText}>
                                                            {[...Array(numberOfDigits + 2 - (attemptedAnswer || "").toString().length).keys()].map((fIndex) => <Typography key={fIndex} className={classes.numberBox}>&nbsp;</Typography>)}
                                                            {((attemptedAnswer || "").toString().match(/[\d]/gi) || []).map((e, eIndex) => <Typography key={eIndex} className={classes.numberBox}>{e}</Typography>)}
                                                        </Box>
                                                        {answer !== attemptedAnswer ?
                                                            <Box display="flex" justifyContent="flex-end" className={classes.correctAnswerBox}>
                                                                {answer.toString().match(/[\d]/gi).map((e, eIndex) => <Typography key={eIndex} className={(answer.toString().length - 1) === eIndex ? null : classes.numberBox}>{e}</Typography>)}
                                                            </Box> : null}
                                                    </React.Fragment> :
                                                    <NumberTextField
                                                        className={classes.answerTextField}
                                                        numberOfDigits={numberOfDigits}
                                                        variant="standard"
                                                        onChange={e => updateAnswer(qIndex, ebIndex, parseInt(e.target.value))} /> : null
                                            }
                                        </Box>);
                                }
                                return null;
                            })}
                        </Box>
                    </Box>
                </Grid>
            })}
        </Grid >
    )
}
