import React, { useEffect } from 'react';

/**
 * Material UI
 */
import { Box, Grid, Typography, Icon, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionHeader } from './controls/SectionHeader';
import { shuffleArray } from '../../../common/functions/ArrayUtils';
import { CHAR_CODE_A } from '../../../common/Constants';
import { DELIMETER_REGEX } from '../section-editors/SentenceReorderingSectionEditor';

/**
 * sectionAttempt object:
 * - sentenceOrders: generate randomly as a map when it first get renders
 * - answers: a map for storing answer for each question
 */
const EMPTY_SECTION_ATTEMPT = { score: 0, answers: {} };
export function SentenceReorderingSection(props) {
    const classes = makeStyles(theme => ({
        textBlock: {
            display: "inline",
            whiteSpace: "pre-wrap",
            verticalAlign: "bottom",
            // lineHeight: "2.5em",
        },
        questionGrid: {
            display: "flex",
            alignItems: "flex-start",
            textAlign: "left"
        },
        questionNumberMarker: {
            minWidth: theme.spacing(5),
        },
        sentenceGrid: {
            display: "flex",
            alignItems: "flex-start",
            justifyItems: "flex-start"
        },
        sentenceAnswerBox: {
            width: theme.spacing(3),
            lineHeight: "1.5rem",
            textAlign: "center"
        },
        correctOrderText: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
            borderRadius: 10,
        },
        answerBlock: {
            border: `2px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
            borderRadius: 10,
        },
        correctAnswerText: {
            color: theme.palette.success.main,
        },
        incorrectAnswerText: {
            color: theme.palette.error.main,
        }
    }))();
    const { sectionIndex, section, updateSectionAttempt, showScore, showAnswer } = props;
    const sectionAttempt = Object.assign({}, { numberOfAnswers: section.questions.map(q => q.sentences).flat().length }, EMPTY_SECTION_ATTEMPT, props.sectionAttempt);

    const updateAnswer = (questionIndex, orderIndex, answer) => {
        let originalSectionAttempt = Object.assign({}, sectionAttempt);
        let originalAnswers = Object.assign({}, originalSectionAttempt.answers)
        originalAnswers[questionIndex] = originalAnswers[questionIndex] || [];
        originalAnswers[questionIndex][orderIndex] = answer;
        originalSectionAttempt.answers = originalAnswers;
        originalSectionAttempt.score = Object.keys(originalSectionAttempt.sentenceOrders).map(qIndex =>
            originalSectionAttempt.sentenceOrders[qIndex].filter((sIndex, oIndex) =>
                (originalAnswers[qIndex] || [])[oIndex] === String.fromCharCode(CHAR_CODE_A + originalSectionAttempt.sentenceOrders[qIndex].indexOf(oIndex)))).flat().length;
        updateSectionAttempt(sectionIndex, originalSectionAttempt);
    }

    useEffect(() => {
        // Shuffle sentences for the first time
        if (!Boolean(sectionAttempt.sentenceOrders)) {
            let originalSectionAttempt = Object.assign({}, sectionAttempt);
            let sentenceOrders = {};
            section.questions.forEach((q, qIndex) => sentenceOrders[qIndex] = shuffleArray([...Array(q.sentences.length).keys()]));
            originalSectionAttempt.sentenceOrders = sentenceOrders;
            updateSectionAttempt(sectionIndex, originalSectionAttempt);
        }
    }, [sectionAttempt.id]);

    return (
        Boolean(sectionAttempt.sentenceOrders) ?
            <Grid container spacing={3} className="print-table">
                <SectionHeader section={section}
                    sectionIndex={sectionIndex}
                    sectionAttempt={sectionAttempt}
                    showScore={showScore}
                    showAnswer={showAnswer} />
                {section.questions.map((question, qIndex) =>
                    <Grid key={question.id} item xs={12} className={classes.questionGrid}>
                        <Typography className={[classes.textBlock, classes.questionNumberMarker]}>{qIndex + 1}. </Typography>
                        <Box>
                            {sectionAttempt.sentenceOrders[qIndex].map((sentenceIndex, oIndex) =>
                                <Box key={`sentence-${oIndex}`} className={classes.sentenceGrid} mb={2}>
                                    <Box mr={2}>
                                        <Typography>{String.fromCharCode(oIndex + CHAR_CODE_A)}.</Typography>
                                    </Box>
                                    <Typography>
                                        {
                                            Boolean(question.hideTrailingSeparator) && Boolean(question.sentences[sentenceIndex].charAt(question.sentences[sentenceIndex].length - 1).match(DELIMETER_REGEX)) ?
                                                question.sentences[sentenceIndex].slice(0, -1) :
                                                question.sentences[sentenceIndex]
                                        }
                                    </Typography>
                                </Box>
                            )}
                            <Box style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                {sectionAttempt.sentenceOrders[qIndex].map((sentenceIndex, oIndex) => {
                                    let correctOrderLetter = String.fromCharCode(CHAR_CODE_A + sectionAttempt.sentenceOrders[qIndex].indexOf(oIndex));
                                    let isCorrect = ((sectionAttempt.answers[qIndex] || [])[oIndex] === correctOrderLetter);
                                    return <React.Fragment key={`answer-box-${oIndex}`}>
                                        {oIndex === 0 ? null : <Box mx={2}><Icon>arrow_forward</Icon></Box>}
                                        {showAnswer ?
                                            <React.Fragment>
                                                <Typography className={isCorrect ? classes.correctAnswerText : classes.incorrectAnswerText}>{(sectionAttempt.answers[qIndex] || [])[oIndex]}</Typography>
                                                {!isCorrect ? <Typography className={classes.correctOrderText}>{correctOrderLetter}</Typography> : null}
                                            </React.Fragment> :
                                            <React.Fragment>
                                                <Box mb={2} displayPrint="block" display="none">
                                                    {/* Show Text Box for Print */}
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={showAnswer}
                                                        value={(sectionAttempt.answers[qIndex] || [])[oIndex]}
                                                        inputProps={{ className: classes.sentenceAnswerBox }} />
                                                    {/* End: Show Text Box for Print */}
                                                </Box>
                                                <Box mb={2} displayPrint="none" display="block">
                                                    {/* Show Dropdown for Web */}
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        select
                                                        disabled={showAnswer}
                                                        value={(sectionAttempt.answers[qIndex] || [])[oIndex]}
                                                        onChange={(e) => updateAnswer(qIndex, oIndex, e.target.value.toUpperCase())}
                                                        inputProps={{ className: classes.sentenceAnswerBox }}>
                                                        {question.sentences.map((s, sIndex) => {
                                                            let optionValue = String.fromCharCode(CHAR_CODE_A + sIndex);
                                                            return <MenuItem key={optionValue} value={optionValue}>{optionValue}</MenuItem>;
                                                        })}
                                                    </TextField>
                                                    {/* End: Show Dropdown for Web */}
                                                </Box>
                                            </React.Fragment>}
                                    </React.Fragment>
                                }
                                )}
                            </Box>
                        </Box>
                    </Grid>)
                }
            </Grid > : null
    )
}
