import React from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, TextField, Typography, Button, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionEditor } from './SectionEditor';

/**
 * Application
 */
import { DualLabelSwitch } from '../../../common/components/controls/DualLabelSwitch';
import { CustomSlider } from '../../../common/components/controls';

/**
 * Constants
 */
const DEFAULT_SECTION_ATTRIBUTES = {
    type: "arithmetic-operation",
    title: null,
    instruction: null,
    mode: "manual",
    step: 1,
    numberOfQuestions: 5,
    addition: true,
    range: 100
};

/**
 * TODO: Areas of improvement
 * 1. Validation for manual expression input
 * 2. Better range handling
 */
export function ArithmeticOperationSectionEditor(props) {
    const classes = makeStyles(theme => ({
        switchLabel: {
            color: theme.palette.text.primary,
            justifyContent: "left",
        },
        switchFormControl: {
            marginLeft: 0
        }
    }))();
    const { sectionIndex, updateSection } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [] }, props.section);
    const generateRandomQuestions = (section) => {
        let availableOperators = [Boolean(section.addition) ? "+" : null, Boolean(section.subtraction) ? "-" : null].filter(o => o !== null);
        let questions = [...Array(section.numberOfQuestions || 0).keys()].map(qIndex => {
            var initialFactor = (0.5 + (Math.random() * 0.5));
            var remainingFactor = 1 - initialFactor;
            var expressionBlocks = [Math.ceil(section.range * initialFactor)];
            [...Array(section.step).keys()].forEach(() => {
                let previousSum = eval(expressionBlocks.join(""));
                let operator = availableOperators[Math.floor(availableOperators.length * Math.random())];
                expressionBlocks.push(operator);
                switch (operator) {
                    case "+": {
                        var thisFactor = (0.5 + (Math.random() * 0.5)) * remainingFactor;
                        remainingFactor -= thisFactor;
                        let newNumber = Math.ceil(section.range * thisFactor);
                        // Handle Carry - quite complex logic
                        if (Boolean(section.additionWithCarry)) {
                            let previousSumDigits = previousSum.toString().match(/[\d]/gi).map(d => parseInt(d)).reverse();
                            let newNumberDigits = newNumber.toString().match(/[\d]/gi).map(d => parseInt(d)).reverse();
                            // Not to adjust the first digit to avoid overflow
                            let digitsToAdjust = Math.min(previousSumDigits.length, newNumberDigits.length) - 1;
                            [...Array(digitsToAdjust).keys()].forEach(reversedIndex => {
                                var shortfall = 10 - (previousSumDigits[reversedIndex] + newNumberDigits[reversedIndex])
                                // If adjustment needed
                                if (shortfall > 0) {
                                    // Add shortfall to make the digit the same as the minuend
                                    var adjustment = shortfall;
                                    // Add random adjustment to make the digit as big as 9
                                    adjustment += Math.ceil(9 * Math.random());
                                    // Adjust according to the position of the digit
                                    adjustment *= (Math.pow(10, reversedIndex));
                                    // Minus 10/100 etc to avoid overflow
                                    adjustment -= Math.pow(10, reversedIndex + 1);
                                    // Make the adjustment to the number
                                    newNumber += adjustment;
                                }
                            });
                        }
                        expressionBlocks.push(newNumber);
                        break;
                    }
                    case "-": {
                        let newNumber = Math.ceil(previousSum * (0.5 + (Math.random() * 0.5)));
                        // Handle Borrow - quite complex logic
                        if (Boolean(section.subtractionWithBorrow)) {
                            let previousSumDigits = previousSum.toString().match(/[\d]/gi).map(d => parseInt(d)).reverse();
                            let newNumberDigits = newNumber.toString().match(/[\d]/gi).map(d => parseInt(d)).reverse();
                            // Not to adjust the first digit to avoid overflow
                            let digitsToAdjust = Math.min(previousSumDigits.length, newNumberDigits.length) - 1;
                            [...Array(digitsToAdjust).keys()].forEach(reversedIndex => {
                                var shortfall = previousSumDigits[reversedIndex] - newNumberDigits[reversedIndex]
                                // If adjustment needed
                                if (shortfall >= 0) {
                                    // Add shortfall to make the digit the same as the minuend
                                    var adjustment = shortfall;
                                    // Add random adjustment to make the digit as big as 9
                                    adjustment += Math.ceil((9 - previousSumDigits[reversedIndex]) * Math.random());
                                    // Adjust according to the position of the digit
                                    adjustment *= (Math.pow(10, reversedIndex));
                                    // Minus 10/100 etc to avoid overflow
                                    adjustment -= Math.pow(10, reversedIndex + 1);
                                    // Make the adjustment to the number
                                    newNumber += adjustment;
                                }
                            });
                        }
                        expressionBlocks.push(newNumber);
                        break;
                    }
                    default:
                        break;
                }
            });
            return { id: uuid(), expressionBlocks: expressionBlocks };
        });
        return questions;
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var updatedSection = Object.assign({}, section);
        updatedSection[attributeKey] = attributeValue;
        if (updatedSection.mode === "random") {
            updatedSection.questions = generateRandomQuestions(updatedSection);
        }
        updateSection(sectionIndex, Object.assign({}, updatedSection));
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions || []).map((question, questionIndex) =>
                <ArithmeticOperationQuestionEditor
                    key={question.id}
                    question={question}
                    questionIndex={questionIndex}
                    isEditingSection={section.isEditing} />
            )}>
            <Grid item xs={12} md={12}>
                <TextField
                    label="Maximum Range"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{ type: "number" }}
                    value={section.range}
                    onChange={e => updateAttribute("range", e.target.value)} />
            </Grid>
            <Grid item xs={12} md={3}>
                <DualLabelSwitch
                    value={section.mode === "random"}
                    onLabel="Random" offLabel="Manual" color="primary"
                    labelClass={classes.switchLabel}
                    onChange={e => updateAttribute("mode", e.target.checked ? "random" : "manual")} />
            </Grid>
            <Grid item xs={12} md={9}>
                {section.mode === "random" &&
                    <React.Fragment>
                        <Typography variant="subtitle2">Number of Questions</Typography>
                        <CustomSlider gutterTop defaultValue={section.numberOfQuestions} max={20} valueLabelDisplay="on" marks
                            onChange={(e, value) => updateAttribute("numberOfQuestions", value)} />
                    </React.Fragment>}
            </Grid>
            <Grid item xs={12}>
                <DualLabelSwitch
                    checked={section.step === 2}
                    onLabel="Two-Step" offLabel="One-Step" color="primary" labelClass={classes.switchLabel}
                    onChange={e => updateAttribute("step", e.target.checked ? 2 : 1)} />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className={classes.switchFormControl}
                    label="Addition"
                    labelPlacement="start"
                    control={
                        <Switch color="primary"
                            checked={section.addition}
                            onChange={e => updateAttribute("addition", e.target.checked)} />
                    } />
                {Boolean(section.addition) &&
                    <FormControlLabel
                        labelPlacement="start"
                        label="With Carry"
                        control={
                            <Switch color="primary"
                                checked={section.additionWithCarry}
                                onChange={e => updateAttribute("additionWithCarry", e.target.checked)} />
                        } />}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    className={classes.switchFormControl}
                    label="Subtraction"
                    labelPlacement="start"
                    control={
                        <Switch color="primary"
                            checked={section.subtraction}
                            onChange={e => updateAttribute("subtraction", e.target.checked)} />
                    } />
                {Boolean(section.subtraction) &&
                    <FormControlLabel
                        labelPlacement="start"
                        label="With Borrow"
                        control={
                            <Switch color="primary"
                                checked={section.subtractionWithBorrow}
                                onChange={e => updateAttribute("subtractionWithBorrow", e.target.checked)} />
                        } />}
            </Grid>
        </SectionEditor >
    );
}

/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Type in your question and specify either 'True' or 'False' as the answer." },
};
export function ArithmeticOperationQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        numberInputBox: {
            width: theme.spacing(3),
            lineHeight: "1.5rem",
            textAlign: "center"
        },
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection } = props;
    const question = Object.assign(props.question);
    const stage = question.stage;
    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            complete(false);
        }
    }
    const updateExpressionBlock = (ebIndex, expressionBlock) => {
        var updatedQuestion = Object.assign({}, question);
        updatedQuestion.expressionBlocks[ebIndex] = expressionBlock;
        updateQuestion(questionIndex, updatedQuestion);
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <Box display="flex" justifyItems="flex-start" alignItems="center">
                    {question.expressionBlocks.map((expressionBlock, ebIndex) =>
                        typeof expressionBlock === "number" ?
                            <TextField variant="outlined"
                                value={expressionBlock}
                                inputProps={{ className: classes.numberInputBox }}
                                onChange={e => updateExpressionBlock(ebIndex, parseInt(e.target.value))} />
                            : <Box px={2}>
                                <Typography>{expressionBlock}</Typography>
                            </Box>
                    )}
                </Box>
            break;
        default:
            editor =
                <React.Fragment>
                    <Typography onClick={isEditingSection ? () => { } : () => setStage("input")}>
                        {question.expressionBlocks.join(" ")} = {eval(question.expressionBlocks.join(" "))}
                    </Typography>
                </React.Fragment>
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {Boolean(STAGES[stage]) && Boolean(STAGES[stage].previousStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].previousStage)}>Back</Button> : null}
                {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)}
                            disabled={question.expressionBlocks.filter(b => !Boolean(b)).length > 0}> Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)}
                            disabled={question.expressionBlocks.filter(b => !Boolean(b)).length > 0}>Next Question</Button>
                    </React.Fragment> :
                    null}
            </Grid>
        </React.Fragment >
    );
}
