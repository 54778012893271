import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    zh: {
        translation: {
            "Test Paper Online": "線上試卷平台",
            "User Details": "用戶資料",
            "Home": "首頁",
            "Test Paper": "試卷",
            "Prepare Paper": "出試卷",
            "Item 1": "項目 1",
            "Item 2": "項目 2",
            "Item 3": "項目 3",
            "Your Account": "你的賬戶",
            "Admin": "系統管理",
            "Login": "登入",
            "Google": "谷歌",
            "Facebook": "面書",
            "Google Login": "谷歌登入",
            "Facebook Login": "面書登入",
            "Login with Google": "用谷歌賬戶登入",
            "Login with Facebook": "用面書賬戶登入",
            "Logout": "登出",
            "English": "英文",
            "Chinese": "中文",
            "Tailored for HK Parents": "為香港家長度身訂造",
            "Why you need Test Paper Online?": "您為什麼需要網上試卷平台?"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("languageCode") || "en",
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;