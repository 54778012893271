import React from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

/**
 * Application
 */
import { SigninSection, ThumbnailSection, SideBySideSection, SubscriptionSection } from '../../common/components/Sections';
import { INTRO_BLOCKS } from '../Constants';

export function Home(props) {

	const { t, account } = props;

	const classes = makeStyles(theme => ({
		root: {
			width: "100%",
			color: "rgb(44,62,80)",
			fontFamily: "Calibri",
			textAlign: "center"
		},
	}))();

	return (
		<Box className={classes.root}>
			{!Boolean(account) ? <SigninSection t={t} /> : null}
			<SideBySideSection t={t}
				title="Create your test paper hassle free"
				text={[
					"Being parents in Hong Kong would never be easy. With fierce competition starting at early childhood stage, it takes tremendous effort for parents to keep a fast pace, not to mention the stress ones need to stand with in hoping your kids to outperform.",
					"In retrospect, most of us have already put in lots of endeavour with the same goal to better nurture our kids. Unfortunately most of us would need to admit that we were not doing things effectively. Test Paper Online is the solution for you."]}
				imageUrl="/images/parents-and-kids-transparent.png" />
			<ThumbnailSection t={t}
				title={"Tailored for HK Parents"}
				subtitle={"Why you need Test Paper Online?"}
				blocks={INTRO_BLOCKS} />
			<SubscriptionSection t={t}
				title={"We are on pilot run"}
				subtitle={"Subscribe our newsletter"} />
			<Box textAlign="right" m={3}>
				<Typography>Version: {process.env.REACT_APP_BUILD_TIMESTAMP}</Typography>
			</Box>
		</Box >
	);
}