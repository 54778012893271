import React, { useState } from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, Typography, Button, FormLabel, IconButton, Icon, Tooltip, FormControlLabel, Switch } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionEditor } from './SectionEditor';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';

// const DELIMETER_REGEX = /[,.;!\u0021\u002C\u3002]{1}/gi;
export const DELIMETER_REGEX = /[,.;!?，。；！？：]{1}/gi;
const DEFAULT_SECTION_ATTRIBUTES = {
    type: "mulitple-choice",
    title: null,
    instruction: null
};
export function SentenceReorderingSectionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            marginBottom: theme.spacing(10)
        },
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        }
    }))();
    const { sectionIndex, updateSection, languageCode } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input", sentences: [] }] }, props.section);
    const updateThisSection = (sectionIndex, section) => {
        var originalSection = Object.assign({}, section, { isEditing: section.questions.filter(q => q.stage !== undefined).length > 0 });
        updateSection(sectionIndex, originalSection);
    }
    const addQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex + 1, 0, Object.assign({}, { id: uuid(), stage: "input", choices: [] }));
        updateThisSection(sectionIndex, originalSection);
    }
    const removeQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, originalSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, originalSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var originalSection = Object.assign({}, section);
        originalSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, originalSection);
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <SentenceReorderingQuestionEditor
                    className={classes.questionEditor}
                    question={question}
                    questionIndex={questionIndex}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    updateQuestion={updateQuestion}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode} />)} />
    );
}

/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Type in your paragraph.", nextStage: "arrange" },
    arrange: { hint: "Select the punctuation to breakup your paragraph into sentences.", previousStage: "input" }
};
export function SentenceReorderingQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex",
        },
        editorBox: {
            display: "flex",
            alignItems: "flex-start",
            width: "100%"
        },
        sentenceBox: {
            width: "100%",
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(1),
            marginBottom: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode } = props;
    const question = Object.assign({ id: uuid() }, props.question);
    const stage = question.stage;
    const [delimeters, setDelimeters] = useState([]);
    const [selectedDelimeters, setSelectedDelimeters] = useState([]);

    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const combineSentence = (sentenceIndex) => {
        let originalQuestion = Object.assign({}, question);
        let originalSentences = originalQuestion.sentences;
        originalSentences.splice(sentenceIndex - 1, 2, [originalSentences[sentenceIndex - 1], originalSentences[sentenceIndex]].join(""));
        updateQuestion(questionIndex, Object.assign({}, question, { sentences: originalSentences }));
    }
    const toggleHideTrailingSeparator = () => {
        let hideTrailingSeparator = !Boolean(question.hideTrailingSeparator);
        let updatedQuestion = Object.assign({}, question, { hideTrailingSeparator: hideTrailingSeparator });
        updateQuestion(questionIndex, updatedQuestion);
    }
    const splitParagraph = (nextStage) => {
        let originalQuestion = Object.assign({}, question);
        // Split string and keep delimeters
        // https://stackoverflow.com/questions/12001953/javascript-and-regex-split-string-and-keep-the-separator
        let regex = new RegExp(`[^${selectedDelimeters.join("")}]+[${selectedDelimeters.join("")}]?`, 'gi');
        originalQuestion.sentences = originalQuestion.sentences.join("").match(regex);
        updateQuestion(questionIndex, Object.assign({}, originalQuestion, { stage: nextStage }));
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            let originalQuestion = Object.assign({}, question);
            updateQuestion(questionIndex, Object.assign({}, originalQuestion, { stage: stage }));
        } else {
            complete(false);
        }
    }
    const parseDelimeter = (text) => {
        setDelimeters([...new Set(text.match(DELIMETER_REGEX))]);
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <Box className={classes.editorBox}>
                    <TextFieldWithSpeechRecognition
                        fullWidth
                        languageCode={languageCode}
                        variant="outlined"
                        multiline={true}
                        value={(question.sentences || []).join("")}
                        onChange={e => {
                            parseDelimeter(e.target.value);
                            updateQuestion(questionIndex, Object.assign({}, question, { sentences: [e.target.value] }));
                        }} />
                </Box>
            break;
        case "arrange":
            editor =
                question.sentences.map((s, sIndex) =>
                    <Box className={classes.editorBox} key={`sentence-${sIndex}`}>
                        <Typography key={`sentence-${sIndex}`} className={classes.sentenceBox}>{s}</Typography>
                        {sIndex === 0 ? <Box p={3} /> :
                            <Tooltip title="Merge with the previous sentence">
                                <IconButton>
                                    <Icon size="small" color="primary" onClick={() => combineSentence(sIndex)}>merge_type</Icon>
                                </IconButton>
                            </Tooltip>}
                    </Box>
                );
            break;
        default:
            editor =
                question.sentences.map((sentence, sIndex) =>
                    <Box key={`sentence-${sIndex}`} className={classes.editorBox} mb={3} pt={1}>
                        <Typography onClick={isEditingSection ? () => { } : () => setStage("arrange")}>
                            {
                                Boolean(question.hideTrailingSeparator) && Boolean(sentence.charAt(sentence.length - 1).match(DELIMETER_REGEX)) ?
                                    // Hide Trailing Separator
                                    sentence.slice(0, -1) :
                                    // Show Trailling Separator
                                    sentence
                            }
                        </Typography>
                    </Box>)
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Box p={1}>
                    <Typography component="span" className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    {editor}
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {/* Separate with checkboxes */}
                {stage === "input" && (delimeters.length > 0) ?
                    <Box style={{ display: "inline" }} m={1}>
                        <FormLabel color="primary">Separators: </FormLabel>
                        <ToggleButtonGroup value={selectedDelimeters}
                            onChange={(e, values) => setSelectedDelimeters(values)}
                            styles={{ height: 38 }}>
                            {delimeters.map((d, dIndex) =>
                                <ToggleButton key={dIndex} value={d} style={{ padding: "3 8", height: 38 }}>
                                    <Box px={0.5}>
                                        <Typography variant="subtitle2">{d}</Typography>
                                    </Box>
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box> : null}
                {/* End: Separate with checkboxes */}
                {/* Hide Trailing Separator Selector */}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) &&
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(question.hideTrailingSeparator)}
                                onChange={toggleHideTrailingSeparator} color="primary" />}
                        label={<Typography variant="button" color="primary">Hide Trailing Separator</Typography>} />
                }
                {/* End: Hide Trailing Separator Selector */}
                {/* Common Buttons */}
                {stage === "input" ? <Button className={classes.button} color="primary" onClick={() => splitParagraph(STAGES[stage].nextStage)}>Next</Button> : null}
                {stage === "arrange" ? <Button className={classes.button} color="primary" onClick={() => { parseDelimeter(question.sentences.join("")); setStage(STAGES[stage].previousStage); }}>Back</Button> : null}
                {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)} disabled={question.sentences.length === 0}>Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)} disabled={question.sentences.length === 0}>Next Question</Button>
                    </React.Fragment> :
                    null}
                {/* End: Common Buttons */}
            </Grid>
        </React.Fragment >
    );
}