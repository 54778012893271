import React from 'react';

/**
 * Slate
 */
import { Editor, Transforms } from 'slate';
import { ReactEditor, useEditor, useReadOnly } from 'slate-react';
// import { Button } from '../controls';

/**
 * Material UI
 */
import { useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { cx, css } from 'emotion';

/**
 * Get the media size of the screen
 */
function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

/**
 * Suggested view for this plugin
 */
export const VideoElement = (props) => {
    const { attributes, children, element } = props;
    const editor = useEditor()
    const readOnly = useReadOnly();
    const { url } = element
    const width = useWidth();
    const iFrameWidth = (width === "xs") ? 280 : 560;
    const iFrameHeight = (width === "xs") ? 158 : 315;
    return (
        <div {...attributes}>
            <div contentEditable={false}>
                <div style={{ maxWidth: iFrameWidth, maxHeight: iFrameHeight }}>
                    <iframe
                        width={iFrameWidth}
                        height={iFrameHeight}
                        src={url}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="allowfullscreen">
                    </iframe>
                </div>
                {!Boolean(readOnly) &&
                    <div style={{ maxWidth: iFrameWidth, maxHeight: iFrameHeight, textAlign: "right" }}>
                        <div style={{ color: "#FF0000", cursor: "pointer", fontSize: "0.8em" }}
                            onClick={e => {
                                const path = ReactEditor.findPath(editor, element);
                                Transforms.removeNodes(editor, { at: path });
                            }}>Remove</div>
                    </div>}
            </div>
            {children}
        </div>
    )
}

/**
 * Button to Insert Video
 */
export const InsertVideoButton = () => {
    const classes = makeStyles(theme => ({
        inactive: {
            color: theme.palette.grey[300],
        }
    }))();
    const editor = useEditor();
    const isVideoActive = () => {
        const [video] = Editor.nodes(editor, { match: n => n.type === 'video' })
        return !!video
    }
    return (
        <IconButton
            size='small'
            className={isVideoActive() ? null : classes.inactive}
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the video:')
                if (!url) return
                insertVideo(editor, url)
            }} >
            <YouTubeIcon />
        </IconButton>
    )
}

/**
 * Wrapper to override Editor from 'slate'
 */
export const withVideos = editor => {
    const { isVoid } = editor
    editor.isVoid = element => (element.type === 'video' ? true : isVoid(element))
    return editor
}

/**
 * Private
 */
const transformYoutubeUrl = (watchUrl) => {
    if (Boolean(watchUrl)) {
        let tokens = watchUrl.match(/https\:\/\/www\.youtube.com\/watch\?v\=([^\s]+)/);
        if (Boolean(tokens) && Boolean(tokens[1])) {
            return `https://www.youtube.com/embed/${tokens[1]}`;
        }
    }
    return watchUrl;
}
const insertVideo = (editor, url) => {
    const text = { text: '' }
    const image = { type: 'video', url: transformYoutubeUrl(url), children: [text] }
    Transforms.insertNodes(editor, image)
}

{/* <UrlInput
    url={url}
    onChange={val => {
        const path = ReactEditor.findPath(editor, element)
        Transforms.setNodes(editor, { url: val }, { at: path })
    }} /> */}

// const UrlInput = ({ url, onChange }) => {
//     const [value, setValue] = React.useState(url)
//     return (
//         <input
//             value={value}
//             onClick={e => e.stopPropagation()}
//             style={{
//                 marginTop: '5px',
//                 boxSizing: 'border-box',
//             }}
//             onChange={e => {
//                 const newUrl = e.target.value
//                 setValue(newUrl)
//                 onChange(newUrl)
//             }}
//         />
//     )
// }
