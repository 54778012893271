import React, { useState } from 'react';

/**
 * Material UI
 */
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

/**
 * Application
 */
import { deleteDocumentFromCollection } from '../../serviecs/firebase';
import { LoadingMask } from './LoadingMask';

/**
 * Generic Dialog Box to Confirm Deletion of Firebase Document
 * @param {Object} props 
 * Mandatory attributes
 * - open: govern whether this dialog should open or not
 * - handleClose: function to close this dialog
 * - confirmCallback: callback function upon confirmation
 * - title: Title of the dialog box
 * - contentText: Text to be display as content of the dialog box
 * - collectionPath: the path representing the collection
 * - documentId: the ID of the document
 */
export function DeleteDocumentFromFirebaseDialog(props) {
    const { open, handleClose, confirmCallback, title, contentText, collectionPath, documentId } = props;
    const [loadingMessage, setLoadingMessage] = useState();
    const handleConfirm = () => {
        deleteDocumentFromCollection(collectionPath, documentId, () => {
            setLoadingMessage(null);
            handleClose();
            if (Boolean(confirmCallback)) {
                confirmCallback();
            }
        });
    }
    return (
        <React.Fragment>
            {Boolean(loadingMessage) && <LoadingMask message={loadingMessage} />}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{contentText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm}>Confirm</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}