import React from "react";

/**
 * Material UI
 */
import { Box, Typography, Icon, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

export const DictionaryButton = (props) => {
    const classes = makeStyles(theme => ({
        dictionaryIcon: {
            cursor: 'pointer'
        },
        languageText: {
            position: 'relative',
            left: "-0.6rem",
            bottom: "-0.6rem",
        }
    }))();
    const { onClick, ...others } = props;
    return (
        <Tooltip title="Chinese dictionary">
            <Box display='flex' alignItems='center'>
                <IconButton onClick={onClick} size="small">
                    <Icon className={classes.dictionaryIcon}>language</Icon>
                </IconButton>
                <Typography
                    className={classes.languageText}
                    variant='caption'>中</Typography>
            </Box>
        </Tooltip>
    );
}

/**
 * Reference: fixed positioning
 * https://stackoverflow.com/questions/2005954/center-a-positionfixed-element
 */
export const DictionaryMask = (props) => {
    const { explanations = [], clearExplanations, removeExplanation } = props;
    const classes = makeStyles(theme => ({
        root: {
        },
        explanationBox: {
            textAlign: "center",
            backgroundColor: `${theme.palette.grey[100]}CC`,
            borderRadius: theme.spacing(1),
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
        },
        boldText: {
            fontWeight: 800
        },
        explanationText: {
            whiteSpace: "pre-wrap"
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(4)
        }
    }))();

    return (
        <Box className={classes.root}>
            {explanations.map((explanation, eIndex) =>
                <Box key={eIndex} className={classes.explanationBox} p={1} mb={1}>
                    <Box className={classes.closeButton}>
                        {/* Close all dialogs */}
                        {Boolean(eIndex === 0) &&
                            <Tooltip title="Close all">
                                <IconButton size="small" onClick={clearExplanations}>
                                    <Icon style={{ fontSize: "1rem" }}>cancel</Icon>
                                </IconButton>
                            </Tooltip>}
                        {/* End: Close all dialogs */}
                        {/* Close only this dialog */}
                        <Tooltip title="Close">
                            <IconButton size="small" onClick={() => removeExplanation(explanation?.word)}>
                                <Icon style={{ fontSize: "1rem" }}>remove_circle</Icon>
                            </IconButton>
                        </Tooltip>
                        {/* End: Close only this dialog */}
                    </Box>
                    <Typography className={classes.boldText}>{explanation?.word}</Typography>
                    <Typography className={classes.explanationText}>{explanation?.explanation}</Typography>
                </Box>
            )}
        </Box>
    );
}