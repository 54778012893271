import React from 'react';

/**
 * React Sppech Kit
 */
import { useSpeechSynthesis } from 'react-speech-kit';

/**
 * Material UI
 */
import { Icon, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const SpeakTestPanel = (props) => {
    const dummyText = "香港（英語：Hong Kong；縮寫：HK／HKG），全稱香港特別行政區[18]（英語：Hong Kong Special Administrative Region[19]；縮寫：HKSAR[20]），簡稱「港」，雅稱「香江」，是中華人民共和國的一個特別行政區，亦是粵港澳大灣區、大中華地區、亞太地區主要城市，以及全球城市[21]之一。香港地處南海北岸、珠江口東側，毗鄰廣東省，西隔珠江與澳門、珠海相望，北沿深圳河接深圳。位於中國大陸南端，地處西太平洋沿岸各國中心，又當太平洋和印度洋航運要衝[22]:37。香港全境由香港島、九龍和新界組成，地理上則由九龍半島等大陸土地，以及263個島嶼構成[7]。截至2021年年中，香港人口臨時數字由2020年年中的748萬人減至約739萬人[9]。";
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
    const speakLanguage = (text, languageCodes) => {
        let voice = voices.filter(v => languageCodes.includes(v.lang))[0];
        speak({ text: text, voice: voices.filter(v => languageCodes.includes(v.lang))[0] })
    }
    const classes = makeStyles(theme => ({
        clickable: {
            cursor: 'pointer'
        },
    }))();

    return (
        <Box mt={10}>
            <Typography variant='body2'>{dummyText}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Voice</TableCell>
                        <TableCell align="center">Lang</TableCell>
                        <TableCell align="center">Local Service</TableCell>
                        <TableCell align="center">Default</TableCell>
                        <TableCell align="center">Voice URL</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {voices.map(voice =>
                        <TableRow style={{ width: "100%" }}>
                            <TableCell align="center">
                                <Box display='flex' alignItems='center'>
                                    <Icon className={classes.clickable} onClick={() => speakLanguage(dummyText, voice.lang)}>mic</Icon>
                                    <Icon className={classes.speakIcon} onClick={cancel}>stop_circle</Icon>
                                    <Box ml={1}>
                                        <Typography variant='caption'>{voice.name}</Typography>
                                    </Box>
                                </Box>

                            </TableCell>
                            <TableCell align="center">{voice.lang}</TableCell>
                            <TableCell align="center">{voice.localService ? 'Y' : 'N'}</TableCell>
                            <TableCell align="center">{voice.default ? 'Y' : 'N'}</TableCell>
                            <TableCell align="center">{voice.voiceURL}</TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </Box >
    );
}