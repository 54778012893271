import React, { useEffect, useState, useContext } from 'react';

/**
 * React Router
 */
import { useParams } from 'react-router-dom';

/**
 * Material UI
 */
import { Container, Grid, Menu, MenuItem, Typography, Button, Dialog, ListItem, TextField, List, ListItemText, DialogActions, IconButton, Icon, withStyles, Box, InputAdornment, Checkbox, ListItemIcon, ListItemSecondaryAction, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MUIDataTable from 'mui-datatables';

/**
 * Firebase
 */
import firebase, { firestore, getFromFirestoreCollectionById, getFirestoreTimestamp } from '../../../common/serviecs/firebase';
import { LoadingMask } from '../../../common/components/controls/LoadingMask';

/**
 * Application
 */
import { UserContext } from '../../../App';
import { PaperTable } from '../papers/PaperTable';

const COLUMNS = [
    { name: "id", label: "ID", options: { filter: false, sort: false } },
    { name: "title", label: "Title", options: { filter: false, sort: false } },
    { name: "subject", label: "Subject", options: { filter: false, sort: false } },
];

export function Collection(props) {
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const [collection, setCollection] = useState();

    const loadCollection = () => {
        firestore.collection(`/users/${user.id}/collections`).doc(id).get().then(collectionRef => {
            let retrievedCollection = Object.assign({ id: collectionRef.id }, collectionRef.data());
            let paperIds = collectionRef.data().items.map(i => i.paperId);
            getFromFirestoreCollectionById("test-papers", paperIds).then(retrievedPapers => {
                retrievedCollection.papers = retrievedPapers;
                setCollection(retrievedCollection);
            });
        });
    }

    useEffect(() => {
        if (Boolean(id)) {
            loadCollection();
        }
    }, [id])
    return (
        Boolean(collection) &&
        <PaperTable title={collection.title} papers={collection.papers} />
    );
}

/**
 * Dialog Title with Close Button
 * Required props
 * 1. onClose
 */
function DialogTitle(props) {
    const { children, onClose, ...other } = props;
    const classes = makeStyles(theme => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    }))()
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Box mr={5}>
                <Typography variant="h6">{children}</Typography>
            </Box>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icon>close</Icon>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

/**
 * Dialog box to select collection to be added into
 * 
 * 1. handleClose
 */
const collectionDialogStyle = makeStyles(theme => ({
    listItem: {
        marginTop: 0,
        marginBottom: 0
    }
}));
export function AddToCollectionDialog(props) {
    const classes = collectionDialogStyle();
    const { user, paper, open, handleClose } = props;
    const [collections, setCollections] = useState();
    const [newCollection, setNewCollection] = useState();
    let newCollectionNameAlreadyExist = Boolean(collections) && Boolean(newCollection) && (collections.filter(c => c.title === newCollection.title).length > 0);

    /**
     * Create new collection with a selected paper as the only item
     */
    const addToCollection = (collectionId, paper) => {
        firestore.collection(`/users/${user.id}/collections`).doc(collectionId).get().then(collectionRef => {
            if (collectionRef.exists) {
                // Append the paper at the bottom to existing collection
                let collection = collectionRef.data();
                let items = collection.items;
                items.push({
                    displayName: `${paper.grade} - ${paper.subject} - ${paper.title}`,
                    paperId: paper.id
                });
                firestore.collection(`/users/${user.id}/collections`).doc(collectionId).
                    set(Object.assign({}, collection, { items: items })).then(() => {
                        setCollections(null);
                        retrieveCollections(user);
                    });
            }
        });
    }
    const addToNewCollection = (collection, paper) => {
        firestore.collection(`/users/${user.id}/collections`).add({
            title: collection.title,
            description: collection.description,
            createdAt: getFirestoreTimestamp(),
            items: [
                {
                    displayName: `${paper.grade} - ${paper.subject} - ${paper.title}`,
                    paperId: paper.id
                }
            ]
        }).then(() => {
            setCollections(null);
            retrieveCollections(user);
        });
    }
    const removeFromCollection = (collectionId, paper) => {
        firestore.collection(`/users/${user.id}/collections`).doc(collectionId).get().then(collectionRef => {
            if (collectionRef.exists) {
                // Append the paper at the bottom to existing collection
                let collection = collectionRef.data();
                let items = collection.items;
                items = items.filter(i => i.paperId !== paper.id);
                firestore.collection(`/users/${user.id}/collections`).doc(collectionId).
                    set(Object.assign({}, collection, { items: items })).then(() => {
                        setCollections(null);
                        retrieveCollections(user);
                    });
            }
        });
    }

    const retrieveCollections = (user) => {
        /**
         * Retrieve all collections for a user
         */
        firestore.collection(`/users/${user.id}/collections`).orderBy("title", "asc").get().then(collectionsRef => {
            let retrievedCollections = [];
            collectionsRef.forEach(collectionRef => {
                retrievedCollections.push(Object.assign({ id: collectionRef.id }, collectionRef.data()));
            })
            setCollections(retrievedCollections);
        });
    }

    useEffect(() => {
        retrieveCollections(user);
    }, [user]);

    return (
        <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>Add to Collection</DialogTitle>
            {Boolean(collections) ?
                <DialogContent style={{ scrollable: "auto" }}>
                    {/* Textbox to Create New Collection */}
                    <Box display="flex" alignItems="flex-start" mt={2}>
                        <Box flexGrow={10}>
                            <TextField fullWidth
                                size="small"
                                placeholder="Add to a new collection"
                                value={Boolean(newCollection) ? newCollection.title : null}
                                onChange={e => setNewCollection(Object.assign({}, newCollection, { title: e.target.value }))}
                                error={newCollectionNameAlreadyExist}
                                helperText={newCollectionNameAlreadyExist ? "Collection already exists" : null} />
                            {Boolean(newCollection) && Boolean(newCollection.title) &&
                                <Box mt={2}>
                                    <TextField fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        multiline={true}
                                        rows={3}
                                        label="Description"
                                        placeholder="Input some description of your collection (optional)"
                                        value={Boolean(newCollection) ? newCollection.description : null}
                                        onChange={e => setNewCollection(Object.assign({}, newCollection, { description: e.target.value }))} />
                                </Box>
                            }
                        </Box>
                        <Box>
                            <IconButton size="small"
                                disabled={!Boolean(newCollection)}
                                color={"primary"}
                                onClick={(e) => {
                                    addToNewCollection(newCollection, paper);
                                    setCollections(null);
                                    retrieveCollections(user);
                                    setNewCollection(null);
                                    //handleClose();
                                }}>
                                <Icon>add_circle</Icon>
                            </IconButton>
                        </Box>
                    </Box>
                    {/* End: Textbox to Create New Collection */}
                    <List>
                        {/* Existing Collections */}
                        {collections.length > 0 ?
                            collections.map((collection, cIndex) => {
                                let alreadyInCollection = collection.items.filter(i => i.paperId === paper.id).length > 0;
                                let handleClick = () => {
                                    alreadyInCollection ? removeFromCollection(collection.id, paper) : addToCollection(collection.id, paper);
                                    //handleClose();
                                }
                                return (
                                    <ListItem className={classes.listItem} key={cIndex}>
                                        <ListItemText>{collection.title}</ListItemText>
                                        <ListItemSecondaryAction>
                                            <Checkbox checked={alreadyInCollection} color="primary" size="small" onClick={handleClick} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }) :
                            <Box textAlign="center" my={3}>
                                <Typography variant="body1">No collection created yet</Typography>
                            </Box>
                        }
                        {/* End: Existing Collections */}
                    </List>
                </DialogContent> :
                <LoadingMask message="Loading collections" />
            }
            <DialogActions>
                <Button onClick={handleClose}>{Boolean(newCollection) && Boolean(newCollection.title) ? "Cancel" : "Done"}</Button>
            </DialogActions>
        </Dialog>
    )
}