/**
 * Firebase SDK
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

/**
 * Auto config for firebase
 */
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_FIREBASE_CLOUD_STORAGE_BUCKET,
});

/**
 * Firebase Authentication Example
 * https://github.com/firebase/quickstart-js/blob/master/auth/google-redirect.html
 */
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const loginWithGoogle = () => {
    googleProvider.addScope('https://www.googleapis.com/auth/plus.login');
    /**
     * https://firebase.google.com/docs/auth/web/redirect-best-practices
     * signInWithRedirect not longer works suddenly. Found 3Oct2023
     */
    //firebase.auth().signInWithRedirect(googleProvider);
    firebase.auth().signInWithPopup(googleProvider);
}

export const loginWithFacebook = () => {
    firebase.auth().signInWithRedirect(facebookProvider)
}

export const logout = () => {
    firebase.auth().signOut();
}

export const getFirestoreTimestamp = () => {
    return firebase.firestore.Timestamp.now();
}

export const addHoursToFirestoreTimestamp = (timestamp, hours) => {
    return firebase.firestore.Timestamp.fromMillis(1000 * (timestamp.seconds + (hours * 3600)));
}

/**
 * Return the firebase firestore object
 */
export const firestore = firebase.firestore();

/**
 * As firestore only supprt batch retrieval by IDs up to a batch size of 10.
 * This method aggregate retrieval up to any number of IDs
 * return: Purpose
 */
const MAX_DOCUMENTS_PER_QUERY = 10;
export const getFromFirestoreCollectionById = (collectionPath, documentIds) => {
    let retrievedDocuments = [];
    retrievedDocuments.length = documentIds.length;
    let retrievalPromises = [];
    for (let batchIndex = 0; (batchIndex * MAX_DOCUMENTS_PER_QUERY) < documentIds.length; batchIndex++) {
        console.log(`Procssing batch ${batchIndex}...`)
        let paperIdsForThisBatch = documentIds.slice(batchIndex * MAX_DOCUMENTS_PER_QUERY, (batchIndex + 1) * MAX_DOCUMENTS_PER_QUERY);
        retrievalPromises.push(firestore.collection(collectionPath).where(firebase.firestore.FieldPath.documentId(), "in", paperIdsForThisBatch).get())
    }
    return Promise.all(retrievalPromises).then(documentsRefs => {
        documentsRefs.forEach(documentsRef => {
            documentsRef.forEach(documentRef => {
                let documentId = documentRef.id;
                let documentIndex = documentIds.indexOf(documentId);
                if (documentIndex > -1) {
                    retrievedDocuments.splice(documentIndex, 1, Object.assign({ id: documentRef.id }, documentRef.data()));
                }
            })
        });
        return retrievedDocuments.filter(d => Boolean(d));
    })
}

/**
 * Generic method to delete a document from firestore
 * 
 * @param {String} collectionPath 
 * @param {String} documentId 
 * @param {function} callback 
 */
export const deleteDocumentFromCollection = (collectionPath, documentId, callback) => {
    firestore.collection(collectionPath).doc(documentId).delete().then(docRef => {
        callback();
    });
}

/**
 * Return the firebase cloud storage object
 */
export const storage = firebase.storage();

export default firebase;


