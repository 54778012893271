import React, { useState } from 'react';

/**
 * Material UI
 */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Grid, Button, Icon, IconButton } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

/**
 * Firebase
 */
import { loginWithGoogle, loginWithFacebook } from '../../common/serviecs/firebase';

/**
 * Application
 */
import { REGEX_EMAIL } from '../Constants';
import { GoogleLoginButton } from './controls';

export function SigninSection(props) {
    const { t } = props;
    const classes = makeStyles(theme => ({
        root: {
            width: "100%",
            color: "rgb(44,62,80)",
            fontFamily: "Calibri",
            textAlign: "center"
        },
        loginBox: {
            textAlign: "center",
        },
        googleLoginButton: {
            width: 230,
            textTransform: "uppercase",
            textAlign: "justify",
            backgroundColor: "rgb(66, 133, 244)",
            color: "white"
        },
        facebookLoginButton: {
            width: 230,
            backgroundColor: "#4c69ba",
            color: "white"
        }
    }))();
    return (
        <Container maxWidth="lg">
            <Box displayPrint="none" py={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <GoogleLoginButton />
                        </Box>
                        {/* Disabled for now */}
                        {false &&
                            <Box my={2}>
                                <Button className={classes.facebookLoginButton}
                                    variant="contained"
                                    onClick={loginWithFacebook}
                                    startIcon={<FacebookIcon />}>{t("Login with Facebook")}</Button>
                            </Box>}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

/**
 * Thumbnail Section
 * 
 * Take an array each with the below attributes
 * 1. title
 * 2. text
 * 3. imageUrl
 */
export function ThumbnailSection(props) {
    const { t, title, subtitle, blocks } = props;
    const classes = makeStyles(theme => ({
        root: {
            width: "100%",
            color: "rgb(44,62,80)",
            fontFamily: "Calibri",
            textAlign: "center",
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(1),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 160,
                height: 4,
                content: `''`,
                transform: "matrix(1,0,0,1,-80,0)"
            }
        },
        sectionImage: {
            height: theme.spacing(20),
            width: theme.spacing(20),
            borderRadius: "50%",
            border: "3px solid #ebebeb",
            boxShadow: "0 3px 2px rgba(0, 0, 0, 0.1)",
        }
    }))();

    return (
        <Container maxWidth="lg">
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box my={3}>
                        <Typography variant="h4" className={classes.titleLine}>{t(title)}</Typography>
                        <Box my={1}>
                            <Typography variant="h6" style={{ fontWeight: 800 }}>{t(subtitle)}</Typography>
                        </Box>
                    </Box>
                </Grid>
                {blocks.map((block, bIndex) =>
                    <Grid key={`section=${bIndex}`} item xs={12} sm={6} md={6}>
                        <Box m={2}>
                            <img className={classes.sectionImage} alt="Default user" src={block.imageUrl} />
                        </Box>
                        <Box m={1}>
                            <Typography variant="h6" style={{ fontWeight: 800 }}>{t(block.title)}</Typography>
                        </Box>
                        <Box m={1} display="flex" justifyContent="Center">
                            <Box maxWidth={300}>
                                <Typography variant="body1">{t(block.text)}</Typography>
                            </Box>
                        </Box>
                    </Grid>)}
            </Grid>
        </Container>
    );
}

/**
 * Side by Side Section
 */
export function SideBySideSection(props) {
    const { t, title, text, imageUrl, imageAlt } = props;
    const classes = makeStyles(theme => ({
        root: {
            backgroundColor: "rgb(44,62,80)",
            color: "white"
        },
        contentGrid: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: theme.spacing(1),
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: Math.min((title || "").length - 6, 10) * theme.typography.fontSize,
                height: 4,
                content: `''`,
                transform: `matrix(1,0,0,1,-${Math.min((title || "").length - 6, 10) * theme.typography.fontSize / 2},0)`
            }
        },
    }))();
    let texts = text || ["This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section."];
    return (
        <Grid className={classes.root} container spacing={0}>
            <Grid item xs={12} md={6}>
                <img alt={imageAlt || "Beautiful scenery"} src={imageUrl || "/images/beautiful-scenery-mono.jpg"} style={{ width: "100%" }} />
            </Grid>
            <Grid className={classes.contentGrid} item xs={12} md={6}>
                <Box>
                    <Box m={3}>
                        <Typography variant="h4" className={classes.titleLine}>{t(title)}</Typography>
                    </Box>
                    {texts.map(t =>
                        <Box m={3}>
                            <Typography variant="body1"> {t}</Typography>
                        </Box>)}
                </Box>
            </Grid>
        </Grid>
    );
}

export function TextOnImageSection(props) {
    const items = [
        { url: "/images/beautiful-scenery-colour.jpg", title: "Long Title Line", text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section." },
        { url: "/images/resouvior.jpg", title: "Long Title Line", text: "This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section. This is the body of the section." },
    ]

    const { t } = props;
    const classes = makeStyles(theme => ({
        root: {
            backgroundColor: "rgb(44,62,80)",
            color: "white"
        },
        contentGrid: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //backgroundImage: "url(/images/beautiful-scenery-colour.jpg)",
            backgroundSize: "100% 100%"
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: 100,
                height: 4,
                content: `''`,
                transform: "matrix(1,0,0,1,-50,0)"
            }
        },
    }))();
    return (
        <Grid className={classes.root} container spacing={0}>
            {items.map((i, itemIndex) =>
                <Grid key={`section-${itemIndex}`} className={classes.contentGrid} style={{ backgroundImage: `url(${i.url})` }} item xs={12} md={6}>
                    <Box>
                        <Box m={3}>
                            <Typography variant="h4" className={classes.titleLine}>{t(i.title)}</Typography>
                        </Box>
                        <Box m={3}>
                            <Typography variant="body1">{i.text}</Typography>
                        </Box>
                    </Box>
                </Grid>)}
        </Grid>
    );
}

export function SubscriptionSection(props) {
    const { t, title, subtitle } = props;
    const [errors, setErrors] = useState(Object.assign([], []));
    const classes = makeStyles(theme => ({
        root: {
            color: "rgb(44,62,80)",
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: theme.spacing(1),
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: Math.min((title || "").length - 6, 10) * theme.typography.fontSize,
                height: 4,
                content: `''`,
                transform: `matrix(1,0,0,1,-${Math.min((title || "").length - 6, 10) * theme.typography.fontSize / 2},0)`
            }
        },
        subTitleLine: {
            textAlign: "center",
            fontWeight: 800,
            paddingBottom: theme.spacing(2),
        },
    }))();

    const validate = (key, value, regex) => {
        let originalErrors = Object.assign([], errors);
        if (Boolean(value) && value.search(regex) !== -1) {
            originalErrors = originalErrors.filter(e => e !== key);
        } else if (!originalErrors.includes(key)) {
            originalErrors.push(key);
        }
        setErrors(originalErrors);
    }

    return (
        <Container maxWidth="lg">
            <Box py={10}>
                <Grid className={classes.root} container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography component="div" variant="h4" className={classes.titleLine}>{t(title)}</Typography>
                        </Box>
                        <Box>
                            <Typography component="div" variant="body1" className={classes.subTitleLine}>{t(subtitle)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.contentGrid} xs={12} md={9}>
                        <Box stylea={{ width: "95%" }}>
                            <TextField variant="outlined" fullWidth
                                size="small" label="Email"
                                error={errors.includes("email")}
                                onKeyUp={(e) => validate("email", e.target.value, REGEX_EMAIL)}
                                helperText={errors.includes("email") ? "Please enter a valid email address!" : "Enter your email address to subscribe for our newsletter."} />
                        </Box>
                    </Grid>
                    <Grid item className={classes.contentGrid} xs={12} md={3}>
                        <Box stylea={{ width: "95%" }}>
                            <Button variant="contained" color="primary" fullWidth size="large">Subscribe</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export function PrintableSection(props) {
    const { t, title, sections, numberOfColumns, isPrintable } = props;
    const classes = makeStyles(theme => ({
        root: {
            color: "rgb(44,62,80)",
            "@media print": {
                "&@page": {
                    size: "A4",
                    margin: 0,
                },
                "&html, body": {
                    width: "210mm",
                    height: "282mm",
                    "font - size": "11px",
                    "background": "#FFF",
                    overflow: "visible",
                },
                body: {
                    "padding - top": "15mm"
                }
            }
        },
        titleLine: {
            textAlign: "center",
            width: "auto",
            fontWeight: 800,
            display: "block",
            position: "relative",
            paddingBottom: theme.spacing(2),
            '&::after': {
                position: "absolute",
                bottom: 0,
                left: "50%",
                borderBottom: "rgb(204,0,0) solid 4px",
                width: Math.max((title || "").length - 10, 6) * theme.typography.fontSize * 2,
                height: 4,
                content: `''`,
                transform: `matrix(1,0,0,1,-${Math.min((title || "").length - 10, 10) * theme.typography.fontSize},0)`
            }
        },
        subTitleLine: {
            textAlign: "center",
            fontWeight: 800,
            paddingBottom: theme.spacing(2),
        },
        contentText: {
            textAlign: "center",
            breakInside: "avoid",
            pageBreakInside: "avoid",
            '@media print': {
                breakInside: "avoid",
                pageBreakInside: "avoid",
            }
        }
    }))();
    return (
        <Container maxWidth="lg">
            <Grid className={classes.root} container spacing={2}>
                {Boolean(isPrintable) &&
                    <Grid item xs={12}>
                        <Box textAlign="right" displayPrint="none">
                            <IconButton onClick={() => { window.print(); return false; }}>
                                <Icon>print</Icon>
                            </IconButton>
                        </Box>
                    </Grid>}
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h4" className={classes.titleLine}>{t(title)}</Typography>
                    </Box>
                </Grid>
                {sections.map((section, sectionIndex) =>
                    <Grid key={`section-${sectionIndex}`} item xs={12} sm={12 / (numberOfColumns || 2)}>
                        <Box m={2}>
                            <Typography variant="h5" className={classes.subTitleLine}>{t(section.title)}</Typography>
                        </Box>
                        <Box m={2}>
                            <div className={classes.contentText} style={{ pageBreakInside: "avoid" }}>
                                <Typography variant="body1">{t(section.text)}</Typography>
                            </div>
                        </Box>
                    </Grid>)}
            </Grid>
        </Container>
    );
}
