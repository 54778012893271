import React from "react";

/**
 * Material UI
 */
import { Box, Typography, Icon, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

export const SpeakButton = (props) => {
    const classes = makeStyles(theme => ({
        speakIcon: {
            cursor: 'pointer'
        },
        languageText: {
            position: 'relative',
            left: "-0.6rem",
            bottom: "-0.6rem",
        }
    }))();
    const { onClick, languageText, languageName, ...others } = props;
    return (
        <Tooltip title={`Speak in ${languageName}`}>
            <Box display='flex' alignItems='center'>
                <IconButton onClick={onClick} size="small">
                    <Icon className={classes.speakIcon}>record_voice_over</Icon>
                </IconButton>
                <Typography
                    className={classes.languageText}
                    variant='caption'>{languageText}</Typography>
            </Box >
        </Tooltip>
    );
}

export const StopButton = (props) => {
    const classes = makeStyles(theme => ({
        speakIcon: {
            cursor: 'pointer'
        },
        languageText: {
            position: 'relative',
            bottom: "0.75rem",
        }
    }))();
    const { onClick, ...others } = props;
    return (
        <Tooltip title="Stop speaking">
            <Box display='flex' alignItems='center' flexDirection="column">
                <IconButton onClick={onClick} size="small">
                    <Icon className={classes.speakIcon}>stop</Icon>
                </IconButton>
                <Typography
                    className={classes.languageText}
                    variant='caption'>Stop</Typography>
            </Box>
        </Tooltip>
    );
}
