import React, { useState, useEffect } from 'react';

/**
 * Material UI
 */
import { Container, Grid, Button, useMediaQuery, Box } from '@material-ui/core';
import MUIDataTable from "mui-datatables";

/**
 * Firebase
 */
import { firestore } from '../../../common/serviecs/firebase';
import { LoadingMask } from '../../../common/components/controls/LoadingMask';

/**
 * Application
 */
import { formatTimestamp, formatDate } from '../../../common/functions/DateTimeUtils';

/**
 * Constant
 */
export const PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX = "assignment-inbox";
export const PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_OUTBOX = "assignment-outbox";
export const PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX = "result-inbox";
export const PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX = "result-outbox";
const PAPER_ATTEMPT_TABLE_CONFIG = {
    [PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX]: {
        title: "Your Assignments",
        columns: {
            "sm": ["headline", "assignedByStatus", "dateDueStatus", "action"],
            "md": ["grade", "subject", "combinedTitle", "title", "subtitle", "fullScore", "assignedBy.fullName", "assignedAt", "dateDue", "action"],
        },
    },
    [PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_OUTBOX]: {
        title: "Assignment Outbox",
        columns: {
            "sm": ["headline", "assignedToStatus", "dateDueStatus", "action"],
            "md": ["grade", "subject", "combinedTitle", "title", "subtitle", "fullScore", "attemptedBy.fullName", "assignedAt", "dateDue", "action"],
        },
    },
    [PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX]: {
        title: "Your Results",
        columns: {
            "sm": ["headline", "completionStatus", "action"],
            "md": ["grade", "subject", "combinedTitle", "title", "subtitle", "score", "attemptedBy.fullName", "completedAt", "action"],
        },
    },
    [PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX]: {
        title: "Result Inbox",
        columns: {
            "sm": ["headline", "completionStatus", "action"],
            "md": ["grade", "subject", "combinedTitle", "title", "subtitle", "score", "attemptedBy.fullName", "completedAt", "action"],
        },
    },
}

const FILTER_COLUMNS = ["grade", "subject", "title", "subtitle", "assignedBy.fullName", "attemptedBy.fullName"];

const ALL_COLUMNS = {
    // "id": { label: "ID", options: { display: false } },  // Don't remove this column
    "headline": { label: "Headline", options: { setCellProps: () => ({ style: { fontSize: "1rem", fontWeight: 800 } }) } },
    "completionStatus": { label: "Completion Status" },
    "assignedToStatus": { label: "Assigned To Status" },
    "assignedByStatus": { label: "Assigned By Status" },
    "grade": { label: "Grade", options: { setCellHeaderProps: () => ({ style: { width: "100px" } }) } },
    "subject": { label: "Subject" },
    "combinedTitle": { label: "Title", options: { setCellHeaderProps: () => ({ style: { width: "38%" } }) } },
    "title": { label: "Title", options: { display: false } },
    "subtitle": { label: "Sub Title", options: { display: false } },
    "score": { label: "Score" },
    "fullScore": { label: "Full Score" },
    "assignedBy.fullName": { label: "Assigned By" },
    "attemptedBy.fullName": { label: "Assigned To" },
    "dateDue": { label: "Date Due" },
    "dateDueStatus": { label: "Date Due Status" },
    "timeLimit": { label: "Time Allowed" },
    "assignedAt": { label: "Assigned At" },
    "completedAt": { label: "Completed At", viewSpecific: true },
    "action": { label: " ", options: { sort: false, filter: false, setCellProps: () => ({ style: { textAlign: "right" } }) } },
};


/**
 * The below views are supported. Each view correspond to a custom firestore query and a set of return columns
 * 1. assignment-inbox - attempts assigned to you but not yet completed
 * 2. result-inbox - attempts assigned to you and have been completed
 * 3. assignment-outbox - assignments that you have sent out but yet completed
 * 4. result-outbox - assignments that you have sent out and completed
 */
export function PaperAttemptTable(props) {
    const { user, view } = props;
    const [paperAttempts, setPaperAttempts] = useState(null);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    useEffect(() => {
        let retrievedPaperAttempts = [];
        let collectionRef = firestore.collectionGroup("paper-attempts");
        let queryPromise = null;
        switch (view) {
            case PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_INBOX:
                queryPromise = collectionRef.where("attemptedBy.id", "==", user.id).where("completedAt", "==", null).orderBy("assignedAt", "desc");
                break;
            case PAPER_ATTEMPT_TABLE_VIEW_ASSIGNMENT_OUTBOX:
                queryPromise = collectionRef.where("assignedBy.id", "==", user.id).where("completedAt", "==", null).orderBy("assignedAt", "desc");
                break;
            case PAPER_ATTEMPT_TABLE_VIEW_RESULT_INBOX:
                queryPromise = collectionRef.where("attemptedBy.id", "==", user.id).orderBy("completedAt", "desc");
                break;
            case PAPER_ATTEMPT_TABLE_VIEW_RESULT_OUTBOX:
                queryPromise = collectionRef.where("assignedBy.id", "==", user.id).orderBy("completedAt", "desc");
                break;
            default:
                break;
        }
        queryPromise.get().then(query => {
            query.forEach(doc => {
                retrievedPaperAttempts.push(
                    transformPaperAttempt(
                        Object.assign({}, doc.data(), { id: doc.id, paperId: doc.ref.parent.parent.id }), props));
            })
            setPaperAttempts(retrievedPaperAttempts);
        });
    }, [user, view]);

    let viewColumns = PAPER_ATTEMPT_TABLE_CONFIG[view].columns[isSmallScreen ? "sm" : "md"];
    let columns = [...new Set([...viewColumns, ...FILTER_COLUMNS])].
        map(c => Object.assign(
            {}, ALL_COLUMNS[c],
            {
                name: c,
                options: Object.assign({}, ALL_COLUMNS[c]?.options, {
                    display: (ALL_COLUMNS[c]?.options?.display !== false) && viewColumns.includes(c),
                    viewColumns: viewColumns.includes(c),
                    filter: FILTER_COLUMNS.includes(c),
                    sort: FILTER_COLUMNS.includes(c),
                })
            }));

    return (
        Boolean(paperAttempts) ?
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={PAPER_ATTEMPT_TABLE_CONFIG[view].title}
                            data={paperAttempts}
                            columns={columns}
                            options={{
                                responsive: "stacked",
                                print: false,
                                download: false,
                                viewColumns: false,
                                rowHover: true,
                                selectableRows: "none",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container> : <LoadingMask message="Loading your test paper attempts" />
    );
}

function transformPaperAttempt(paperAttempt, props) {
    const overDueStyle = {
        color: "red",
        fontWeight: 800
    }
    const { goto } = props;
    const assignedBy = paperAttempt.assignedBy || {};
    const attemptedBy = paperAttempt.attemptedBy || {};
    const assignedAt = Boolean(paperAttempt.assignedAt) ? formatTimestamp(paperAttempt.assignedAt.toDate()) : "-";
    const completedAt = Boolean(paperAttempt.completedAt) ? formatTimestamp(paperAttempt.completedAt.toDate()) : "-";
    const isOverDue = (Boolean(attemptedBy.dateDue) && attemptedBy.dateDue.toDate() < new Date());
    const dateDue = Boolean(attemptedBy.dateDue) ? <Box component="span" style={isOverDue ? overDueStyle : null}>{formatDate(attemptedBy.dateDue.toDate())}</Box> : "-";
    const score = (paperAttempt.score !== undefined && paperAttempt.score !== null) ? `${Math.ceil(100 * paperAttempt.score / paperAttempt.fullScore)}%` : "-";
    return (
        Object.assign(
            {},
            paperAttempt,
            {
                headline: [[paperAttempt.grade, paperAttempt.subject].join(" "), paperAttempt.title, paperAttempt.subtitle].filter(t => Boolean(t)).join(" - "),
                combinedTitle: [paperAttempt.title, paperAttempt.subtitle].filter(t => Boolean(t)).join(" - "),
                completionStatus: `Completed by ${attemptedBy.fullName} at ${completedAt} with a score of ${score}`,
                assignedToStatus: `Assigned to ${attemptedBy.fullName} at ${assignedAt}`,
                assignedByStatus: `Assigned by ${assignedBy.fullName} at ${assignedAt}`,
                dateDueStatus: <Box style={isOverDue ? overDueStyle : null}>Date due on {dateDue}</Box>,
                action:
                    <Box>
                        {Boolean(paperAttempt.completedAt) ?
                            <React.Fragment>
                                <Button color="primary" size="small"
                                    onClick={() => goto(`/papers/${paperAttempt.paperId}/paper-attempts/${paperAttempt.id}/review`)}>Review</Button>
                                <Button color="primary" size="small"
                                    onClick={() => goto(`/papers/${paperAttempt.paperId}/paper-attempts/${paperAttempt.id}`)}>Retake</Button>
                            </React.Fragment> :
                            <Button color="primary" size="small"
                                onClick={() => goto(`/papers/${paperAttempt.paperId}/paper-attempts/${paperAttempt.id}`)}>Take</Button>}
                    </Box>,
                score: score,
                timeLimit: `${paperAttempt.timeLimitInMinutes} mins`,
                assignedAt: assignedAt,
                completedAt: completedAt,
                dateDue: dateDue,
            },
        )
    );
}