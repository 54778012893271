/**
 * React
 */
import React, { useState, useRef } from 'react';

/**
 * Material UI
 */
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Icon, Button, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

/**
 * Cropper JS
 */
import Cropper from 'cropperjs';

/**
 * Application
 */
import { storage } from '../../serviecs/firebase';

/**
 * Constants
 */
const STAGE_SELECT = "select";
const STAGE_CROP = "crop";
const STAGE_EDIT = "edit";
const CANVAS_MARKERS = [
    { type: "stroke", border: { width: 1, style: "dotted", color: "#0000FF" }, coordinates: [0, 0, 30, 100] },
    { type: "fillRect", color: "#00FF00", coordinates: [80, 180, 30, 100] },
    { type: "listMarker", coordinates: [50, 20], text: "This is a text marker" },
]
export function ImageEditorDialog(props) {
    const { handleClose, handleConfirm, imageDataUrl, cropperOptions, user } = props;
    const [stage, setStage] = useState(STAGE_CROP);
    const [cropper, setCropper] = useState();
    const [croppedImageDataUrl, setCroppedImageDataUrl] = useState();
    const [canvasMarkers, setCanvasMarkers] = useState(CANVAS_MARKERS);
    const [currentMarker, setCurrentMarker] = useState();
    const imageRef = useRef();
    const canvasRef = useRef();
    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fullScreen = true;

    const loadImage = (fileBlob) => {
        let fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            let dataUrl = fileReader.result;
            imageRef.current.src = dataUrl;
        });
        fileReader.readAsDataURL(fileBlob);
    }

    const initializeCropper = () => {
        console.log("**initializeCropper...")
        let cropper = new Cropper(
            imageRef.current,
            Object.assign({
                viewMode: 2,
                autoCropArea: 1
            }, cropperOptions));
        setCropper(cropper);
    }

    const confirmImage = () => {
        // let dataUrl = cropper.getCroppedCanvas({
        //     width: 512,
        //     height: 512
        // }).toDataURL();

        cropper.getCroppedCanvas({
            maxWidth: 512,
            maxHeight: 512
        }).toBlob(imageBlob => {
            storage.ref().child(`${user.id}/images/profile-picture.jpg`).put(imageBlob).then(snapshot => {
                snapshot.ref.getDownloadURL().then(url => {
                    handleConfirm(url);
                    handleClose();
                });
            });
        })

        // setCroppedImageDataUrl(dataUrl);
        // let image = new Image();
        // image.src = dataUrl;
        // image.addEventListener("load", () => {
        //     let canvasContext = canvasRef.current.getContext("2d");
        //     canvasContext.drawImage(image, 0, 0);
        // });
    }

    const rotate = (degree) => {
        cropper.rotate(degree);
    }

    const loadImageOriginal = (fileBlob) => {
        let fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
            let dataUrl = fileReader.result;
            let image = new Image;
            // Get the canvas DOM element
            let canvasElement = document.getElementById("image-to-edit");
            let context = canvasElement.getContext("2d");
            image.onload = function () {
                context.drawImage(image, 0, 0, 110, 330, 10, 10, 110, 330);
                imageRef.current.addEventListener("mousedown", (e) => {
                    console.log({
                        clientLeft: imageRef.current.clientLeft,
                        clientTop: imageRef.current.clientTop,
                        clientWidth: imageRef.current.clientWidth,
                        clientHeight: imageRef.current.clientHeight,
                        offsetLeft: imageRef.current.offsetLeft,
                        offsetTop: imageRef.current.offsetTop,
                        offsetWidth: imageRef.current.offsetWidth,
                        offsetHeight: imageRef.current.offsetHeight,
                    });
                    console.log(e);
                });
            };
            image.src = dataUrl;
        })
        fileReader.readAsDataURL(fileBlob);
    }

    const handleMouseDown = (event) => {
        let containerTop = event.currentTarget.offsetTop;
        let containerLeft = event.currentTarget.offsetLeft;
        let cursorY = event.nativeEvent.y;  // event.clientY
        let cursorX = event.nativeEvent.x;  // event.clientX
        if (Boolean(currentMarker) && Boolean(currentMarker.type)) {
            setCurrentMarker(Object.assign({}, currentMarker, {
                coordinates: [cursorY - containerTop, cursorX - containerLeft, 0, 0]
            }));
        }
    }
    const handleMouseMove = (event) => {
        if (Boolean(currentMarker) && Boolean(currentMarker.coordinates)) {
            // let height = event.nativeEvent.offsetY - currentMarker.coordinates[0];
            // let width = event.nativeEvent.offsetX - currentMarker.coordinates[1];
            let height = currentMarker.coordinates[2] + event.nativeEvent.movementY;
            let width = currentMarker.coordinates[3] + event.nativeEvent.movementX;
            setCurrentMarker(Object.assign({}, currentMarker, {
                coordinates: [currentMarker.coordinates[0], currentMarker.coordinates[1], height, width],
            }));
        }
    }
    const handleMouseUp = (event) => {
        if (currentMarker) {
            setCanvasMarkers([...canvasMarkers, ...[currentMarker]]);
            setCurrentMarker(null);
        }
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                onClose={handleClose}>
                <DialogTitle>Insert and Edit Image</DialogTitle>
                <DialogContent>

                    {/* Image Cropper */}
                    {stage === STAGE_CROP &&
                        <div style={{ border: "1px dotted #0000FF" }}>
                            <img ref={imageRef} src={imageDataUrl} onLoad={initializeCropper} />
                        </div>}
                    {/* End: Image Cropper */}

                    {/* Preview */}
                    {stage === STAGE_SELECT &&
                        <div style={{ border: "1px dotted #0000FF" }}>
                            <img src={imageDataUrl} />
                        </div>}
                    {/* End: Preview */}

                    {/* Canvas */}
                    {stage === STAGE_EDIT &&
                        <div style={{ position: "relative", border: "1px dotted #0000FF", cursor: "crosshair", wdiths: "100%" }}
                            onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                            <canvas ref={canvasRef} style={{ cursor: "crosshair", background: "url('/logo512.png')", widths: "100%" }} />
                            {/* Markers for Preview */}
                            {[...canvasMarkers, ...[currentMarker]].filter(m => Boolean(m) && Boolean(m.coordinates)).map((marker, mIndex) => {
                                let typeSpecificStyles = {};
                                if (marker.type === "stroke") {
                                    typeSpecificStyles.border = `${marker.border.width}px ${marker.border.style} ${marker.border.color}`;
                                }
                                if (marker.type === "fillRect") {
                                    typeSpecificStyles.backgroundColor = marker.color;
                                }
                                return <div kay={mIndex}
                                    style={
                                        Object.assign({},
                                            typeSpecificStyles, {
                                            display: "block",
                                            position: "absolute",
                                            top: marker.coordinates[0],
                                            left: marker.coordinates[1],
                                            height: marker.coordinates[2],
                                            width: marker.coordinates[3],
                                        })}>{marker.text}</div>
                            })}
                            {/* End: Markers for Preview */}
                        </div>}
                    {/* End: Canvas */}

                    {/* Debug Messages */}
                    <div style={{ height: 30, width: 1000, whiteSpace: "pre", overflowWrap: "break-word" }}>{JSON.stringify(currentMarker, null, 3)}</div>
                    {/* Debug Messages */}
                    {/* Buttons */}
                    <input accept="image/*"
                        style={{ display: "none" }}
                        id="icon-button-file" type="file"
                        onChange={e => loadImage(e.target.files[0])} />
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" component="span">
                            <Icon>photo_camera</Icon>
                        </IconButton>
                    </label>
                    <Tooltip title="Rotate 90 degree clockwise">
                        <IconButton color="primary" onClick={() => rotate(90)}>
                            <Icon>rotate_right</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Rotate 90 degree anit-clockwise">
                        <IconButton color="primary" onClick={() => rotate(-90)}>
                            <Icon>rotate_left</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add rectangle">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "stroke", border: { width: 1, style: "solid", color: "#FF0000" } })}>
                            <Icon>crop_square</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add filled reatangle">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "fillRect", color: "#00FF00" })}>
                            <Icon>stop</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add text marker">
                        <IconButton color="primary" onClick={() => setCurrentMarker({ type: "textMarker" })}>
                            <Icon>text_fields</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Complete your edit">
                        <IconButton color="primary" onClick={confirmImage}>
                            <Icon>check_circle</Icon>
                        </IconButton>
                    </Tooltip>
                    {/* End: Buttons */}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={confirmImage} color="primary" autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export function resizeImage(user, file, max_width, max_height, compressionRatio, imageEncoding, callback) {
    var fileLoader = new FileReader(),
        canvas = document.createElement('canvas'),
        context = null,
        imageObj = new Image(),
        blob = null;

    //create a hidden canvas object we can use to create the new resized image data
    canvas.id = "hiddenCanvas";
    canvas.width = max_width;
    canvas.height = max_height;
    canvas.style.visibility = "hidden";
    document.body.appendChild(canvas);

    //get the context to use 
    context = canvas.getContext('2d');

    // check for an image then
    //trigger the file loader to get the data from the image         
    if (file.type.match('image.*')) {
        fileLoader.readAsDataURL(file);
    } else {
        alert('File is not an image');
    }

    // setup the file loader onload function
    // once the file loader has the data it passes it to the 
    // image object which, once the image has loaded, 
    // triggers the images onload function
    fileLoader.onload = function () {
        var data = this.result;
        imageObj.src = data;
    };

    fileLoader.onabort = function () {
        alert("The upload was aborted.");
    };

    fileLoader.onerror = function () {
        alert("An error occured while reading the file.");
    };


    // set up the images onload function which clears the hidden canvas context, 
    // draws the new image then gets the blob data from it
    imageObj.onload = function () {

        // Check for empty images
        if (this.width == 0 || this.height == 0) {
            alert('Image is empty');
        } else {

            context.clearRect(0, 0, max_width, max_height);
            context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, max_width, max_height);


            //dataURItoBlob function available here:
            // http://stackoverflow.com/questions/12168909/blob-from-dataurl
            // add ')' at the end of this function SO dont allow to update it without a 6 character edit
            // blob = dataURItoBlob(canvas.toDataURL(imageEncoding));
            blob = canvas.toBlob(imageBlob => {
                storage.ref().child(`${user.id}/temporary/temporary-image.jpg`).put(imageBlob).then(snapshot => {
                    snapshot.ref.getDownloadURL().then(url => {
                        callback(url);
                    });
                });
            }, imageEncoding, compressionRatio);

            //pass this blob to your upload function
            // upload(blob);
        }
    };

    imageObj.onabort = function () {
        alert("Image load was aborted.");
    };

    imageObj.onerror = function () {
        alert("An error occured while loading image.");
    };

}