// Locale is not supported in early version of Safari
// export const formatTimestampUsingLocale = (timestamp) => {
//     return Boolean(timestamp) ? timestamp.toLocaleString('en-GB', { year: "numeric", month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit", hour12: true }) : "-";
// }
export const formatTimestamp = (timestamp) => {
    if (Boolean(timestamp)) {
        let hours = timestamp.getHours().toString().padStart(2, "0");
        let minutes = timestamp.getMinutes().toString().padStart(2, "0");
        return `${timestamp.toDateString()} ${hours}:${minutes}`;
    } else {
        return ("-");
    }
}

// Locale is not supported in early version of Safari
// export const formatDateUsingLocale = (timestamp) => {
//     return Boolean(timestamp) ? timestamp.toLocaleString('en-GB', { year: "numeric", month: "long", day: "2-digit" }) : "-";
// }

export const formatDate = (timestamp) => {
    return Boolean(timestamp) ?
        timestamp.toDateString() :
        "-";
}

export const getDate = () => {
    let timestamp = new Date();
    timestamp.setHours(0);
    timestamp.setMinutes(0);
    timestamp.setSeconds(0);
    timestamp.setMilliseconds(0);
    return timestamp;
}
