import { firestore } from "../../common/serviecs/firebase";


export const multipleChoiceSectionRichTextTypeDataPatch = async (account) => {
    let multipleChoiceSections = {};
    let query = await firestore.collection("test-papers").where("ownerUserId", "==", account.ownerUserId).orderBy("updatedAt", "desc").get();
    query.forEach(doc => {
        let paper = { ...doc.data(), id: doc.id };
        paper.sections.forEach((section, sIndex) => {
            if (doc.id !== "pV6z6paqXoxPj7bmpkoE" && section.type === "multiple-choice") {
                let choices = [];
                section.questions.forEach((question, qIndex) => {
                    question.choices.forEach((choice, cIndex) => {
                        if (!Boolean(choice.type)) {
                            choices.push(choice);
                            paper.sections[sIndex].questions[qIndex].choices[cIndex] = { type: "text", value: choice }
                        }
                    })
                });
                // firestore.collection("test-papers").doc(paper.id).set(paper).then(paperRef => {
                //     let sectionId = `${paper.id}/${section.id}`;
                //     multipleChoiceSections[sectionId] = choices;
                // });
                let sectionId = `${paper.id}/${section.id}`;
                multipleChoiceSections[sectionId] = choices;
            }
        });


    });
    return multipleChoiceSections;
}

export const fillInBlankSectionConfigurationsDataPatch = async (account) => {
    let fillInBlankSections = {};
    let query = await firestore.collection("test-papers").where("ownerUserId", "==", account.ownerUserId).orderBy("updatedAt", "desc").get();
    query.forEach(async (doc) => {
        let paper = { ...doc.data(), id: doc.id };
        paper.sections.forEach((section, sIndex) => {
            if (section.type === "fill-in-blank") {
                let _configurations = {
                    provideOptions: Boolean(section?.provideOptions),
                    shuffleQuestions: Boolean(section?.shuffleQuestions)
                };
                // Delete existing configurations
                delete section?.provideOptions;
                delete section?.shuffleQuestions;
                paper.sections[sIndex].configurations = _configurations;
                let sectionId = `${paper.id}/${section.id}`;
                fillInBlankSections[sectionId] = {
                    title: section?.title, configurations: section.configurations
                }
                // let sectionId = `${paper.id}/${section.id}`;
                // fillInBlankSections[sectionId] = {
                //     title: section?.title, configurations: section.configurations
                // }
            }
        });
        let paperRef = await firestore.collection("test-papers").doc(paper.id).set(paper);
    });
    return fillInBlankSections;
}