import React, { useState } from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

/**
 * Application
 */
import { GRADES, LANGUAGES, LANGUAGE_CODE_ENGLISH } from '../../Constants';
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { ExpanableCheckbox, TextFieldWithSpeechRecognition } from '../../../common/components/controls';

export const PaperAttributes = (props) => {
    const { paper, updatePaperAttributes } = props;
    const [hasRichText, setRichText] = useState(Boolean(paper.richText));
    const [hasPreparationMaterial, setPreparationMaterial] = useState(Boolean(paper.preparationMaterial));
    const toggleRichText = () => {
        if (Boolean(hasRichText)) {
            updatePaperAttributes("richText", null);
        }
        setRichText(!hasRichText);
    }
    const togglePreparationMaterial = () => {
        if (Boolean(hasPreparationMaterial)) {
            updatePaperAttributes("preparationMaterial", null);
        }
        setPreparationMaterial(!hasPreparationMaterial);
    }
    return (
        <React.Fragment>
            <Grid item xs={12} md={9}>
                <TextField label="Author"
                    disabled={true}
                    value={paper.author.fullName}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} md={3}>
                <Autocomplete
                    options={LANGUAGES}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => (option.code === value.code)}
                    fullWidth
                    value={(LANGUAGES.filter(l => l.code === (paper.languageCode || LANGUAGE_CODE_ENGLISH))[0]) || {}}
                    onChange={(e, option) => updatePaperAttributes("languageCode", option ? option.code : null)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Language"
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition label="Subject"
                    languageCode={paper.languageCode}
                    value={paper.subject}
                    onChange={e => updatePaperAttributes("subject", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} md={9}>
                <TextFieldWithSpeechRecognition label="School"
                    languageCode={paper.languageCode}
                    value={Boolean(paper.school) ? paper.school : ""}
                    onChange={e => updatePaperAttributes("school", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} md={3}>
                <Autocomplete
                    options={GRADES}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => (option.label === value.label)}
                    groupBy={option => option.group}
                    fullWidth
                    value={(GRADES.filter(g => g.label === paper.grade)[0]) || {}}
                    onChange={(e, option) => updatePaperAttributes("grade", option ? option.label : null)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Grade"
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            <PaperTitleAttributes paper={paper} updatePaperAttributes={updatePaperAttributes} />
            <Grid item xs={6}>
                <TextField label="Full Score"
                    type="number"
                    value={paper.fullScore}
                    onChange={e => updatePaperAttributes("fullScore", parseInt(e.target.value))}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={6}>
                <TextField label="Time Limit (Minutes)"
                    type="number"
                    value={paper.timeLimitInMinutes}
                    onChange={e => updatePaperAttributes("timeLimitInMinutes", isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value))}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    options={[]}
                    value={paper.taggings || []}
                    getOptionLabel={(option) => option}
                    fullWidth
                    multiple={true}
                    freeSolo={true}
                    onChange={(e, value) => updatePaperAttributes("taggings", value)}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Taggings"
                            InputLabelProps={{ shrink: true }} />
                    } />
            </Grid>
            <Grid item xs={12}>
                {/* Rich Text */}
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={hasRichText || Boolean(paper.richText)}
                        onChange={toggleRichText}
                        label="Richtext Introduction" />
                </Box>
                {(Boolean(hasRichText)) &&
                    <RichTextEditor variant="outlined"
                        value={paper.richText}
                        setValue={value => updatePaperAttributes("richText", value)}
                    />}
                {/* End: Rich Text */}
                {/* Preparation Material */}
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={hasPreparationMaterial || Boolean(paper.preparationMaterial)}
                        onChange={togglePreparationMaterial}
                        label="Preparation Material" />
                </Box>
                {(Boolean(hasPreparationMaterial)) &&
                    <RichTextEditor variant="outlined"
                        value={paper.preparationMaterial}
                        setValue={value => updatePaperAttributes("preparationMaterial", value)}
                    />}
                {/* End: Preparation Material */}
            </Grid>
            <Box mt={5}>
                <span>&nbsp;</span>
            </Box>
        </React.Fragment>
    );
}

export const PaperTitleAttributes = (props) => {
    const { paper, updatePaperAttributes } = props;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition label="Title"
                    languageCode={paper.languageCode}
                    value={paper.title}
                    onChange={e => updatePaperAttributes("title", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition label="Subtitle"
                    languageCode={paper.languageCode}
                    value={paper.subtitle}
                    onChange={e => updatePaperAttributes("subtitle", e.target.value)}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
        </React.Fragment>
    );
}
