import React from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, TextField, Typography, Button, MenuItem, IconButton, Icon, InputAdornment, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionEditor } from './SectionEditor';

/**
 * Application
 */
import { CHAR_CODE_A } from '../../../common/Constants';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "matching",
    title: null,
    instruction: null
};
export function MatchingSectionEditor(props) {
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input" }] }, props.section);
    const { languageCode } = props;
    {/* https://stackoverflow.com/questions/48355322/re-render-array-of-child-components-in-react-after-editing-the-array */ }
    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <MatchingQuestionEditor
                    key={questionIndex}
                    question={question}
                    questionIndex={questionIndex}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode} />)}>
        </SectionEditor>
    );
}

/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { hint: "Input items into both group 1 and group 2 and link them up." },
};
export function MatchingQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 800
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        groupBox: {
            borderRadius: 4,
            borderColor: theme.palette.text.primary,
            borderStyle: "solid",
            borderWidth: 1,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode } = props;
    const question = Object.assign({}, { expressions: [{ text: null, option: null }], options: [null] }, props.question);
    const stage = question.stage;
    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            complete(false);
        }
    }
    const addExpression = (expressionIndex) => {
        let expressions = [...[], ...question.expressions];
        expressions.splice(expressionIndex + 1, 0, { text: null, option: null });
        updateQuestion(questionIndex, Object.assign({}, question, { expressions: expressions }));
    }
    const removeExpression = (expressionIndex) => {
        let expressions = [...[], ...question.expressions];
        expressions.splice(expressionIndex, 1);
        updateQuestion(questionIndex, Object.assign({}, question, { expressions: expressions }));
    }
    const updateExpression = (expressionIndex, key, value) => {
        let expressions = [...[], ...question.expressions];
        expressions[expressionIndex][key] = value;
        updateQuestion(questionIndex, Object.assign({}, question, { expressions: expressions }));
    }
    const addOption = (optionIndex) => {
        let options = [...[], ...question.options];
        options.splice(optionIndex + 1, 0, null);
        updateQuestion(questionIndex, Object.assign({}, question, { options: options }));
    }
    const removeOption = (optionIndex) => {
        let options = [...[], ...question.options];
        options.splice(optionIndex, 1);
        updateQuestion(questionIndex, Object.assign({}, question, { options: options }));
    }
    const updateOption = (optionIndex, value) => {
        let options = [...[], ...question.options];
        options[optionIndex] = value;
        updateQuestion(questionIndex, Object.assign({}, question, { options: options }));
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <Grid container spacing={2}>
                    {/* Options */}
                    <Grid item xs={12} md={6}>
                        <Box textAlign="center">
                            <Typography variant="subtitle2">Group 2</Typography>
                        </Box>
                        {question.options.map((option, oIndex) =>
                            <Box key={oIndex} display="flex" alignItems="center" mb={2}>
                                <TextFieldWithSpeechRecognition
                                    label={String.fromCharCode(CHAR_CODE_A + oIndex)}
                                    languageCode={languageCode}
                                    InputProps={{
                                        startAdornment:
                                            <Tooltip title="Remove item">
                                                <InputAdornment>
                                                    <IconButton size="small" onClick={e => removeOption(oIndex)}>
                                                        <Icon>remove_circle</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>,
                                        endAdornment:
                                            <Tooltip title="Add next item">
                                                <InputAdornment>
                                                    <IconButton size="small" onClick={e => addOption(oIndex)} color="primary">
                                                        <Icon>add_circle</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>
                                    }}
                                    fullWidth variant="outlined"
                                    InputLabelProps={{ shrink: true }} value={option}
                                    onChange={e => updateOption(oIndex, e.target.value)} />
                            </Box>
                        )}
                    </Grid>
                    {/* End: Options */}
                    {/* Expressions */}
                    <Grid item xs={12} md={6}>
                        <Box textAlign="center">
                            <Typography variant="subtitle2">Group 1</Typography>
                        </Box>
                        {question.expressions.map((expression, eIndex) =>
                            <Box key={eIndex} display="flex" alignItems="flex-start" mb={2}>
                                <TextFieldWithSpeechRecognition
                                    languageCode={languageCode}
                                    key={eIndex}
                                    fullWidth
                                    inputProps={{
                                        style: { marginLeft: 8 },
                                    }}
                                    InputProps={{
                                        startAdornment:
                                            <Tooltip title="Remove item">
                                                <InputAdornment>
                                                    <IconButton size="small" onClick={e => removeExpression(eIndex)}>
                                                        <Icon>remove_circle</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>,
                                        endAdornment:
                                            <Tooltip title="Add next item">
                                                <InputAdornment>
                                                    <IconButton size="small" onClick={e => addExpression(eIndex)} color="primary">
                                                        <Icon>add_circle</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>
                                    }}
                                    variant="outlined" style={{ marginRight: 8 }}
                                    value={expression.text}
                                    onChange={e => updateExpression(eIndex, "text", e.target.value)} />
                                <TextField select fullWidth label="Answer"
                                    variant="outlined" InputLabelProps={{ shrink: true }}
                                    style={{ width: 80, marginRight: 8 }}
                                    value={expression.option}
                                    onChange={e => updateExpression(eIndex, "option", e.target.value)}>
                                    {question.options.map((option, oIndex) =>
                                        <MenuItem style={{ textAlign: "center" }} value={oIndex}>{String.fromCharCode(CHAR_CODE_A + oIndex)}</MenuItem>)}
                                </TextField>
                            </Box>)}
                    </Grid>
                    {/* End: Expressions */}
                </Grid >
            break;
        default:
            editor =
                editor =
                <Grid container spacing={2} onClick={isEditingSection ? () => { } : () => setStage("input")}>
                    <Grid item xs={12} md={6}>
                        <Box textAlign="center">
                            <Typography variant="subtitle2">Group 1</Typography>
                        </Box>
                        <Box className={classes.groupBox}>
                            {question.expressions.map((expression, eIndex) =>
                                <Box key={eIndex} display="flex" alignItems="center" mb={2}>
                                    <Typography style={{ flexGrow: 1 }}>{expression.text}</Typography>
                                    <Typography style={{ fontWeight: 800 }}>{String.fromCharCode(CHAR_CODE_A + expression.option)}</Typography>
                                </Box>)}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box textAlign="center">
                            <Typography variant="subtitle2">Group 2</Typography>
                        </Box>
                        <Box className={classes.groupBox}>
                            {question.options.map((option, oIndex) =>
                                <Box key={oIndex} display="flex" alignItems="center" mb={2}>
                                    <Typography>{String.fromCharCode(CHAR_CODE_A + oIndex)}. {option}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid >
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage].hint}</Typography>
                </Grid> : null}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                {Boolean(STAGES[stage]) && Boolean(STAGES[stage].previousStage) ? <Button className={classes.button} color="primary" onClick={() => setStage(STAGES[stage].previousStage)}>Back</Button> : null}
                {Boolean(STAGES[stage]) ? <Button className={classes.button} color="primary" onClick={() => removeQuestion(questionIndex)}>Remove</Button> : null}
                {Boolean(STAGES[stage]) && !Boolean(STAGES[stage].nextStage) ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={() => complete(false)}
                            disabled={(question.expressions.filter(e => Boolean(e.text) && e.option !== null).length === 0) || (question.options.filter(o => Boolean(o)).length === 0)}> Complete</Button>
                        <Button className={classes.button} color="primary" onClick={() => complete(true)}
                            disabled={(question.expressions.filter(e => Boolean(e.text) && e.option !== null).length === 0) || (question.options.filter(o => Boolean(o)).length === 0)}>Next Question</Button>
                    </React.Fragment> :
                    null}
            </Grid>
        </React.Fragment >
    );
}