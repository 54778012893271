import React from 'react';
import PropTypes from 'prop-types';

/**
 * Material UI
 */
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export function NumberTextField(props) {
    const numberOfDigits = props.numberOfDigits || 1;
    const classes = makeStyles(theme => ({
        numberBox: {
            width: theme.spacing(2 + numberOfDigits),
            lineHeight: "1.5rem",
            textAlign: "center"
        },
    }))();
    return (
        <TextField
            variant="outlined"
            size="small"
            {...props}
            //inputProps={{ className: classes.numberBox }}
            InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: { ...{ className: classes.numberBox, numberOfDigits: numberOfDigits }, ...props }
            }} />
    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, numberOfDigits, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={false}
            isNumericString={false}
            // prefix="("
            // suffix=")"
            isAllowed={values => {
                return values.value === "" || (values.value.length <= numberOfDigits && values.floatValue < Math.pow(10, numberOfDigits));
            }}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};